import { get, post, put} from '../lib/request'


export const serviceListarContacto = (params) => {
  return get(`/api/contacto`, params)
}

export const serviceCrearContacto = (contacto) => {
  return post(`/api/contacto`, contacto)
}

//recibe correo electronico
export const serviceRecibirContacto = (params) => {
  return post(`/api/contacto/recibir-correo`, params)
}
export const serviceEditarContacto = (idContacto, contacto) => {
  return put(`/api/contacto/${ idContacto }`, contacto)
}