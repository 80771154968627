import React from 'react'
import Titulo from '../../componentes/Titulo'

import { Card, Col, Row, Divider } from 'antd'
import clienteSmartPharma from '../../assets/clientes/smartPharma/clienteSmartPharma.png'
import clienteScopDelPeru from '../../assets/clientes/scopDelPeru/clienteScopDelPeru.png'
import clienteJRSMeddev from '../../assets/clientes/jrsMeddev/jrsMeddev.png'
import clienteJiangGroup from '../../assets/clientes/jiangGroup/jiangGroup.png'
import clienteBEPHARMACYSAC from '../../assets/clientes/BEPHARMACYSAC/bePharmacySAC.png'
import clienteIMPORTPHARMANC from '../../assets/clientes/IMPORTPHARMANC/importPharmaNC.png'
import clienteCALI from '../../assets/clientes/CALI/cali.png'

export const Clientes = () => (
  <div>
    <p />
    <Titulo nombre="Clientes" />
    <Divider />

    <Card>
    <Row gutter={24} type="flex">
        <Col span={4} xs={24} sm={24} md={24} lg={4} xl={4}>
          <Card
            bordered={false}
            cover={<img alt="SMART PHARMA" src={clienteSmartPharma} style={{ 
              width:'165px', height:'125px', border: '1px solid #ddd', borderRadius: '3px'
            }}/>}>
            <h6 style={{textAlign:'center'}}>SMART PHARMA</h6>
          </Card>
        </Col>
        <Col span={4} xs={24} sm={24} md={24} lg={4} xl={4}>
          <Card
            bordered={false}
            cover={<img alt="SCOP DEL PERU" src={clienteScopDelPeru} style={{ 
              width:'165px', height:'125px', border: '1px solid #ddd', borderRadius: '3px'
            }}/>}>
            <h6 style={{textAlign:'center'}}>SCOP DEL PERÚ</h6>
          </Card>
        </Col>
        <Col span={4} xs={24} sm={24} md={24} lg={4} xl={4}>
            <Card
              bordered={false}
              cover={<img alt="JRS MEDDEV" src={clienteJRSMeddev} style={{
                width:"165px", height:"125px", border: "1px solid #ddd", borderRadius: "3px"
              }}/>}>
             <h6 style={{textAlign:"center"}}>JRS MEDDEV</h6>
            </Card>
        </Col>
        <Col span={4} xs={24} sm={24} md={24} lg={4} xl={4}>
            <Card
              bordered={false}
              cover={<img alt="JIANG GROUP" src={clienteJiangGroup} style={{
                width:"165px", height:"125px", border: "1px solid #ddd", borderRadius: "3px"
              }}/>}>
             <h6 style={{textAlign:"center"}}>JIANG GROUP</h6>
            </Card>
        </Col>

        <Col span={4} xs={24} sm={24} md={24} lg={4} xl={4}>
            <Card
              bordered={false}
              cover={<img alt="B&ampE PHARMACY SAC" src={clienteBEPHARMACYSAC} style={{
                width:"165px", height:"125px", border: "1px solid #ddd", borderRadius: "3px"
              }}/>}>
             <h6 style={{textAlign:"center"}}>B&E PHARMACY SAC</h6>
            </Card>
        </Col>
        <Col span={4} xs={24} sm={24} md={24} lg={4} xl={4}>
            <Card
              bordered={false}
              cover={<img alt="IMPORT PHARMA NC" src={clienteIMPORTPHARMANC} style={{
                width:"165px", height:"125px", border: "1px solid #ddd", borderRadius: "3px"
              }}/>}>
             <h6 style={{textAlign:"center"}}>IMPORT PHARMA NC</h6>
            </Card>
        </Col>
      </Row>

      <Row gutter={24} type="flex">
      <Col span={4} xs={24} sm={24} md={24} lg={4} xl={4}>
            <Card
              bordered={false}
              cover={<img alt="CORPORACION ALIANZA LOGISTICA INTEGRAL" src={clienteCALI} style={{
                width:"165px", height:"125px", border: "1px solid #ddd", borderRadius: "3px"
              }}/>}>
             <h6 style={{textAlign:"center"}}>CORPORACION ALIANZA LOGISTICA INTEGRAL</h6>
            </Card>
        </Col>
      </Row>
    </Card>
  </div>
)