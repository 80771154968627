import React, { Component } from 'react'
import {
  Divider,
  Collapse,
  Button
} from 'antd'

import { DownloadOutlined } from '@ant-design/icons';

import '../../../../estilos-paginas/cursos/cursos.css'
import imagen from '../../../../assets/cursos/gratuitos/curso29/CBPOficinaFarmaceutica2.png'
import pdfCurso from '../../../../assets/cursos/gratuitos/pdfcursos/CBPOficinaFarmaceutica.pdf'

const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class CBPOficinaFarmaceutica extends Component {
  render() {
    return(
      <>
        <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={imagen}
            style={{minWidth: 280, maxWidth: 350, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>CERTIFICACIÓN DE BUENAS PRÁCITCAS DE OFICINA FARMACÉUTICA</h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("TEMARIO")}
              <ul>
                <li>Normativa Sanitaria Vigente para la Certificación de una Oficina Farmacéutica:</li>
                  <ul>
                    <li>Ley 29459: Ley de los Productos Farmacéuticos, Dispositivos Médicos y Productos Sanitarios.</li>
                    <li>D.S. 014-2011-SA, Reglamento de Establecimiento Farmacéuticos y modificatorias.</li>
                    <li>R.M. 585-99-SA/DM, Manual de Buenas Prácticas de Almacenamiento de Productos Farmacéuticos y Afines.</li>
                    <li>R.M. 013-2009-MINSA, Manual de Buenas Prácticas de Dispensación.</li>
                    <li>R.M. 538-2016/MINSA, Preparados Farmacéuticos.</li>
                  </ul>
                <li>Del local.</li>
                <li>De las Instalaciones.</li>
                <li>Organización Interna.</li>
                <li>Recursos Materiales.</li>
                <li>Del Personal.</li>
                <li>Seguridad y mantenimiento.</li>
                <li>Limpieza y Sanitización.</li>
                <li>Técnicas de Manejo.</li>
                <li>Libros oficiales y Material de Consulta.</li>
                <li>Procedimiento Operativos.</li>
                <li>Almacenamiento y Dispensación.</li>
                <li>No conformidades más frecuentes en inspecciones a Oficina Farmacéutica.</li>
              </ul>

              <a href={pdfCurso} download>
              <Button type="primary" shape="round" icon={<DownloadOutlined/>} size={'large'}>
                Descargar PDF
               </Button>
              </a>
            </Panel>
            <Panel key="3"
              style={{ backgroundColor:'#1890ff'}}
            >
              <ul>
                <li>Si desea registrar su participación en el webinar: CERTIFICACIÓN DE BUENAS PRÁCITCAS DE OFICINA FARMACÉUTICA, agradeceré llenar el siguiente formulario de inscripción: </li>
              </ul>
              <br/> <a href="https://forms.gle/YMbdmoTUtwRaKjib9"rel="noopener noreferrer" target="_blank"><Button type="primary" shape="round" size={'large'}>FORMULARIO DE INSCRIPCIÓN </Button></a>
            </Panel>
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}