import React, { Component } from 'react'
import {
  Divider,
  Collapse,
  Button
} from 'antd'

import { DownloadOutlined } from '@ant-design/icons';

import '../../../../estilos-paginas/cursos/cursos.css'
import imagen from '../../../../assets/cursos/gratuitos/curso35/flyer.png'
import pdfCurso from '../../../../assets/cursos/gratuitos/pdfcursos/CBPDT2023.pdf'

const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class CertificacionBuenasPracticasDeDistribucionYTransporte extends Component {
  render() {
    return(
      <>
        <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={imagen}
            style={{minWidth: 280, maxWidth: 350, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>CERTIFICACIÓN EN BUENAS PRÁCITCAS DE DISTRIBUCIÓN Y TRANSPORTE</h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("TEMARIO")}
              <ul>
                <li>ÁMBITO DE APLICACIÓN DE LA NORMATIVA R.M. N° 833-2015 Y SUS MODIFICATORIAS.</li>
                <li>SISTEMA DE ASEGURAMIENTO DE LA CALIDAD.</li>
                <li>PERSONAL.</li>
                <li>INSTALACIONES, EQUIPOS E INSTRUMENTOS (CONCEPTOS DE CALIFICACIÓN Y CALIBRACIÓN).</li>
                <ul>
                    <li>CALIFICACIÓN DE EMBALAJE (CONTENEDORES PASIVOS - CONTENEDORES ACTIVOS)</li>
                    <li>CALIFICACIÓN DE EQUIPOS</li>
                    <li>CALIFICACIÓN DE LA UNIDAD DE TRANSPORTE</li>
                </ul>
                <li>DESPACHO</li>
                <li>DISTRIBUCIÓN Y TRANSPORTE (PLANIFICACIÓN DE RUTA, MANEJO DE SUSTANCIAS PELIGROSAS, VENCIDOS Y/O DEVUELTOS, DESVIACIONES DE TEMPERATURA, DOCUMENTACIÓN, ENTRE CONSIDERACIONES SEGÚN NORMATIVA).</li>
                <li>DOCUMENTACIÓN Y TRAZABILIDAD.</li>
                <li>AUTOINSPECCIONES (ACCIONES CORRECTIVAS Y ACCIONES PREVENTIVAS).</li>
                <li>QUEJAS Y RECLAMOS.</li>
                <li>DEVOLUCIONES.</li>
                <li>PLAN DE CONTINGENCIA.</li>
                <li>CONTRATO PARA EL SERVICIO DE DISTRIBUCIÓN Y TRANSPORTE.</li>
                <li>PROCESO DE INSPECCIÓN EN BPDT POR PARTE DE LA AUTORIDAD.</li>
                <li>OBSERVACIONES MÁS FRECUENTES A UNA INSPECCIÓN DE BPDT.</li>
              </ul>

              <a href={pdfCurso} download>
              <Button type="primary" shape="round" icon={<DownloadOutlined/>} size={'large'}>
                Descargar PDF
               </Button>
              </a>
            </Panel>
            <Panel key="3"
              style={{ backgroundColor:'#1890ff'}}
            >
              <ul>
                <li>Si desea registrar su participación en el webinar: CERTIFICACIÓN EN BUENAS PRÁCITCAS DE DISTRIBUCIÓN Y TRANSPORTE, agradeceré llenar el siguiente formulario de inscripción: </li>
              </ul>
              <br/> <a href="https://forms.gle/1gmwc2wnDWpHsfGe6"rel="noopener noreferrer" target="_blank"><Button type="primary" shape="round" size={'large'}>FORMULARIO DE INSCRIPCIÓN </Button></a>
            </Panel>
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}