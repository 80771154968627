import React, { Component } from 'react'
import {
  Divider,
  Collapse,
  Button
} from 'antd'

import { DownloadOutlined } from '@ant-design/icons';

import '../../../../estilos-paginas/cursos/cursos.css'
import cursoPesquisaYProcesoDeControlDeCalidadDeProductosFarmaceuticos from '../../../../assets/cursos/gratuitos/novenoCurso/pesquisaYProcesoDeControlDeCalidadDeProductosFarmaceuticos2.png'
import pdfCurso from '../../../../assets/cursos/gratuitos/pdfcursos/cursoPesquisaYProcesoDeControlDeCalidadDeProductosFarmaceuticos.pdf'

const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class PesquisaYProcesoDeControlDeCalidadDeProductosFarmaceuticos extends Component {
  render() {
    return(
      <>
        <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={cursoPesquisaYProcesoDeControlDeCalidadDeProductosFarmaceuticos}
            style={{minWidth: 280, maxWidth: 350, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>PESQUISA Y PROCESO DE CONTROL DE CALIDAD DE PRODUCTOS FARMACÉUTICOS, DISPOSITIVOS MÉDICOS Y PRODUCTOS SANITARIOS</h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("TEMARIO")}
              <ul>
                <li>Normatividad y rol de la autoridad en temas de calidad de los productos farmacéuticos, dispositivos médicos y productos sanitarios.</li>
                <li>Responsabilidades de DIGEMID, OD, ARM, CENTRO NACIONAL DE CONTROL DE CALIDAD.</li>
                <li>Facultades de los Inspectores en las actividades depesquisas.</li>
                <li>De las acciones de Pesquisa.</li>
                <li>De la metodología y el análisis de muestras.</li>
                <li>De los Resultados del control de calidad(Conforme, No conforme).</li>
                <li>Dirimencias.</li>
                <li>Retiro de Mercado y/o Destrucción de Productos.</li>
                <li>Sanciones.</li>
              </ul>

              <a href={pdfCurso} download>
              <Button type="primary" shape="round" icon={<DownloadOutlined/>} size={'large'}>
                Descargar PDF
               </Button>
              </a>
            </Panel>
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}