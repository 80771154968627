import React, { useState } from 'react';

import './Contactanos.css';


const Contactanos = () => {
  const [formData, setFormData] = useState({
    nombre: '',
    correo: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('http://localhost:8080/api/contacto/send-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        // Aquí puedes realizar cualquier acción adicional después de enviar el formulario, como mostrar un mensaje de éxito.
      })
      .catch(error => {
        console.error('Error al enviar el formulario:', error);
        // Aquí puedes mostrar un mensaje de error en caso de que la solicitud falle.
      });

  };


  return (
    <div className='Contactanos'>
      <h2>Contactanos</h2>

      <div>
        <h3>Ubicación</h3>
        <p> Avenida Fray Luis de Leon 590, Cercado de Lima</p>
      </div>

      <div>
        <h3>Formulario de contacto</h3>
        <form onSubmit={handleSubmit}>
          <label htmlFor="nombre">Nombre:</label>
          <input
            type="text"
            id="nombre"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
          />

          <label htmlFor="correo">Correo electrónico:</label>
          <input
            type="email"
            id="correo"
            name="correo"
            value={formData.correo}
            onChange={handleChange}
          />

          <label htmlFor="message">Mensaje:</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
          />

          <button type="submit">Enviar</button>
        </form>
      </div>
    </div>
  )
};

export default Contactanos;
