import React, { Component } from 'react'
import {
  Divider,
  Collapse,
  Button
} from 'antd'

import { DownloadOutlined } from '@ant-design/icons';

import '../../../../estilos-paginas/cursos/cursos.css'
import manejoYControlDeEstupefacientesYPsicotropicosEnEstablecimientosFarmaceuticos from '../../../../assets/cursos/gratuitos/decimoOctavoCurso/ManejoYControlDeEstupefacientesYPsicotropicosEnEstablecimientosFarmaceuticos2.png'
import pdfCurso from '../../../../assets/cursos/gratuitos/pdfcursos/cursoManejoYControlDeEstupefacientesYPsicotropicosEnEstablecimientosFarmaceuticos.pdf'

const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class ManejoYControlDeEstupefacientesYPsicotropicosEnEstablecimientosFarmaceuticos extends Component {
  render() {
    return(
      <>
        <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={manejoYControlDeEstupefacientesYPsicotropicosEnEstablecimientosFarmaceuticos}
            style={{minWidth: 280, maxWidth: 350, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>MANEJO Y CONTROL DE ESTUPEFACIENTES Y PSICOTRÓPICOS EN ESTABLECIMIENTOS FARMACÉUTICOS</h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("TEMARIO")}
              <ul>
                <li>Normativa: D.S. N° 023-2001-SA:</li>
                  <ul>
                    <li>Reglamento de estupefacientes, psicotrópicos y otras sustancias sujetas a fiscalización sanitaria y su modificatoria D.S. N° 024-2020-SA.</li>
                  </ul>
                <li>Previsiones.</li>
                <li>Fabricación.</li>
                <li>Fabricación.</li>
                <li>Balance Trimestral.</li>
                <li>Libros Electrónicos.</li>
                <li>Libros de Estupefacientes y Psicotrópico (Recomendaciones para el llenado).</li>
                <li>Importación:</li>
                  <ul>
                    <li>Certificado oficial.</li>
                  </ul>

                <li>Almacenamiento.</li>
                <li>Recetas Especiales.</li>
                <li>Visación de Libros.</li>
              </ul>

              <a href={pdfCurso} download>
              <Button type="primary" shape="round" icon={<DownloadOutlined/>} size={'large'}>
                Descargar PDF
               </Button>
              </a>
            </Panel>
            <Panel key="3"
              style={{ backgroundColor:'#1890ff'}}
            >
              <ul>
                <li>Si desea participar en el Webinar: MANEJO Y CONTROL DE ESTUPEFACIENTES Y PSICOTRÓPICOS EN ESTABLECIMIENTOS FARMACÉUTICOS por favor registrar su participación en el siguiente enlace: </li>
              </ul>
              <br/> <a href="https://forms.gle/n1yXuPY6MGiNugn69"rel="noopener noreferrer" target="_blank"><Button type="primary" shape="round" size={'large'}>FORMULARIO DE INSCRIPCIÓN </Button></a>
            </Panel>
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}