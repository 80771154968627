import React, { Component } from 'react'
import {
  Divider,
  Collapse,
  Button
} from 'antd'

import { DownloadOutlined } from '@ant-design/icons';

import '../../../../estilos-paginas/cursos/cursos.css'
import procedimientoAdministrativoGeneralEnEstablecimientosFarmaceuticos from '../../../../assets/cursos/gratuitos/decimoNovenoCurso/ProcedimientoAdministrativoGeneralEnEstablecimientosFarmaceuticos2.png'
import pdfCurso from '../../../../assets/cursos/gratuitos/pdfcursos/cursoProcedimientoAdministrativoGeneralEnEstablecimientosFarmaceuticos.pdf'

const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class ProcedimientoAdministrativoGeneralEnEstablecimientosFarmaceuticos extends Component {
  render() {
    return(
      <>
        <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={procedimientoAdministrativoGeneralEnEstablecimientosFarmaceuticos}
            style={{minWidth: 280, maxWidth: 350, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>PROCEDIMIENTO ADMINISTRATIVO GENERAL EN ESTABLECIMIENTOS FARMACÉUTICOS</h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("TEMARIO")}
              <ul>
                <li>Aspectos Generales de la Ley del Procedimiento Administrativo General N°27444 y Modificaciones.</li>
                  <ul>
                    <li>Principios del Procedimiento Administrativo y nuevos principios.</li>
                    <li>El Acto Administrativo o Nulidad de los actos administrativos o Eficacia de los actos administrativos o Revisión de los actos en la vía administrativa.</li>
                    <li>El procedimiento administrativo o Silencio administrativo o Iniciación del Procedimiento o Plazos y Términos.</li>
                    <li>Procedimiento administrativo sancionador.</li>
                    <li>Principios y aplicación del PAS en defensa del administrado.</li>
                    <li>Fiscalización administrativa derechos y obligaciones del administrado.</li>
                    <li>Simplificación administrativa.</li>
                    <li>Texto Único de Procedimiento Administrativo TUPA.</li>
                    <li>Caducidad y Prescripción de sanciones Administrativa en favor del administrado.</li>
                    <li>Procedimientos Especiales.</li>
                  </ul>
                <li>Consideraciones a tener presente ante una inspección, pesquisa y/o verificación en los establecimientos farmacéuticos de salud..</li>
                <li>Aplicación de caso.</li>
              </ul>

              <a href={pdfCurso} download>
              <Button type="primary" shape="round" icon={<DownloadOutlined/>} size={'large'}>
                Descargar PDF
               </Button>
              </a>
            </Panel>
            <Panel key="3"
              style={{ backgroundColor:'#1890ff'}}
            >
              <ul>
                <li>Si desea registrar su participación en el webinar: PROCEDIMIENTO ADMINISTRATIVO GENERAL EN ESTABLECIMIENTOS FARMACÉUTICOS, agradeceré llenar el siguiente formulario de inscripción: </li>
              </ul>
              <br/> <a href="https://forms.gle/9fCJ8HaSHdCkHVGa7"rel="noopener noreferrer" target="_blank"><Button type="primary" shape="round" size={'large'}>FORMULARIO DE INSCRIPCIÓN </Button></a>
            </Panel>
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}