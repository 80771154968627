import React, { Component } from 'react'
import {
  Divider,
  Collapse,
  Button
} from 'antd'

import { DownloadOutlined } from '@ant-design/icons';

import '../../../../estilos-paginas/cursos/cursos.css'
import imagen from '../../../../assets/cursos/gratuitos/curso37/FLYER.png'
import pdfCurso from '../../../../assets/cursos/gratuitos/pdfcursos/GISFDLF.pdf'

const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class GestionParaLaImplementacionDelSistemaDeFarmacoVigilanciaEnUnaDrogueriaYLaboratorioFarmaceutico extends Component {
  render() {
    return(
      <>
        <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={imagen}
            style={{minWidth: 280, maxWidth: 350, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>GESTIÓN PARA LA IMPLEMENTACIÓN DEL SISTEMA DE FARMACOVIGILANCIA EN UNA DROGUERÍA Y LABORATORIO FARMACÉUTICO</h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("TEMARIO")}
              <ul>
                <li>24 de mayo (Hora: 8:00 pm a 10:00 pm) BUENAS PRACTICAS DE FARMACOVIGILANCIA EN UNA DROGUERÍA Y LABORATORIO FARMACÉUTICO:</li>
                    <ul>
                        <li>Conceptos y generalidades de la Farmacovigilancia.</li>
                        <li>Requisitos para la implementación del Sistema de Farmacovigilancia para una Droguería y Laboratorio Farmacéutico, según lo indicado en la R.M. N° 1053-2020: MANUAL DE BUENAS PRACTICAS DE FARMACOVIGILANCIA y Modificatoria.</li>
                    </ul>
                <li>25 de mayo (Hora: 8:00 pm a 10:00 pm) GESTIÓN DE LAS SOSPECHAS DE REACCIONES ADVERSAS A LOS MEDICAMENTOS</li>
                    <ul>
                        <li>Conceptos y generalidades de las Sospecha de Reacción Adversa a los Medicamentos.</li>
                            <ul>
                                <li>Reacción Adversa a un medicamento (RAM)</li>
                                <li>Identificar RAMs</li>
                            </ul>
                        <li>Recopilación de las Sospechas de Reacciones Adversas (SRA).</li>
                        <li>Mecanismos necesarios para la gestión de las SRA.</li>
                        <li>Notificación de SRA al Centro Nacional de Farmacovigilancia y Tecnovigilancia.</li>
                            <ul>
                                <li>Datos para incluir en la Notificación de SRA.</li>
                                <li>Plazo para el envío de las SRA.</li>
                            </ul>
                        <li>Registro de las Notificaciones de SRA.</li>
                        <li>Seguimiento y cierre de las Notificaciones de SRA.</li>
                    </ul>
                <li>26 de mayo (Hora: 8:00 pm a 10:00 pm) Informe Periódico De Seguridad (IPS), Estudios Post Autorización (EPA), Gestión De Señales</li>
                    <ul>
                        <li>INFORME PERIÓDICO DE SEGURIDAD (IPS).</li>
                        <ul>
                            <li>Definición, objetivos.</li>
                            <li>Evaluación del balance beneficio/riesgo.</li>
                            <li>Estructura del IPS y Periodicidad de presentación a la ANM de acuerdo con la normatividad vigente.</li>
                        </ul>
                        <li>Estudios Post Autorización (EPA).</li>
                        <ul>
                            <li>Definición, objetivos.</li>
                            <li>Tipos de estudio.</li>
                            <li>Estructura del Protocolo de estudio y del informe final de acuerdo con la normatividad vigente.</li>
                        </ul>
                        <li>GGestión de Señales.</li>
                        <ul>
                            <li>Definición.</li>
                            <li>Fuentes de información para la identificación de señales.</li>
                            <li>Métodos de identificación de Señales.</li>
                        </ul>
                    </ul>
                <li>27 de mayo (Hora: 4:00 pm a 6:30 pm)</li>
                    <ul>
                        <li>Desarrollo del acta de inspección en una Certificación en Buenas Prácticas de Farmacovigilancia.</li>
                    </ul>
              </ul>
              <a href={pdfCurso} download>
              <Button type="primary" shape="round" icon={<DownloadOutlined/>} size={'large'}>
                Descargar PDF
               </Button>
              </a>
            </Panel>
            <Panel key="3"
              style={{ backgroundColor:'#1890ff'}}
            >
              <ul>
                <li>Si desea registrar su participación en el webinar: GESTIÓN PARA LA IMPLEMENTACIÓN DEL SISTEMA DE FARMACOVIGILANCIA EN UNA DROGUERÍA Y LABORATORIO FARMACÉUTICO, agradeceré llenar el siguiente formulario de inscripción: </li>
              </ul>
              <br/> <a href="https://forms.gle/ToxZvELsYhEJuLpt9"rel="noopener noreferrer" target="_blank"><Button type="primary" shape="round" size={'large'}>FORMULARIO DE INSCRIPCIÓN </Button></a>
            </Panel>
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}