import React, { Component } from 'react'
import {
  Divider,
  Collapse,Button,
} from 'antd'

import { DownloadOutlined } from '@ant-design/icons';

import '../../../../estilos-paginas/cursos/cursos.css'
import gestionDeNotificacionesSanitariasDeProductosCosmeticos from '../../../../assets/cursos/gratuitos/GestiondeNotificacionesSanitariasdeProductosCosmeticos2.jpg'
import pdfCurso from '../../../../assets/cursos/gratuitos/pdfcursos/cursoGestionDeNotificacionesSanitariasDeProductosCosmeticos.pdf'

const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class GestionDeNotificacionesSanitariasDeProductosCosmeticos extends Component {
  render() {
    return(
      <>
         <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={gestionDeNotificacionesSanitariasDeProductosCosmeticos}
            style={{minWidth: 330, maxWidth: 450, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>Gestión de Notificaciones Sanitarias de Productos Cosméticos</h3>
              <h3>¿Qué documentos son necesarios para realizar la Notificación Sanitaria Obligatoria de un producto cosmético?</h3>
              <h3>“Normas Supranacionales de la Comunidad Andina”</h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("TEMAS A DESARROLLAR:")}
              <ul>
                <li>Decisión 516 (enfocado en el proceso de NSO)</li>
                <li>Requisitos para la Notificación Sanitaria Obligatoria de Productos Cosméticos:</li>
                <li>Requisitos para el Reconocimiento de Notificación Sanitaria Obligatoria de Productos Cosméticos.</li>
                <li>Requisitos para la Renovación de Notificación Sanitaria Obligatoria de Productos Cosméticos.</li>
                <li>Modificaciones, cambios en la Notificación Sanitaria Obligatoria.</li>
                <li>Gestión de trámites en VUCE</li>
                <li>Decisión 833:  Principales cambios Vs Decisión 516.</li>
              </ul>
              <a href={pdfCurso} download>
              <Button type="primary" shape="round" icon={<DownloadOutlined/>} size={'large'}>
                Descargar PDF
               </Button>
              </a>
            </Panel>
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}