import React, { Component } from 'react'
import {
  Divider,
  Collapse,Button,
} from 'antd'

import { DownloadOutlined } from '@ant-design/icons';

import '../../../../estilos-paginas/cursos/cursos.css'
import comoaperturarunadrogueria from '../../../../assets/cursos/gratuitos/Quedebemostenerencuentapararealizarelmapeotermicodeunalmacenylacalificaciondelembalajeisotermicodeunadrogueria.jpg'
import pdfCurso from '../../../../assets/cursos/gratuitos/pdfcursos/cursoQuedebemostenerencuentapararealizarelmapeotermicodeunalmacenylacalificaciondelembalajeisotermicodeunadrogueria.pdf'

const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class QueDebemosTenerEnCuentaParaRealizarElMapeoTermicoDeUnAlmacenYLaCalificacionDelEmbalajeIsotermicoDeUnaDrogueria extends Component {
  render() {
    return(
      <>
         <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={comoaperturarunadrogueria}
            style={{minWidth: 330, maxWidth: 450, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>¿Qué debemos tener en cuenta para realizar el mapeo térmico de un almacén y la calificación del embalaje isotérmico de una Droguería?</h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("TEMAS A DESARROLLAR:")}
              <hr />
              {subtitulo("MAPEO TÉRMICO")}
              <ul>
                <li>Mapeo Térmico como requisito del Documento Técnico: Manual de Buenas Prácticas de Almacenamiento (R.M. 132-2015/ MINSA).</li>
                <li>Preparación del almacén en diversas temperaturas. - Infraestructura y Distribución interna.</li>
                <li>Dispositivos de Control de Temperatura y Humedad Relativa. - Unidad de Climatización. </li>
                <li>Perfil Térmico de Almacenes. - Carga y Estaciones.</li>
                <li>Puntos Críticos de Temperatura y % HR. – Ubicación y Monitoreo. </li>
                <li>Dispositivos de Monitoreo y Calibración trazable.</li>
              </ul>
              {subtitulo("CALIFICACION DE EMBALAJE ISOTERMICO")}
              <ul>
                <li>Referencias internacionales y nacionales</li>
                <li>Conceptos generales</li>
                <li>Componentes de los contenedores pasivos</li>
                <li>Tipos de aislantes térmicos </li>
                <li>Tipos de refrigerantes</li>
                <li>Etapas de la calificación </li>
                <li>Equipos e instrumentos necesarios</li>
                <li>Calificación de Diseño</li>
                <li>Calificación de Operación</li>
                <li>Calificación de Desempeño</li>
              </ul>
              <a href={pdfCurso} download>
              <Button type="primary" shape="round" icon={<DownloadOutlined/>} size={'large'}>
                Descargar PDF
               </Button>
              </a>
            </Panel>
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}