import React, { Component } from 'react'
import {
  Divider,
  Collapse,
  Button
} from 'antd'

import { DownloadOutlined } from '@ant-design/icons';

import '../../../../estilos-paginas/cursos/cursos.css'
import imagen from '../../../../assets/cursos/gratuitos/curso31/NSOHD1.png'
import pdfCurso from '../../../../assets/cursos/gratuitos/pdfcursos/BROCHURE_NSOHD_2022.pdf'

const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class GestionDeLaInformacionParaLaObtencionDeLaNotificacionSanitariaDeProductosDeHigieneDomesticaYProductosAbsorbentesDeHigieneRegistroSanitarioDeProductos extends Component {
  render() {
    return(
      <>
        <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={imagen}
            style={{minWidth: 280, maxWidth: 350, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>GESTIÓN DE LA INFORMACIÓN PARA LA OBTENCIÓN DE: LA NOTIFICACIÓN SANITARIA DE PRODUCTOS DE HIGIENE DOMÉSTICA Y PRODUCTOS ABSORBENTES DE HIGIENE - REGISTRO SANITARIO DE PRODUCTOS</h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("TEMARIO")}
              <ul>
                <li>NORMATIVA VIGENTE: NACIONAL Y SUPRANACIONAL</li>
                <li>CAN: ORGANISMOS REGULADORES.</li>
                <li>DECISION 706 - DEFINICIONES.</li>
                <li>DECISION 706 - NOTIFICACIÓN SANITARIA PHD Y PADHP.</li>
                <li>DECISION 706 - PROYECTO ROTULADO.</li>
                <li>DECISION 706 - GRUPO DE PRODUCTOS.</li>
                <li>DECISION 706 - RENOVACIÓN.</li>
                <li>DECISION 706 - RECONOCIMIENTO.</li>
                <li>DECISION 706 - 2DO IMPORTADOR.</li>
                <li>DECISION 706 - COMUNICACIÓN DE CAMBIOS.</li>
                <li>DECISION 706 - CIRCULACIÓN DE MIEMBROS.</li>
                <li>DECISION 706 - RESOLUCIÓN 1370.</li>
                <li>AGOTAMIENTO DE EXISTENCIAS EN PRODUCTOS SANITARIOS REGULADOS POR LA CAN-DECISIÓN 783.</li>
                <li>REGISTRADO SANITARIO DE PRODUCTOS PARA BEBÉ.</li>
                <ul>
                    <li>NORMATIVA NACIONAL.</li>
                    <li>DEFINICIONES.</li>
                    <li>RS Y CIRCULACIÓN DE ARTÍCULOS PARA BEBÉS.</li>
                    <li>CLASIFICACIÓN.</li>
                    <li>CALIDAD Y SEGURIDAD.</li>
                    <li>REQUISITOS PARA LA INSCRIPCIÓN AL RS.</li>
                    <li>DJ PARA LA INSCRIPCIÓN AL RS.</li>
                    <li>REQUISITOS PARA LA REINSCRIPCIÓN AL RS.</li>
                    <li>DJ PARA LA REINSCRIPCIÓN AL RS.</li>
                    <li>ROTULADO EL ENVASE DE LOS ARTÍCULOS PARA BEBÉS.</li>
                    <li>VIGENCIA, CODIFICACIÓN Y PLAZOS DE EVALUACIÓN.</li>
                    <li>CAMBIOS MENORES.</li>
                    <li>CAMBIOS MAYORES.</li>
                </ul>
              </ul>

              <a href={pdfCurso} download>
              <Button type="primary" shape="round" icon={<DownloadOutlined/>} size={'large'}>
                Descargar PDF
               </Button>
              </a>
            </Panel>
            <Panel key="3"
              style={{ backgroundColor:'#1890ff'}}
            >
              <ul>
                <li>Si desea registrar su participación en el webinar:GESTIÓN DE LA INFORMACIÓN PARA LA OBTENCIÓN DE: LA NOTIFICACIÓN SANITARIA DE PRODUCTOS DE HIGIENE DOMÉSTICA Y PRODUCTOS ABSORBENTES DE HIGIENE - REGISTRO SANITARIO DE PRODUCTOS, agradeceré llenar el siguiente formulario de inscripción: </li>
              </ul>
              <br/> <a href="https://forms.gle/NDbPnfuWm7PU2U5n7"rel="noopener noreferrer" target="_blank"><Button type="primary" shape="round" size={'large'}>FORMULARIO DE INSCRIPCIÓN </Button></a>
            </Panel>
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}