import React, { Component } from 'react'
import {
  Divider,
  Collapse
} from 'antd'

import '../../../../estilos-paginas/cursos/cursos.css'
import cursoVenderPeru from '../../../../assets/cursos/inHouse-Libres/cursoVenderPeru.jpg'

const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class ComoVenderAlEstadoProductosFarmaceuticos extends Component {
  render() {
    return(
      <>
        <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={cursoVenderPeru}
            style={{minWidth: 280, maxWidth: 350, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>COMO VENDER AL ESTADO PRODUCTOS FARMACÉUTICOS, DISPOSITIVOS MÉDICOS Y PRODUCTOS SANITARIOS MEDIANTE PROCESO DE LICITACIÓN PÚBLICA, ADJUDICACIÓN DIRECTA, DIRECTA SELECTIVA, COMPRA CORPORATIVA, CONVENIO MARCO, SUBASTA INVERSA ENTRE OTROS.</h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("1. ASPECTOS GENERALES")}
              <ul>
                <li>Conceptos básicos.</li>
                <li>Ámbito de aplicación de la normativa.</li>
                <li>Funciones del Organismo Supervisor de las Contrataciones del Estado(OSCE).</li>
                <li>El SEACE (Sistema Electrónico de Adquisiciones y Contrataciones del Estado).</li>
                <li>Órganos Responsables de la contratación pública.</li>
                <li>Registro Nacional de proveedores.</li>
                <li>Límites a la participación de proveedores.</li>
                <li>Fases de los procesos de contratación.</li>
              </ul>
              <hr />
              {subtitulo("2. PLANIFICACIÓN Y ACTUACIONES PREPARATORIAS")}
              <ul>
                <li>Plan anual de contrataciones, finalidad y características.</li>
                <li>Formulación, Contenido y Aprobación del PAC.</li>
                <li>Expediente de contratación.</li>
                <li>Requerimiento, Homologación, Valor estimado y referencial.</li>
                <li>Definición de requerimientos técnicos mínimos.</li>
                <li>Valor referencial y valor estimado.</li>
                <li>Certificación Presupuestal.</li>
                <li>Sistemas de Contratación.</li>
                <li>Modalidad de ejecución contractual.</li>
                <li>Documentos de procedimientos de selección.</li>
                <li>Compras corporativas.</li>
              </ul>
              <hr />
              {subtitulo("3. PROCESO DE SELECCIÓN")}
              <ul>
                <li>Disposiciones generales.</li>
                <li>Montos de procedimientos.</li>
                <li>Preguntas de casos prácticos.</li>
                <li>Tipos de procesos de selección: Licitación Pública, Concurso público, licitación Pública con precalificación, Adjudicación simplificada, selección de consultores Individuales, subasta inversa electrónica, Comparación de precios, contratación directa.</li>
                <li>Evaluación y calificación de ofertas.</li>
                <li>Otorgamiento de la buena Pro.</li>
              </ul>
              {subtitulo("4. EJECUCIÓN CONTRACTUAL")}
              <ul>
                <li>Suscripción del contrato.</li>
                <li>Nulidad del contrato.</li>
                <li>Subcontrataciones.</li>
                <li>Penalidades.</li>
                <li>Resolución de contrato.</li>
                <li>Cesión de derechos y de posición contractual.</li>
                <li>Adicionales y reducciones.</li>
                <li>Ampliación de plazo.</li>
                <li>Otras modificaciones al contrato.</li>
                <li>Recepción y conformidad.</li>
                <li>Constancia de prestación.</li>
                <li>Vicios ocultos.</li>
                <li>Adelanto y pago.</li>
                <li>Contrataciones complementarias.</li>
                <li>Garantías.</li>
              </ul>
            </Panel>
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}