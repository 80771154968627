import React, { Component } from 'react'
import {
  Divider,
  Collapse
} from 'antd'

import '../../../../estilos-paginas/cursos/cursos.css'
import cursoBuenasPracticasAlmacenamiento from '../../../../assets/cursos/inHouse-Libres/cursoBuenasPracticasAlmacenamiento.jpg'

const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class CertificacionBuenasPracticasDeAlmacenesEspecializados extends Component {
  render() {
    return(
      <>
        <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={cursoBuenasPracticasAlmacenamiento}
            style={{minWidth: 280, maxWidth: 350, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>CERTIFICACION EN BUENAS PRÁCTICAS DE ALMACENAMIENTO DE DROGUERIAS Y ALMACENES ESPECIALIZADOS</h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("TEMARIO")}
              <ul>
                <li>Normatividad y antecedentes de las Buenas Prácticas de Almacenamiento BPA.</li>
                <li>Sistema de Aseguramiento de Calidad:</li>
                  <ul>
                    <li>Manual de Calidad: Organigrama y funciones.</li>
                    <li>Documentación (Procedimientos Operativos y Registros).</li>
                  </ul>
                <li>Desarrollo de Guía de inspección de las Buenas Prácticas de Almacenamiento para Droguerías y Almacenes Especializados</li>
                <li>Sanciones.</li>
              </ul>
            </Panel>
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}