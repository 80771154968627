import React, { Component } from 'react'
import {
  Divider,
  Collapse,
  Button
} from 'antd'

import { DownloadOutlined } from '@ant-design/icons';

import '../../../../estilos-paginas/cursos/cursos.css'
import imagen from '../../../../assets/cursos/gratuitos/curso25/IPS-EPA1.png'
import pdfCurso from '../../../../assets/cursos/gratuitos/pdfcursos/CURSO_GESTION_DE_LAS_SOSPECHAS_2022.pdf'

const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class IPSEPASignals extends Component {
  render() {
    return(
      <>
        <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={imagen}
            style={{minWidth: 280, maxWidth: 350, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>Informe Periódico de Seguridad (IPS), Estudios Post Autorización (EPA), Gestión de Señales</h3>
              <h4 style={{ color: '#1890ff'}}>(Según lo indicado en el Manual de Buenas Prácticas de Farmacovigilancia, aprobado con R.M. N° 1053-2020/MINSA)</h4>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("TEMARIO")}
              <ul>
                <li>Informe Periódico de Seguridad (IPS).</li>
                <ul>
                  <li> Definición, objetivos.</li>
                  <li> Evaluación del balance beneficio/riesgo.</li>
                  <li> Estructura del IPS y Periodicidad de presentación a la ANM de acuerdo a la normatividad vigente.</li>
                </ul>
                <li>Estudios Post Autorización (EPA).</li>
                <ul>
                  <li> Definición, objetivos.</li>
                  <li> Tipos de estudio.</li>
                  <li> Estructura del Protocolo de estudio y del informe final de acuerdo a la normatividad vigente.</li>
                </ul>
                <li>Gestión de Señales.</li>
                <ul>
                  <li> Definición.</li>
                  <li> Fuentes de información para la identificación de señales.</li>
                  <li> Métodos de identificación de Señales.</li>
                </ul>
              </ul>

              <a href={pdfCurso} download>
              <Button type="primary" shape="round" icon={<DownloadOutlined/>} size={'large'}>
                Descargar PDF
               </Button>
              </a>
            </Panel>
            <Panel key="3"
              style={{ backgroundColor:'#1890ff'}}
            >
              <ul>
                <li>Si desea registrar su participación en el webinar: Informe Periódico de Seguridad (IPS), Estudios Post Autorización (EPA), Gestión de Señales, agradeceré llenar el siguiente formulario de inscripción: </li>
              </ul>
              <br/> <a href="https://forms.gle/fHFHr9fEbTMMevbX8"rel="noopener noreferrer" target="_blank"><Button type="primary" shape="round" size={'large'}>FORMULARIO DE INSCRIPCIÓN </Button></a>
            </Panel>
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}