import React, { Component } from 'react'
import {
  Divider,
  Collapse,
  Button
} from 'antd'

import { DownloadOutlined } from '@ant-design/icons';

import '../../../../estilos-paginas/cursos/cursos.css'
import imagen from '../../../../assets/cursos/gratuitos/curso32/BIM_1_2022.png'
import pdfCurso from '../../../../assets/cursos/gratuitos/pdfcursos/BROCHURE_BIM_2022.pdf'

const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class BusquedaYGestionDeLaLiteraturaCientificaSobreMedicamentos extends Component {
  render() {
    return(
      <>
        <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={imagen}
            style={{minWidth: 280, maxWidth: 350, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>BÚSQUEDA Y GESTIÓN DE LA LITERATURA CIENTÍFICA SOBRE MEDICAMENTOS</h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("TEMARIO")}
              <ul>
                <li>AGENCIAS REGULADORAS DE LOS PAÍSES DE ALTA VIGILANCIA.</li>
                <li>PÁGINAS CIENTÍFICAS DE REFERENCIAS PARA SUSTENTAR SEGURIDAD Y EFICIENCIA DE UN MEDICAMENTO O PARA SUSTENTAR SU CONDICIÓN DE VENTA.</li>
                <li>PÁGINAS CIENTÍFICAS DE REFERENCIAS PARA SUSTENTO EN LA ELABORACIÓN DE INFORME PERIÓDICO DE SEGURIDAD(IPS) Y/O PLAN DE GESTIÓN DE RIESGO(PGR).</li>
              </ul>

              <a href={pdfCurso} download>
              <Button type="primary" shape="round" icon={<DownloadOutlined/>} size={'large'}>
                Descargar PDF
               </Button>
              </a>
            </Panel>
            <Panel key="3"
              style={{ backgroundColor:'#1890ff'}}
            >
              <ul>
                <li>Si desea registrar su participación en el webinar: BÚSQUEDA Y GESTIÓN DE LA LITERATURA CIENTÍFICA SOBRE MEDICAMENTOS, agradeceré llenar el siguiente formulario de inscripción: </li>
              </ul>
              <br/> <a href="https://forms.gle/p9otqWHSEPgjgu6s8"rel="noopener noreferrer" target="_blank"><Button type="primary" shape="round" size={'large'}>FORMULARIO DE INSCRIPCIÓN </Button></a>
            </Panel>
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}