import React, { Component } from 'react'
import {
  Divider,
  Collapse
} from 'antd'

import '../../../../estilos-paginas/cursos/cursos.css'
import cursoFarmacoVigilancia from '../../../../assets/cursos/inHouse-Libres/cursoFarmacoVigilancia.jpg'

const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class ImplementacionSistemaFarmacovigilancia extends Component {
  render() {
    return(
      <>
        <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={cursoFarmacoVigilancia}
            style={{minWidth: 280, maxWidth: 350, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>“IMPLEMENTACION DEL SISTEMA DE FARMACOVIGILANCIA PARA LABORATORIOS, DROGUERIAS Y ALMACENES ESPECIALIZADOS TITULARES DE REGISTRO SANITARIO O CERTIFICADO DE REGISTRO SANITARIO"</h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("FARMACOVIGILANCIA")}
              <ul>
                <li>Introducción a la Farmacovigilancia: Historia y primeros casos.</li>
                <li>Conceptos básicos: RAM, SUSAR, Notificaciones espontaneas, IPS, RMP, entre otros conceptos.</li>
                <li>Farmacovigilancia dentro de la Industria Farmacéutica: Responsabilidad del titular de comercialización (TAC).</li>
                <li>El SEACE (Sistema Electrónico de Adquisiciones y Contrataciones del Estado).</li>
                <li>Descripción del procesamiento de un caso y su incorporación dentro de la empresa; seguimiento del caso.</li>
              </ul>
              <hr />
              {subtitulo("LEGISLACION EN FARMACOVIGILANCIA")}
              <ul>
                <li>Legislación Europea (EMA) y Legislación Americana (FDA)</li>
                <li>Normativa Peruana.</li>
                <li>Interpretación de la NTS N° 123- MINSA/DIGEMID-V01: Norma Técnica de Salud que regula las actividades de Farmacovigilancia y Tecnovigilancia de Productos Farmacéuticos, Dispositivos Médicos y Productos Sanitarios.</li>
              </ul>
              <hr />
              {subtitulo("IMPLEMENTACION DEL SISTEMA DE FARMACOVIGILANCIA")}
              <ul>
                <li>Análisis de la Empresa (TAC, Distribuidor, venta: local, nacional, internacional).</li>
                <li>Organización de la Empresa: Organigrama: Ubicación del Área de Farmacovigilancia.</li>
                <li>Responsable de Farmacovigilancia: Perfil y funciones.</li>
                <li>Interacción del área de Farmacovigilancia con otras áreas de la Empresa.</li>
                <li>Procedimientos operativos estandarizados utilizados en el sistema de farmacovigilancia de la organización.</li>
              </ul>
              {subtitulo("AUDITORIAS DEL SISTEMA DE FARMACOVIGILANCIA EN LA ORGANIZACIÓN")}
              <ul>
                <li>Generalidades.</li>
                <li>Procesos de Auditoría.</li>
                <li>Acciones correctivas y preventivas.</li>
                <li>Seguimiento de las no conformidades.</li>
                <li>Rol de Digemid en las actividades de Farmacovigilancia.</li>
              </ul>
            </Panel>
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}