import React, { useEffect, useContext } from 'react'

// import Router from 'next/router'
// import {
//   Form,
//   Input,
//   Button,
//   message,
//   Card,
// } from 'antd'
// import  tokenAuth  from '../../config/token'
// import clienteAxios from '../../config/axios'
import AuthContext from '../../context/autenticacion/authContext'

// import {AiOutlineUser, AiTwotoneLock} from 'react-icons/ai'
// import authContext from '../../context/autenticacion/authContext'



const Admin = (props) => {

  const authContext = useContext(AuthContext);
  //, iniciarSesion de authcontext
  const { mensaje, autenticado } = authContext;
  useEffect(() => {
    console.log('autenticado', autenticado)
    if(autenticado) {
      props.history.push('/admin/envio-mails');
    }

    if(mensaje) {
      console.log(`mensaje: `, mensaje.msg)
    }
  })
  // const formRef = React.createRef();
  // const onFinish = async values => {
  //   const token = localStorage.getItem('token');
  //   if(token) {
  //     tokenAuth(token)
  //   }
  //   try {
  //     console.log('clienteaxios', clienteAxios.post())
  //     const respuesta = await clienteAxios.post('/api/auth')
  //   } catch (error) {
  //     console.log("aea",error.response);
  //   }
  // }
  return (
    <div className="form-usuario">
      <div className="contenedor-form sombra-dark">
        <h1>Iniciar</h1>
      </div>
    </div>
  )
}


export default Admin