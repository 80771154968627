import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Titulo from '../../componentes/Titulo'
import { 
  Divider,
  Col,
  Form,
  Input,
  Card, 
  Row,
  Pagination,
  Button,
  Drawer,
  message
} from 'antd';
import { serviceCrearContacto } from '../../services/contacto'

import inHouse from '../../assets/cursos/inhouse-home.jpg'
import cursoBuenasPracticasDistribucion from '../../assets/cursos/inHouse-Libres/cursoBuenasPracticasDistribucion.jpg'
import cursoVenderPeru from '../../assets/cursos/inHouse-Libres/cursoVenderPeru.jpg'
import cursoFarmacoVigilancia from '../../assets/cursos/inHouse-Libres/cursoFarmacoVigilancia.jpg'
import cursoControlCalidad from '../../assets/cursos/inHouse-Libres/cursoControlCalidad.jpg'
import cursoBuenasPracticasAlmacenamiento from '../../assets/cursos/inHouse-Libres/cursoBuenasPracticasAlmacenamiento.jpg'
import comoaperturarunadrogueria from '../../assets/cursos/gratuitos/comoaperturarunadrogueria.jpg'
import Quedebemostenerencuentapararealizarelmapeotermicodeunalmacenylacalificaciondelembalajeisotermicodeunadrogueria from '../../assets/cursos/gratuitos/Quedebemostenerencuentapararealizarelmapeotermicodeunalmacenylacalificaciondelembalajeisotermicodeunadrogueria.jpg'
import QueDocumentosSonNecesariosParaLaElaboracionDeUnDossierParaLaInscripcionOReinscripcionDeUnaEspecialidadFarmaceutica from '../../assets/cursos/gratuitos/QUE DOCUMENTOS SON NECESARIOS  PARA LA ELABORACION DE UN DOSSIER PARA LA  INSCRIPCION O REINSCRIPCION DE UNA ESPECIALIDAD FARMACEUTICA1.jpg'
import GestionDeNotificacionesSanitariasDeProductosCosmeticos from '../../assets/cursos/gratuitos/GestiondeNotificacionesSanitariasdeProductosCosmeticos2.jpg'
import GestionParaLaImplementacionDelSistemaDeFarmacovigilanciaEnUnaDrogueriaYLaboratorioFarmaceutico from '../../assets/cursos/gratuitos/quintoCurso/GestionParaLaImplementacionDelSistemaDeFarmacovigilanciaEnUnaDrogueriaYLaboratorioFarmaceutico.png'
import ComoSolicitarYObtenerLaAutorizacionSanitariaDeUnaOficinaFarmaceutica from '../../assets/cursos/gratuitos/sextoCurso/AUTORIZACIONSANITARIAFOOTER.png'
import CertificacionEnBuenasPracticasDeAlmacenamiento from '../../assets/cursos/gratuitos/septimoCurso/CertificacionEnBuenasPracticasDeAlmacenamiento3.png'
import CertificacionEnBuenasPracticasDeDistribucionYTransporte from '../../assets/cursos/gratuitos/octavoCurso/CertificacionEnBuenasPracticasDeDistribucionYTransporte3.png'
import PesquisaYProcesoDeCalidadDeProductosFarmaceuticos from '../../assets/cursos/gratuitos/novenoCurso/pesquisaYProcesoDeControlDeCalidadDeProductosFarmaceuticos2.png'
import GestionParaImplementacionDelSistemaDeTecnovigilanciaEnUnaDrogueriaOLaboratorioDeDispositivoMedico from '../../assets/cursos/gratuitos/decimoCurso/gestionParaImplementacionDelSistemaDeTecnovigilanciaEnUnaDrogueriaOLaboratorioDeDispositivoMedico1.png'
import InformesPeriodicosDeSeguridadDeProductosFarmaceuticos from '../../assets/cursos/gratuitos/duoDecimoCurso/InformesPeriodicosDeSeguridadDeProductosFarmaceuticos2.png'
import BusquedaYGestionDeLaLiteraturaCientificaSobreMedicamentos from '../../assets/cursos/gratuitos/unDecimoCurso/BusquedaYGestionDeLaLiteraturaCientificaSobreMedicamentos2.png'
import VigilanciaPrevencionYControlDeLaEnfermedadPorCoronavirus from '../../assets/cursos/gratuitos/decimoTercerCurso/covid19_1.png'
import RequisitosTecnicosParaSolicitarYObtenerLaAutorizacionSanitariaDeUnaDrogueria from '../../assets/cursos/gratuitos/decimoCuartoCurso/REQUISITOS-TECNICOS-PARA-SOLICITAR-Y-OBTENER-LA-AUTORIZACION-SANITARIA-DE-UNA-DROGUERIA.png'
import GestionDeLaInformacionParaLaElaboracionDelDossierDeDispositivosMedicosParaLaInscripcionOReinscripcionEnElRegistroSanitario from '../../assets/cursos/gratuitos/decimoQuintoCurso/gestionDeLaInformacionParaLaELaboracionDelDossierDeDispositivosMedicosParaLaInscripcionOReinscripcionEnELRegistroSanitario2.png'
import GestionDeLaInformacionParaLaElaboracionDelDossierDeEspecialidadesFarmaceuticasParaLaInscripcionOReinscripcionEnElRegistroSanitario from '../../assets/cursos/gratuitos/decimoSextoCurso/GestionDeLaInformacionParaLaElaboracionDelDossierDeEspecialidadesFarmaceuticasParaLaInscripcionOReinscripcionEnElRegistroSanitario1.png'
import IntercambiabilidadDeMedicamentos from '../../assets/cursos/gratuitos/decimoSeptimoCurso/intercambiabilidadDeMedicamentos2.png';
import ManejoYControlDeEstupefacientesYPsicotropicosEnEstablecimientosFarmaceuticos from '../../assets/cursos/gratuitos/decimoOctavoCurso/ManejoYControlDeEstupefacientesYPsicotropicosEnEstablecimientosFarmaceuticos2.png'
import ProcedimientoAdministrativoGeneralEnEstablecimientosFarmaceuticos from '../../assets/cursos/gratuitos/decimoNovenoCurso/ProcedimientoAdministrativoGeneralEnEstablecimientosFarmaceuticos2.png'
import BuenasPracticasEnLaCadenaDeFrioParaProductosFarmaceuticosBiologicosYODispositivosMedicos from '../../assets/cursos/gratuitos/curso20/BuenasPracticasEnLaCadenaDeFrioParaProductosFarmaceuticosBiologicosYODispositivosMedicos2.png'
import CertificacionEnBuenasPracticasDeAlmacenamientoParaDrogueriasYAlmacenesEspecializados2022 from '../../assets/cursos/gratuitos/curso21/BPA.png'
import CertificacionEnBuenasPracticasDeDistribucionYTransporte2022 from '../../assets/cursos/gratuitos/curso22/BDPT1.png'
import BuenasPracticasDeFarmacovigilanciaEnUnaDrogueriaYLaboratorioFarmaceutico from '../../assets/cursos/gratuitos/curso23/CURSO_BUENAS_PRACTICAS_FV.png'
import GestionDeLasSospechasDeReaccionesAdversasALosMedicamentos from '../../assets/cursos/gratuitos/curso24/CURSO_GESTION_DE_LAS_SOSPECHAS.png'
import IPSEPASignals from '../../assets/cursos/gratuitos/curso25/IPS-EPA1.png';
import PESQUISA20221 from '../../assets/cursos/gratuitos/curso26/PESQUISA_1.png'
import DossierPDyPN from '../../assets/cursos/gratuitos/curso27/DossierPDyPN2.png'
import DossierDMINSCYREINSCRS from '../../assets/cursos/gratuitos/curso28/ELABORACIONDOSSIERDMIRRS.png'
import CBPOficinaFarmaceutica from '../../assets/cursos/gratuitos/curso29/CBPOficinaFarmaceutica2.png';
import GIPONSOProductosCosmeticos from '../../assets/cursos/gratuitos/curso30/GIPONSOProductosCosmeticos1.png'
import NSOHD2022 from '../../assets/cursos/gratuitos/curso31/NSOHD1.png';
import BIM2022 from '../../assets/cursos/gratuitos/curso32/BIM_1_2022.png'
import CADENADEFRIO2023 from '../../assets/cursos/gratuitos/curso33/cadenaFrio1.png'
import CBPA2023 from '../../assets/cursos/gratuitos/curso34/flyer.png'
import CBPDT2023 from '../../assets/cursos/gratuitos/curso35/flyer.png'
import NRIMP2023 from '../../assets/cursos/gratuitos/curso36/FLYER.png'
import GISFDLF2023 from '../../assets/cursos/gratuitos/curso37/FLYER.png'
import GIEDEMDMIRRS2023 from '../../assets/cursos/gratuitos/curso38/FLYER.png'
import MAPEOTERMICODEALMACENES2023 from '../../assets/cursos/gratuitos/curso39/FLYER.png'
import PESQUISAYPROCESODECONTROLDECALIDAD2023 from '../../assets/cursos/gratuitos/curso40/FLYER.png'
import GESTIONEIMPLEMENTACIONDELASBUENASPRACTICAS2024 from '../../assets/cursos/gratuitos/curso41/FLYER.png'
import GESTIONPARALAIMPLEMENTACIONDELSISTEMADEFARMACOVIGILANCIADEUNADROGUERIA2024 from '../../assets/cursos/gratuitos/curso42/FLYER.png'
import GestionDeLaInformacionParaLaObtencionDeLaNotificacionSanitariaObligatoriaDeProductosCosmeticosHigieneDomesticaYAbsorbentesDeHigieneJunio from '../../assets/cursos/gratuitos/curso43/FLYER.png'
import ManejoYControlDeEstupefacientesYPsicotropicosEnEstablecimientosFarmaceuticos2024 from '../../assets/cursos/gratuitos/2024/curso44/FLYER.png'  
import ProcedimientoAdministrativoGeneralEnEstablecimientosFarmaceuticos2024 from '../../assets/cursos/gratuitos/2024/curso45/FLYER_.png'
import BusquedaYGestionDeLaLiteraturaCientificaSobreMedicamentos2024 from '../../assets/cursos/gratuitos/2024/curso46/FLYER_BUSQ_INF.png';


const {Meta} = Card

const validateMessages = {
  required: `Es un campo requerido!`,
  types: {
    email: `No es un correo electrónico válido!`,
  },
};

//datos de cursos
const data = [
  { 
    id:'0',
    direccion: '2020/buenas-practicas-de-distribucion-y-transporte',
    titulo: '',
    descripcion: 'BUENAS PRÁCTICAS DE DISTRIBUCIÓN Y TRANSPORTE (Según los lineamientos detallados en el Manual y Guía de Inspección emitida por DIGEMID)',
    imagen: cursoBuenasPracticasDistribucion
  },
  { 
    id:'1',
    direccion: '2020/como-vender-al-estado-productos-farmaceuticos',
    titulo: '',
    descripcion: 'COMO VENDER AL ESTADO PRODUCTOS FARMACÉUTICOS, DISPOSITIVOS MÉDICOS Y PRODUCTOS SANITARIOS MEDIANTE PROCESO DE LICITACIÓN PÚBLICA, ADJUDICACIÓN DIRECTA, DIRECTA SELECTIVA, COMPRA CORPORATIVA, CONVENIO MARCO, SUBASTA INVERSA ENTRE OTROS.',
    imagen: cursoVenderPeru
  },
  { 
    id:'2',
    direccion: '2020/implementacion-del-sistema-de-farmacovigilancia-para-laboratorios,-droguerias-y-almacenes-especializados',
    titulo: '',
    descripcion: 'IMPLEMENTACIÓN DEL SISTEMA DE FARMACOVIGILANCIA PARA LABORATORIOS, DROGUERÍAS Y ALMACENES ESPECIALIZADOS TITULARES DE REGISTRO SANITARIO O CERTIFICADO DE REGISTRO SANITARIO',
    imagen: cursoFarmacoVigilancia
  },
  { 
    id:'3',
    direccion: '2020/autorizaciones-sanitarias-de-establecimientos-farmaceuticos',
    titulo: '',
    descripcion: 'AUTORIZACIONES SANITARIAS DE ESTABLECIMIENTOS FARMACÉUTICOS "DROGUERÍAS Y ALMACENES ESPECIALIZADOS" 1',
    imagen: inHouse
  },
  { 
    id:'4',
    direccion: '2020/certificacion-en-buenas-practicas-de-almacenamiento-de-droguerias-y-almacenes-especializados',
    titulo: '',
    descripcion: 'CERTIFICACIÓN EN BUENAS PRÁCTICAS DE ALMACENAMIENTO DE DROGUERIAS Y ALMACENES ESPECIALIZADOS',
    imagen: cursoBuenasPracticasAlmacenamiento
  },
  { 
    id:'5',
    direccion: '2020/regulacion-en-el-control-de-calidad-de-productos-farmaceuticos,-dispositivos-medicos-y-sanitarios',
    titulo: '',
    descripcion: 'REGULACIÓN EN EL CONTROL DE CALIDAD DE PRODUCTOS FARMACÉUTICOS, DISPOSITIVOS MÉDICOS Y SANITARIOS',
    imagen: cursoControlCalidad
  },
  { 
    id:'6',
    direccion: '2020/Como-aperturar-una-droguería',
    titulo: '',
    descripcion: '¿CÓMO APERTURAR UNA DROGUERIA?',
    imagen: comoaperturarunadrogueria
  },
  {
    id:'7',
    direccion: '2020/Que-debemos-tener-en-cuenta-para-realizar-el-mapeo-termico-de-un-almacen-y-la-calificacion-del-embalaje-isotermico-de-una-drogueria',
    titulo: '',
    descripcion: '¿Qué debemos tener en cuenta para realizar el mapeo térmico de un almacén y la calificación del embalaje isotérmico de una Droguería?',
    imagen: Quedebemostenerencuentapararealizarelmapeotermicodeunalmacenylacalificaciondelembalajeisotermicodeunadrogueria
  },
  {
    id: '8',
    direccion: '2020/Que-documentos-son-necesarios-para-la-elaboracion-de-un-dossier-para-la-inscripcion-o-reinscripcion-de-una-especialidad-farmaceutica',
    titulo: '',
    descripcion: '¿QUE DOCUMENTOS SON NECESARIOS PARA LA ELABORACIÓN DE UN DOSSIER PARA LA INSCRIPCIÓN O REINSCRIPCIÓN DE UNA ESPECIALIDAD FARMACÉUTICA?',
    imagen: QueDocumentosSonNecesariosParaLaElaboracionDeUnDossierParaLaInscripcionOReinscripcionDeUnaEspecialidadFarmaceutica
  },
  {
    id: '9',
    direccion: '2020/Gestion-de-Notificaciones-Sanitarias-de-Productos-Cosmeticos',
    titulo: '',
    descripcion: 'Gestión de Notificaciones Sanitarias de Productos Cosméticos',
    imagen: GestionDeNotificacionesSanitariasDeProductosCosmeticos 

  },
  {
    id: '10',
    direccion: '2020/Gestion-Para-La-Implementacion-Del-Sistema-De-Farmaco-vigilancia-En-Una-Drogueria-Y-Laboratorio-Farmaceutico',
    titulo: '',
    descripcion: 'Gestión para la implementación del sistema de farmaco vigilancia en una droguería y laboratorio farmacéutico.',
    imagen: GestionParaLaImplementacionDelSistemaDeFarmacovigilanciaEnUnaDrogueriaYLaboratorioFarmaceutico
  },
  {
    id: '11',
    direccion: '2020/Como-solicitar-y-obtener-la-autorizacion-sanitaria-de-una-oficina-farmaceutica',
    titulo: '',
    descripcion: '¿CÓMO SOLICITAR Y OBTENER LA AUTORIZACIÓN SANITARIA DE UNA OFICINA FARMACÉUTICA?',
    imagen: ComoSolicitarYObtenerLaAutorizacionSanitariaDeUnaOficinaFarmaceutica
  },
  {
    id: '12',
    direccion: '2020/Certificacion-en-buenas-practicas-de-almacenamiento',
    titulo:'',
    descripcion: "CERTIFICACIÓN EN BUENAS PRÁCTICAS DE ALMACENAMIENTO",
    imagen: CertificacionEnBuenasPracticasDeAlmacenamiento
  },
  {
    id: '13',
    direccion: '2020/Certificacion-en-buenas-practicas-de-distribucion-y-transporte',
    titulo: '',
    descripcion: 'CERTIFICACIÓN EN BUENAS PRÁCTICAS DE DISTRIBUCIÓN Y TRANSPORTE',
    imagen: CertificacionEnBuenasPracticasDeDistribucionYTransporte
  },
  {
    id: '14',
    direccion: '2020/Pesquisa-y-proceso-de-control-de-calidad-de-productos-farmaceuticos',
    titulo: '',
    descripcion: 'PESQUISA Y PROCESO DE CONTROL DE CALIDAD DE PRODUCTOS FARMACÉUTICOS',
    imagen: PesquisaYProcesoDeCalidadDeProductosFarmaceuticos
  },
  {
    id:'15',
    direccion:'2021/Gestion-para-implementacion-del-sistema-de-tecnovigilancia-en-una-drogueria-o-laboratorio-de-dispositivo-medico',
    titulo:'',
    descripcion: 'GESTION PARA LA IMPLEMENTACIÓN DEL SISTEMA DE TECNOVIGILANCIA EN UNA DROGUERÍA O LABORATORIO DE DISPOSITIVO MÉDICO',
    imagen: GestionParaImplementacionDelSistemaDeTecnovigilanciaEnUnaDrogueriaOLaboratorioDeDispositivoMedico
  },
  {
    id: '16',
    direccion:'2021/Busqueda-y-gestion-de-la-literatura-cientifica-sobre-medicamentos',
    titulo:'',
    descripcion: 'BÚSQUEDA Y GESTIÓN DE LA LITERATURA CIENTÍFICA SOBRE MEDICAMENTOS',
    imagen: BusquedaYGestionDeLaLiteraturaCientificaSobreMedicamentos
  },
  {
    id: '17',
    direccion: '2021/Informes-Periodicos-De-Seguridad-De-Productos-Farmaceuticos',
    titulo:'',
    descripcion: 'INFORMES PERIÓDICOS DE SEGURIDAD DE PRODUCTOS FARMACÉUTICOS',
    imagen: InformesPeriodicosDeSeguridadDeProductosFarmaceuticos
  },
  {
    id: '18',
    direccion: '2021/covid19_1',
    titulo:'',
    descripcion: 'VIGILANCIA, PREVENCIÓN Y CONTROL DE LA ENFERMEDAD POR CORONAVIRUS(COVID-19)',
    imagen: VigilanciaPrevencionYControlDeLaEnfermedadPorCoronavirus
  },
  {
    id: '19',
    direccion: '2021/Requisitos-tecnicos-para-solicitar-y-obtener-la-autorizacion-sanitaria-de-una-drogueria',
    titulo:'',
    descripcion: 'REQUISITOS TÉCNICOS PARA SOLICITAR Y OBTENER LA AUTORIZACIÓN SANITARIA DE UNA DROGUERÍA',
    imagen: RequisitosTecnicosParaSolicitarYObtenerLaAutorizacionSanitariaDeUnaDrogueria
  }, 
  {
    id: '20',
    direccion: '2021/Gestion-de-la-informacion-para-la-elaboracion-del-dossier-de-dispositivos-medicos-para-la-inscripcion-o-reinscripcion-en-el-registro-sanitario',
    titulo: '',
    descripcion: 'GESTIÓN DE LA INFORMACIÓN PARA LA ELABORACIÓN DEL DOSSIER DE DISPOSITIVOS MÉDICOS PARA LA INSCRIPCIÓN O REINSCRIPCIÓN EN EL REGISTRO SANITARIO',
    imagen: GestionDeLaInformacionParaLaElaboracionDelDossierDeDispositivosMedicosParaLaInscripcionOReinscripcionEnElRegistroSanitario
  }, {
    id: '21',
    direccion: '2021/Gestion-De-La-Informacion-Para-La-Elaboracion-Del-Dossier-De-Especialidades-Farmaceuticas-Para-La-Inscripcion-O-Reinscripcion-En-El-Registro-Sanitario',
    titulo: '',
    descripcion: 'GESTIÓN DE LA INFORMACIÓN PARA LA ELABORACIÓN DEL DOSSIER DE ESPECIALIDADES FARMACÉUTICAS PARA LA INSCRIPCIÓN O REINSCRIPCIÓN EN EL REGISTRO SANITARIO',
    imagen: GestionDeLaInformacionParaLaElaboracionDelDossierDeEspecialidadesFarmaceuticasParaLaInscripcionOReinscripcionEnElRegistroSanitario,
  }, {
    id: '22',
    direccion: '2021/Intercambiabilidad-De-Medicamentos',
    titulo: '',
    descripcion: 'INTERCAMBIABILIDAD DE MEDICAMENTOS',
    imagen: IntercambiabilidadDeMedicamentos,
  }, {
    id: '23',
    direccion: '2021/Manejo-Y-Control-De-Estupefacientes-Y-Psicotropicos-En-Establecimientos-Farmaceuticos',
    titulo: '',
    descripcion: "MANEJO Y CONTROL DE ESTUPEFACIENTES Y PSICOTRÓPICOS EN ESTABLECIMIENTOS FARMACÉUTICOS",
    imagen: ManejoYControlDeEstupefacientesYPsicotropicosEnEstablecimientosFarmaceuticos,
  }, {
    id: '24',
    direccion: '2021/Procedimiento-Administrativo-General-En-Establecimientos-Farmaceuticos',
    titulo: '',
    descripcion: 'PROCEDIMIENTO ADMINISTRATIVO GENERAL EN ESTABLECIMIENTOS FARMACÉUTICOS',
    imagen: ProcedimientoAdministrativoGeneralEnEstablecimientosFarmaceuticos
  }, {
    id: '25',
    direccion: '2021/Buenas-Practicas-En-La-Cadena-De-Frio-Para-Productos-Farmaceuticos-Biologicos-Y-O-Dispositivos-Medicos',
    titulo: '',
    descripcion: 'BUENAS PRÁCTICAS EN LA CADENA DE FRÍO PARA PRODUCTOS FARMACÉUTICOS, BIOLÓGICOS Y/O DISPOSITIVOS MÉDICOS',
    imagen: BuenasPracticasEnLaCadenaDeFrioParaProductosFarmaceuticosBiologicosYODispositivosMedicos
  }, {
    id: '26',
    direccion: '2022/Certificacion-En-Buenas-Practicas-De-Almacenamiento-Para-Droguerias-Y-Almacenes-Especializados',
    titulo:'',
    descripcion: 'CERTIFICACIÓN EN BUENAS PRÁCITCAS DE ALMACENAMIENTO PARA DROGUERÍAS Y ALMACENES ESPECIALIZADOS',
    imagen: CertificacionEnBuenasPracticasDeAlmacenamientoParaDrogueriasYAlmacenesEspecializados2022
  },  {
    id: '27',
    direccion: '2022/Certificacion-En-Buenas-Practicas-De-Distribucion-y-Transporte',
    titulo:'',
    descripcion: 'CERTIFICACIÓN EN BUENAS PRÁCITCAS DE DISTRIBUCIÓN Y TRANSPORTE',
    imagen: CertificacionEnBuenasPracticasDeDistribucionYTransporte2022
  },   {
    id: '28',
    direccion: '2022/Buenas-Practicas-De-Farmacovigilancia-En-Una-Drogueria-Y-Laboratorio-Farmaceutico',
    titulo:'',
    descripcion: 'BUENAS PRÁCTICAS DE FARMACOVIGILANCIA EN UNA DROGUERÍA Y LABORATORIO FARMACÉUTICO',
    imagen: BuenasPracticasDeFarmacovigilanciaEnUnaDrogueriaYLaboratorioFarmaceutico
  },   {
    id: '29',
    direccion: '2022/Gestion-De-Las-Sospechas-De-Reacciones-Adversas-A-Los-Medicamentos',
    titulo:'',
    descripcion: 'GESTIÓN DE LAS SOSPECHAS DE REACCIONES ADVERSAS A LOS MEDICAMENTOS',
    imagen: GestionDeLasSospechasDeReaccionesAdversasALosMedicamentos
  }, {
    id: '30',
    direccion: '2022/IPS-EPA-Signals',
    titulo:'',
    descripcion: 'Informe Periódico de Seguridad (IPS), Estudios Post Autorización (EPA), Gestión de Señales',
    imagen: IPSEPASignals
  }, {
    id: '31',
    direccion: '2022/Pesquisa-04-2022',
    titulo: '',
    descripcion: 'Pesquisa y proceso de control de calidad de PRODUCTOS FARMACÉUTICOS, DISPOSITIVOS MÉDICOS y PRODUCTOS SANITARIOS',
    imagen: PESQUISA20221
  }, 
  {
    id: '32',
    direccion: '2022/Elaboracion-Dossier-De-Productos-Dieteticos-Y-Naturales',
    titulo: '',
    descripcion:'REQUISITOS TÉCNICOS PARA LA ELABORACIÓN DEL DOSSIER DE PRODUCTOS DIETÉTICOS Y PRODUCTOS NATURALES PARA LA OBTENCIÓN DEL REGISTRO SANITARIO',
    imagen: DossierPDyPN
  },
  {
    id: '33',
    direccion: '2022/Elaboracion-Dossier-De-DISPOSITIVOS-MEDICOS-INSCRIPCION-REINSCRIPCION-RS',
    titulo: '',
    descripcion: 'GESTIÓN DE LA INFORMACIÓN PARA LA ELABORACIÓN DEL DOSSIER DE DISPOSITIVOS MÉDICOS PARA LA INSCRIPCION O REINSCRIPCIÓN EN EL REGISTRO SANITARIO',
    imagen: DossierDMINSCYREINSCRS
  },
  {
    id: '34',
    direccion: '2022/Certificacion-Buenas-Practicas-Oficina-Farmaceutica',
    titulo: '',
    descripcion: 'CERTIFICACIÓN DE BUENAS PRÁCTICAS DE OFICINA FARMACÉUTICA',
    imagen: CBPOficinaFarmaceutica
  },
  {
    id: '35',
    direccion: '2022/GESTION-DE-LA-INFORMACION-PARA-LA-OBTENCION-DE-LA-NOTIFICACION-SANITARIA-OBLIGATORIA-DE-PRODUCTOS-COSMETICOS',
    titulo: '',
    descripcion: 'GESTIÓN DE LA INFORMACIÓN PARA LA OBTENCIÓN DE LA NOTIFICACIÓN SANITARIA OBLIGATORIA DE PRODUCTOS COSMÉTICOS',
    imagen: GIPONSOProductosCosmeticos

  },
  {
    id: '36',
    direccion: '2022/GESTION-DE-LA-INFORMACION-PARA-LA-OBTENCION-DE-LA-NOTIFICACION-SANITARIA-DE-PRODUCTOS-DE-HIGIENE-DOMESTICA-Y-PRODUCTOS-ABSORBENTES-DE-HIGIENE-REGISTRO-SANITARIO-DE-PRODUCTOS',
    titulo: '',
    descripcion: 'GESTIÓN DE LA INFORMACIÓN PARA LA OBTENCIÓN DE: LA NOTIFICACIÓN SANITARIA DE PRODUCTOS DE HIGIENE DOMÉSTICA Y PRODUCTOS ABSORBENTES DE HIGIENE - REGISTRO SANITARIO DE PRODUCTOS',
    imagen: NSOHD2022
  },
  {
    id: '37',
    direccion: '2022/BUSQUEDA-Y-GESTION-DE-LA-LITERATURA-CIENTIFICA-SOBRE-MEDICAMENTOS',
    titulo: '',
    descripcion: 'BÚSQUEDA Y GESTIÓN DE LA LITERATURA CIENTÍFICA SOBRE MEDICAMENTOS',
    imagen: BIM2022
  }  ,
  {
    id: '38',
    direccion: '2023/BUENAS-PRACTICAS-DE-CADENA-DE-FRIO-PARA-PRODUCTOS-FARMACEUTICOS-BIOLOGICOS-Y-O-DISPOSITIVOS-MEDICOS',
    titulo: '',
    descripcion: 'BUENAS PRÁCTICAS DE CADENA DE FRÍO PARA PRODUCTOS FARMACÉUTICOS BIOLÓGICOS Y/O DISPOSITIVOS MÉDICOS',
    imagen: CADENADEFRIO2023
  },
  {
    id: '39',
    direccion: '2023/BUENAS-PRACTICAS-DE-ALMACENAMIENTO',
    titulo: '',
    descripcion: 'CERTIFICACIÓN EN BUENAS PRÁCITCAS DE ALMACENAMIENTO PARA DROGUERÍAS Y ALMACENES ESPECIALIZADOS',
    imagen: CBPA2023
  },
  {
    id: '40',
    direccion: '2023/CERTIFICACION-BUENAS-PRACTICAS-DE-DISTRIBUCION-Y-TRANSPORTE',
    titulo: '',
    descripcion: 'CERTIFICACIÓN EN BUENAS PRÁCITCAS DE DISTRIBUCIÓN Y TRANSPORTE',
    imagen: CBPDT2023
  },
  {
    id: '41',
    direccion: '2023/NORMATIVA-QUE-REGULA-LA-INTERCAMBIABILIDAD-DE-MEDICAMENTOS-EN-EL-PERU',
    titulo: '',
    descripcion: 'NORMATIVA QUE REGULA LA INTERCAMBIABILIDAD DE MEDICAMENTOS EN EL PERU',
    imagen: NRIMP2023
  },
  {
    id: '42',
    direccion: '2023/GESTION-PARA-LA-IMPLEMENTACION-DEL-SISTEMA-DE-FARMACOVIGILANCIA-EN-UNA-DROGUERIA-Y-LABORATORIO-FARMACEUTICO',
    titulo: '',
    descripcion: 'GESTIÓN PARA LA IMPLEMENTACIÓN DEL SISTEMA DE FARMACOVIGILANCIA EN UNA DROGUERÍA Y LABORATORIO FARMACÉUTICO',
    imagen: GISFDLF2023
  },
  {
    id: '43',
    direccion: '2023/GESTION-DE-LA-INFORMACION-PARA-LA-ELABORACION-DEL-DOSSIER-DE-UNA-ESPECIALIDAD-FARMACEUTICA-DISPOSITIVOS-MEDICOS-PARA-LA-INSCRIPCION-O-REINSCRIPCION-EN-EL-REGISTRO-SANITARIO',
    titulo: '',
    descripcion: 'GESTIÓN DE LA INFORMACIÓN PARA LA ELABORACIÓN DEL DOSSIER DE UNA ESPECIALIDAD FARMACÉUTICA, DISPOSITIVOS MÉDICOS PARA LA INSCRIPCIÓN O REINSCRIPCIÓN EN EL REGISTRO SANITARIO',
    imagen: GIEDEMDMIRRS2023
  },
  {
    id: '44',
    direccion: '2023/CRITERIOS_TECNICOS_PARA_REALIZAR_EL_MAPEO_TERMICO_DE_ALMACENES_Y_CALIFICACION_DE_VEHICULOS_DE_TRANSPORTE_Y_CAMARA_FRIA_EN_UNA_DROGUERIA_O_ALMACEN_ESPECIALIZADO',
    titulo: '',
    descripcion: 'CRITERIOS TÉCNICOS PARA REALIZAR EL MAPEO TERMICO DE ALMACENES y CALIFICACION DE VEHICULOS DE TRANSPORTE Y CAMARA FRIA EN UNA DROGUERIA O ALMACEN ESPECIALIZADO',
    imagen: MAPEOTERMICODEALMACENES2023
  },
  {
    id: '45',
    direccion: '2023/PESQUISA_Y_PROCESO_DE_CONTROL_DE_CALIDAD_DE_PRODUCTOS_FARMACEUTICOS_DISPOSITIVOS_MEDICOS_Y_PRODUCTOS_SANITARIOS',
    titulo: '',
    descripcion: 'PESQUISA Y PROCESO DE CONTROL DE CALIDAD DE PRODUCTOS FARMACÉUTICOS, DISPOSITIVOS MÉDICOS Y PRODUCTOS SANITARIOS',
    imagen: PESQUISAYPROCESODECONTROLDECALIDAD2023
  },
  {
    id: '45',
    direccion: '2024/GESTION_E_IMPLEMENTACION_DE_LAS_BUENAS_PRACTICAS_DE_OFICINA_FARMACEUTICA',
    titulo: '',
    descripcion: 'GESTIÓN E IMPLEMENTACIÓN DE LAS BUENAS PRÁCTICAS DE OFICINA FARMACÉUTICA',
    imagen: GESTIONEIMPLEMENTACIONDELASBUENASPRACTICAS2024
  },
  {
    id: '46',
    direccion: '2024/GESTION_PARA_LA_IMPLEMENTACION_DEL_SISTEMA_DE_FARMACOVIGILANCIA_EN_UNA_DROGUERIA_Y_LABORATORIO_FARMACEUTICO',
    titulo: '',
    descripcion: 'GESTIÓN PARA LA IMPLEMENTACIÓN DEL SISTEMA DE FARMACOVIGILANCIA DE UNA DROGUERÍA',
    imagen: GESTIONPARALAIMPLEMENTACIONDELSISTEMADEFARMACOVIGILANCIADEUNADROGUERIA2024
  },
  {
    id: '47',
    direccion: '2024/GESTION_DE_LA_INFORMACIÓN_PARA_LA_OBTENCIÓN_DE_LA_NOTIFICACIÓN_SANITARIA_OBLIGATORIA_DE_PRODUCTOS_COSMÉTICOS-PRODUCTOS_DE_HIGIENE_DOMÉSTICA_Y_PRODUCTOS_ABSORBENTES_DE_HIGIENE-REGISTRO_SANITARIO_DE_PRODUCTOS_SANITARIOS_ARTÍCULOS_PARA_BEBÉS',
    titulo: '',
    descripcion: 'GESTIÓN DE LA INFORMACIÓN PARA LA OBTENCIÓN DE: LA NOTIFICACIÓN SANITARIA OBLIGATORIA DE PRODUCTOS COSMÉTICOS - PRODUCTOS DE HIGIENE DOMÉSTICA Y PRODUCTOS ABSORBENTES DE HIGIENE - REGISTRO SANITARIO DE PRODUCTOS SANITARIOS ARTÍCULOS PARA BEBÉS',
    imagen: GestionDeLaInformacionParaLaObtencionDeLaNotificacionSanitariaObligatoriaDeProductosCosmeticosHigieneDomesticaYAbsorbentesDeHigieneJunio
  },
  {
    id: '48',
    direccion: '2024/MANEJO_Y_CONTROL_DE_ESTUPEFACIENTES_Y_PSICOTROPICOS_EN_ESTABLECIMIENTOS_FARMACEUTICOS',
    titulo: '',
    descripcion: 'MANEJO Y CONTROL DE ESTUPEFACIENTES Y PSICOTROPICOS EN ESTABLECIMIENTOS FARMACEUTICOS',
    imagen: ManejoYControlDeEstupefacientesYPsicotropicosEnEstablecimientosFarmaceuticos2024
  },
  {
    id: '49',
    direccion: '2024/PROCEDIMIENTO_ADMINISTRATIVO_GENERAL_EN_ESTABLECIMIENTOS_FARMACEUTICO',
    titulo: '',
    descripcion: 'PROCEDIMIENTO ADMINISTRATIVO GENERAL EN ESTABLECIMIENTOS FARMACÉUTICO',
    imagen: ProcedimientoAdministrativoGeneralEnEstablecimientosFarmaceuticos2024
  },
  {
    id: '50',
    direccion: '2024/BUSQUEDA_Y_GESTION_DE_LA_LITERATURA_CIENTIFICA_SOBRE_MEDICAMENTOS',
    titulo: '',
    descripcion: 'BÚSQUEDA Y GESTIÓN DE LA LITERATURA CIENTÍFICA SOBRE MEDICAMENTOS',
    imagen: BusquedaYGestionDeLaLiteraturaCientificaSobreMedicamentos2024
  }
  
  ]

export default class Cursos extends Component {
  state = { visible: false }
  formRef = React.createRef();

  onFinish = async (e) => {
    console.log(e.contacto)
    try {
      await serviceCrearContacto(e.contacto)
      message.success('Se añadio correctamente.')
      this.formRef.current.resetFields();
    } catch(e) {
      message.error('El correo ya esta añadido a la base de datos.')
      throw e; 
    }
  }

  showDrawer = () => {
    this.setState({
      visible:true
    })
  }

  onClose = () => {
    this.setState({
      visible: false,
    })
  }

  constructor(props) {
    super(props);
      this.state = {
        minValue: 0,
        maxValue: 4
    }
  }

  handleChange = value => {
    if(value <= 1) {
      this.setState({
        minValue: 0,
        maxValue: 4
      });
    } else {
      this.setState({
        minValue: this.state.maxValue,
        maxValue: value * 4
      })
    }
  }

  render() {
    return (
      <div>
        <p />
        <Titulo nombre="Cursos" />
        <Divider />
        <Card>
          <Row gutter={[24,24]} type="flex" style={{padding: 0}} >
            {data &&
              data.length > 0 &&
              data.slice(this.state.minValue, this.state.maxValue).map(val =>(
                <Col span={12} xs={24} sm={24} md={24} lg={24}  xl={12} 
                  style={{alignContent:'center'}} key={val.id}>
                  <Link to={`/cursos/${val.direccion}`}>
                    <Card
                      
                      hoverable
                      style={{ width: '100%' }}
                      cover={<img alt={val.direccion} src={val.imagen} />}
                     
                    >
                      <Meta title={val.titulo} description={val.descripcion} />
                    </Card>
                  </Link>
                </Col>
              ))
            }
          </Row>
          <Pagination total={data.length} defaultPageSize={4} onChange={this.handleChange}/>
        </Card>
        <Divider />
        <div className="button-contact">
          <p>Quiero obtener información de los cursos y futuros cursos que brinda SGA FARMA.</p>
          <p><Button type="primary" onClick={this.showDrawer} >
            Click aquí
          </Button></p>
        </div>
        <Drawer 
          title="Informacion de cursos"
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
          width={310}
        > 
          <Form ref={this.formRef} onFinish={this.onFinish} validateMessages={validateMessages} layout="vertical">
            <Form.Item
              name={['contacto', 'nombre']}
              label="Nombre"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input style={{ width: '100%' }}/>
            </Form.Item>
            <Form.Item
              name={['contacto', 'correo']}
              label="Correo electrónico"
              rules={[
                {
                  type: 'email',
                  required:true,
                },
              ]}
            >
              <Input style={{ width: '100%' }}/>
            </Form.Item>
            <Form.Item wrapperCol={{ 
              xs: { span: 24, offset: 0},
              sm: { span: 16, offset: 8},
            }} >
              <Button type="primary" htmlType="submit">
                Enviar
              </Button>
            </Form.Item>
            <p> * Son requeridos para poder enviar la consulta.</p>            
          </Form>
        </Drawer>
      </div>
    )
  }
}