import React, { Component } from 'react'
import {
  Divider,
  Collapse,
  Button
} from 'antd'

import { DownloadOutlined } from '@ant-design/icons';

import '../../../../estilos-paginas/cursos/cursos.css'
import imagen from '../../../../assets/cursos/gratuitos/2024/curso46/FLYER_BUSQ_INF.png'
import pdfCurso1 from '../../../../assets/cursos/gratuitos/2024/curso46/BROCHURE.pdf'


const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class BusquedaYGestionDeLaLiteraturaCientificaSobreMedicamentos2024 extends Component {
  render() {
    return(
      <>
        <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={imagen}
            style={{minWidth: 280, maxWidth: 350, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>BÚSQUEDA Y GESTIÓN DE LA LITERATURA CIENTÍFICA SOBRE MEDICAMENTOS</h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("TEMARIO")}
              <ul>
                <p>Ponente: Q.F. KARINA GUTIÉRREZ GARCIA</p>
                <p>1. Búsqueda y Gestión de la literatura científica sobre medicamentos en:</p>
                <li>Agencias reguladoras de los países de alta vigilancia.</li>
                <li>Páginas científicas de referencias para sustentar:</li>
                <ul>
                    <li>Seguridad y Eficiencia de un Medicamento</li>
                    <li>Condición de venta (Sin receta médica)</li>
                    <li>Dosis de un Suplemento Nutricional e información técnica referente a advertencias y precauciones</li>
                </ul>
                <li>Páginas científicas de referencias para sustento en la elaboración de Informe Periódico de Seguridad (IPS) y/o Plan de Gestión de Riesgo (PGR).</li>
              </ul>

              <ul style={{ display: 'flex', justifyContent: 'space-between'}}>
                <br/>
                <a href={pdfCurso1} download>
                <Button type="primary" shape="round" icon={<DownloadOutlined/>} size={'large'}>
                  PDF GESTIÓN LITERATURA
                </Button>
                </a>
              </ul>
            </Panel>
            <Panel key="3"
              style={{ backgroundColor:'#1890ff'}}
            >
              <ul>
                <li>Si desea registrar su participación en el webinar: BÚSQUEDA Y GESTIÓN DE LA LITERATURA CIENTÍFICA SOBRE MEDICAMENTOS, agradeceré llenar el siguiente formulario de inscripción: </li>
              </ul>
              <br/> <a href="https://forms.gle/U9PDojAaUqrVmGCn7"rel="noopener noreferrer" target="_blank"><Button type="primary" shape="round" size={'large'}>FORMULARIO DE INSCRIPCIÓN </Button></a>
            </Panel>
            
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}