import React, { Component } from 'react'
import {
  Divider,
  Collapse,Button,
} from 'antd'

import { DownloadOutlined } from '@ant-design/icons';

import '../../../../estilos-paginas/cursos/cursos.css'
import gestionParaLaImplementacionDelSistemaDeFarmacovigilanciaEnUnaDrogueriaYLaboratorioFarmaceutico from '../../../../assets/cursos/gratuitos/quintoCurso/GestionParaLaImplementacionDelSistemaDeFarmacovigilanciaEnUnaDrogueriaYLaboratorioFarmaceutico.png'
import pdfCurso from '../../../../assets/cursos/gratuitos/pdfcursos/GestionParaLaImplementacionDelSistemaDeFarmacovigilanciaEnUnaDrogueriaYLaboratorioFarmaceutico.pdf'

const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class GestionParaLaImplementacionDelSistemaDeFarmacovigilanciaEnUnaDrogueriaYLaboratorioFarmaceutico extends Component {
  render() {
    return(
      <>
         <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={gestionParaLaImplementacionDelSistemaDeFarmacovigilanciaEnUnaDrogueriaYLaboratorioFarmaceutico}
            style={{minWidth: 330, maxWidth: 450, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>GESTIÓN PARA LA IMPLEMENTACIÓN DEL SISTEMA DE FARMACOVIGILANCIA EN UNA DROGUERÍA Y LABORATORIO FARMACÉUTICO</h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("TEMAS A DESARROLLAR:")}
              <ul>
                <li>Conceptos y generalidades de la farmacovigilancia.</li>
                <li>Requisitos para la implementación del Sistema de Farmacovigilancia para una Droguería y Laboratorio Farmacéutico, según lo indicado en la R.M.N° 1053-2020: "MANUAL DE BUENAS PRÁCTICAS DE FARMACOVIGILANCIA"</li>
              </ul>
              <a href={pdfCurso} download>
              <Button type="primary" shape="round" icon={<DownloadOutlined/>} size={'large'}>
                Descargar PDF
               </Button>
              </a>
            </Panel>
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}