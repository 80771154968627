import React, { useReducer } from 'react'
import AuthContext from './authContext'
import AuthReducer from './authReducer'

import clienteAxios from '../../config/axios'
import tokenAuth from '../../config/token';

import {
  REGISTRO_ERROR,
  REGISTRO_EXITOSO,
  OBTENER_USUARIO,
  LOGIN_ERROR,
  LOGIN_EXITOSO,
  // CERRAR_SESION,
} from '../../types/index'

const AuthState = props => {
  const initialState = {
    token: localStorage.getItem('token'),
    autenticado: null,
    usuario: null,
    mensaje: null
  }
  const [ state, dispatch ] = useReducer(AuthReducer, initialState)

  //funciones 

  const registrarUsuario = async datos => {
    try {
      const respuesta = await clienteAxios.post('/api/usuarios', datos);
      console.log(respuesta);
      dispatch({
        type: REGISTRO_EXITOSO,
        payload: respuesta.data
      })
      //Obtener el usuario
      usuarioAutenticado();
    } catch (error) {
      console.log(error);

      dispatch({
        type: REGISTRO_ERROR
      })
    }
  }

  // Retorna el usuario autenticado\
  const usuarioAutenticado = async () => {
    const token = localStorage.getItem('token');
    if(token) {
      // todo: funcion para enviar el token por headers
      tokenAuth(token);
    }
    try {
      const respuesta = await clienteAxios.get('/api/auth');
      console.log(respuesta)
      dispatch({
        type: OBTENER_USUARIO,
        payload: respuesta.data.usuario
      })
    } catch(error) {
      console.log(error.response);
      dispatch({
        type: LOGIN_ERROR
      })
    }
  }

  

  // Cuando usuario inicia sesion
  const iniciarSesion = async datos => {
    try {
      const respuesta = await clienteAxios.post('/api/auth', datos)
      dispatch({
        type: LOGIN_EXITOSO,
        payload: respuesta.data
      })

      usuarioAutenticado();
    } catch (error) {
      console(error.response.data.msg)
      const alerta = {
        msg: error.response.data.msg
      }
      dispatch({
        type: LOGIN_ERROR,
        payload: alerta
      })
    }
  }
  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        autenticado:state.autenticado,
        usuario: state.usuario,
        mensaje: state.mensaje,
        registrarUsuario,
        iniciarSesion
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthState;