import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Result, Button } from 'antd'
import  Inicio  from "./Inicio"
import  SobreNosotros  from "../pages/sobreNosotros"
import { Clientes } from "../pages/clientes"
import { Servicios } from "../pages/servicios"
import RutaCursos  from "../pages/cursos/rutacursos"
import Contacto from "../pages/contacto"
import Admin from '../pages/admin'
import { Layout } from '../componentes/Layout'
import { NavigationBar } from '../componentes/NavigationBar'
import  Footer  from '../componentes/Footer'
import AuthState from '../context/autenticacion/authState'

import whatsapp from '../assets/whatsapp.png'
import ReactGa from 'react-ga'


const NoMatch = ({ location }) => (
  <div>
    <p />
    <Result
      status="404"
      title="404"
      subTitle="Disculpe, la pagina que desea visitar no existe."
      extra={<Button type="primary" href="/">Regresar a Inicio</Button>}
    />
    <p />
  </div>
)

function iniciandoAnalitics() {
  ReactGa.initialize('UA-169439901-1')
  ReactGa.pageview(window.location.pathname 
    + window.location.search)  
}


class App extends Component {
  render() {
    iniciandoAnalitics()
    return (
      <React.Fragment>
        <NavigationBar />
        <div className="main-center-body">
          <AuthState>
            <Router>
              <Route exact path="/" component={Inicio} />
              <Layout >
              <Switch>
                <Route exact path="/" />
                <Route path="/sobre-nosotros" component={SobreNosotros} />
                {/* <Route path="/productos" component={Productos} /> */}
                <Route path="/clientes" component={Clientes} />
                <Route path="/servicios" component={Servicios} />
                <Route path="/cursos" component={RutaCursos} /> 
                <Route path="/contacto" component={Contacto} /> 
                <Route path="/admin" component= {Admin} />
                <Route component= {NoMatch} />
              </Switch>
              </Layout>
            </Router>
          </AuthState>
        </div>
        <div className="pluggin-chat-bot" 
          style={{ position:'fixed', bottom:'10px', right: '10px', zIndex:'999999'}}>
          <a rel="noopener noreferrer" target="_blank"  href="https://web.whatsapp.com/send?phone=51941649600&text=Quiero%20informaci%C3%B3n">
            <img src={whatsapp} alt="whatsappimage"/>
          </a>
        </div>
        <div className="pluggin-chat-bot-responsive" 
          style={{ position:'fixed', bottom:'10px', right: '10px', zIndex:'999999'}}>
          <a rel="noopener noreferrer" target="_blank"  href="https://api.whatsapp.com/send?phone=51941649600&text=Quiero%20informaci%C3%B3n">
            <img src={whatsapp} alt="whatsappimage"/>
          </a>
        </div>
        
        <Footer />
      </React.Fragment>
    );
  }
}

export default App;
