import React, { Component } from 'react'
import {
  Divider,
  Collapse,
  Button
} from 'antd'

import { DownloadOutlined } from '@ant-design/icons';

import '../../../../estilos-paginas/cursos/cursos.css'
import cursoBuenasPracticasDistribucionYTransporte from '../../../../assets/cursos/gratuitos/octavoCurso/CertificacionEnBuenasPracticasDeDistribucionYTransporte3.png'
import pdfCurso from '../../../../assets/cursos/gratuitos/pdfcursos/CertificacionEnBuenasPracticasDeDistribucionYTransporte.pdf'

const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class CertificacionBuenasPracticasDistribucionYTransporte extends Component {
  render() {
    return(
      <>
        <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={cursoBuenasPracticasDistribucionYTransporte}
            style={{minWidth: 280, maxWidth: 350, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>CERTIFICACIÓN EN BUENAS PRACTICAS DE DISTRIBUCION Y TRANSPORTE</h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("TEMARIO")}
              <ul>
                <li>Ámbito de aplicación de la normativa R.M. N° 833-2015 y sus modificatorias.</li>
                <li>Sistema de Aseguramiento de la Calidad.</li>
                <li>Personal.</li>
                <li>Instalaciones, equipos e instrumentos.</li>
                <li>Calificación de Embalaje y Equipos</li>
                <li>Despacho.</li>
                <li>Distribución y transporte.</li>
                <li>Documentación y trazabilidad.</li>
                <li>Autoinspecciones(Acciones correctivas y acciones preventivas).</li>
                <li>Quejas y Reclamos.</li>
                <li>Devoluciones.</li>
                <li>Plan de contigencia.</li>
                <li>Contrato para el servicio de distribución y transporte.</li>
                <li>Observacioens más frecuentes en una Inspección.</li>
              </ul>

              <a href={pdfCurso} download>
              <Button type="primary" shape="round" icon={<DownloadOutlined/>} size={'large'}>
                Descargar PDF
               </Button>
              </a>
            </Panel>
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}