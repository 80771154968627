import React, { Component } from 'react'
import {
  Divider,
  Collapse,
  Button
} from 'antd'

import { DownloadOutlined } from '@ant-design/icons';

import '../../../../estilos-paginas/cursos/cursos.css'
import gestionDeLaInformacionParaLaElaboracionDelDossierDeEspecialidadesFarmaceuticasParaLaInscripcionOReinscripcionEnElRegistroSanitario from '../../../../assets/cursos/gratuitos/decimoSextoCurso/GestionDeLaInformacionParaLaElaboracionDelDossierDeEspecialidadesFarmaceuticasParaLaInscripcionOReinscripcionEnElRegistroSanitario1.png'
import pdfCurso from '../../../../assets/cursos/gratuitos/pdfcursos/GestionDeLaInformacionParaLaElaboracionDelDossierDeEspecialidadesFarmaceuticasParaLaInscripcionOReinscripcionEnElRegistroSanitario.pdf'

const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class GestionDeLaInformacionParaLaElaboracionDelDossierDeEspecialidadesFarmaceuticasParaLaInscripcionOReinscripcionEnElRegistroSanitario extends Component {
  render() {
    return(
      <>
        <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={gestionDeLaInformacionParaLaElaboracionDelDossierDeEspecialidadesFarmaceuticasParaLaInscripcionOReinscripcionEnElRegistroSanitario}
            style={{minWidth: 280, maxWidth: 350, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>GESTIÓN DE LA INFORMACIÓN PARA LA ELABORACIÓN DEL DOSSIER DE ESPECIALIDADES FARMACÉUTICAS PARA LA INSCRIPCIÓN O REINSCRIPCIÓN EN EL REGISTRO SANITARIO</h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("TEMARIO")}
              <ul>
                <li>Conceptos generalidades para el Registro Sanitario:</li>
                  <ul>
                    <li>Registro Sanitario.</li>
                    <li>Ley 29459 y D.S. 016-2011 -SA.</li>
                    <li>Eficacia, Seguridad y Calidad.</li>
                  </ul>
                <li>Requisitos para la Inscripción y Reinscripción de Especialidades Farmacéuticas (categoría 1, 2 y 3).</li>
              </ul>

              <a href={pdfCurso} download>
              <Button type="primary" shape="round" icon={<DownloadOutlined/>} size={'large'}>
                Descargar PDF
               </Button>
              </a>
            </Panel>
            <Panel key="3"
              style={{ backgroundColor:'#1890ff'}}
            >
              <ul>
                <li>Si desea participar en el Webinar: GESTIÓN DE LA INFORMACIÓN PARA LA ELABORACIÓN DEL DOSSIER DE ESPECIALIDADES FARMACÉUTICAS PARA LA INSCRIPCIÓN O REINSCRIPCIÓN EN EL REGISTRO SANITARIO por favor registrar su participación en el siguiente enlace: </li>
              </ul>
              <br/> <a href="https://forms.gle/foMNZh9QitTSyGTS9"rel="noopener noreferrer" target="_blank"><Button type="primary" shape="round" size={'large'}>FORMULARIO DE INSCRIPCIÓN </Button></a>
            </Panel>
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}