import React, { Component } from 'react'
import './Jumbotron.css'

import { Carousel } from 'antd'


export default class Jumbotron extends Component {
  render() {
    return (
      <Carousel autoplay fade="true">
        <div className="imagen1" />
        <div className="imagen2" />
        <div className="imagen3" />
        <div className="imagen4" />
        <div className="imagen5" />
        <div className="imagen6" />
      </Carousel>
    )
  }
}