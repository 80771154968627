import React, { Component } from 'react'
import {
  Divider,
  Collapse,
  Button
} from 'antd'

import { DownloadOutlined } from '@ant-design/icons';

import '../../../../estilos-paginas/cursos/cursos.css'
import requisitosTecnicosParaSolicitarYObtenerLaAutorizacionSanitariaDeUnaDrogueria from '../../../../assets/cursos/gratuitos/decimoCuartoCurso/REQUISITOS-TECNICOS-PARA-SOLICITAR-Y-OBTENER-LA-AUTORIZACION-SANITARIA-DE-UNA-DROGUERIA.png'
import pdfCurso from '../../../../assets/cursos/gratuitos/pdfcursos/cursoRequisitosTecnicosParaSolicitarYObtenerLaAutorizacionSanitariaDeUnaDrogueria.pdf'

const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class RequisitosTecnicosParaSolicitarYObtenerLaAutorizacionSanitariaDeUnaDrogueria extends Component {
  render() {
    return(
      <>
        <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={requisitosTecnicosParaSolicitarYObtenerLaAutorizacionSanitariaDeUnaDrogueria}
            style={{minWidth: 280, maxWidth: 350, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>REQUISITOS TÉCNICOS PARA SOLICITAR Y OBTENER LA AUTORIZACIÓN SANITARIA DE UNA DROGUERÍA</h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("TEMARIO")}
              <ul>
                <li>Normativa y aspectos Generales de la Regulación Sanitaria de Establecimiento Farmacéutico:</li>
                  <ul>
                    <li>Decreto Supremo N° 014-2011-SA y sus modificatorias.</li>
                    <li>Texto Único de Procedimientos Administrativos (TUPA).</li>
                  </ul>
                <li>Autorización Sanitaria de Funcionamiento de “DROGUERIAS”.</li>
                <li>Consideraciones a tener presente en el llenado de la Solicitud con carácter de Declaración Jurada (Formato A-2 ).</li>
                <li>Actividades a solicitar en una apertura.</li>
                <li>Horario del establecimiento farmacéutico y del Director Técnico.</li>
                <li>Requerimientos que debe de consignar el Plano de la Droguería.</li>
                <li>Asumes y renuncias de los Directores Técnicos y asistentes de las “DROGUERIAS”.</li>
                <li>Cierre temporal o definitivo y cambios de la información Declarada de las DROGUERIAS.</li>
                <li>Información necesaria que debe tener el contrato de servicio de almacenamiento y/o distribución, cuando corresponda.</li>
                <li>Trámite virtual y programación para la inspección.</li>
              </ul>

              <a href={pdfCurso} download>
              <Button type="primary" shape="round" icon={<DownloadOutlined/>} size={'large'}>
                Descargar PDF
               </Button>
              </a>
            </Panel>
            <Panel key="3"
              style={{ backgroundColor:'#1890ff'}}
            >
              <ul>
                <li>Si desea registrar su participación en el webinar: REQUISITOS TÉCNICOS PARA SOLICITAR Y OBTENER LA AUTORIZACIÓN SANITARIA DE UNA DROGUERÍA agradeceré llenar el siguiente formulario de inscripción: </li>
              </ul>
              <br/> <a href="https://forms.gle/wTm2Nd3PwqyQaErV9"rel="noopener noreferrer" target="_blank"><Button type="primary" shape="round" size={'large'}>FORMULARIO DE INSCRIPCIÓN </Button></a>
            </Panel>
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}