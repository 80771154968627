import React, { Component } from 'react'
import {
  Divider,
  Collapse,Button,
} from 'antd'

import { DownloadOutlined } from '@ant-design/icons';

import '../../../../estilos-paginas/cursos/cursos.css'
import certificacionEnBuenasPracticasDeAlmacenamiento from '../../../../assets/cursos/gratuitos/septimoCurso/CertificacionEnBuenasPracticasDeAlmacenamiento3.png'
import pdfCurso from '../../../../assets/cursos/gratuitos/pdfcursos/cursoCertificacionEnBuenasPracticasDeAlmacenamiento.pdf'

const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class CertificacionEnBuenasPracticasDeAlmacenamiento extends Component {
  render() {
    return(
      <>
         <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={certificacionEnBuenasPracticasDeAlmacenamiento}
            style={{minWidth: 330, maxWidth: 450, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>CERTIFICACIÓN EN BUENAS PRÁCTICAS DE ALMACENAMIENTO</h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("TEMAS A DESARROLLAR:")}
              <ul>
                <li>Ámbito de aplicación de la normativa R.M. N° 132-2015 y sus modificatorias.</li>
                <li>Sistema de Aseguramiento de la calidad.</li>
                <li>Personal.</li>
                <li>Instalaciones y equipos e Instrumentos.</li>
                <li>Almacén (Mapeo Térmico).</li>
                <li>Documentación.</li>
                <li>Reclamos.</li>
                <li>Retiro de Mercado.</li>
                <li>Autoinspecciones (Acciones correctivas y acciones preventivas).</li>
                <li>Contrato para el servicio Almacenamiento.</li>
                <li>Plan de contingencia.</li>
                <li>Observaciones más frecuentes en una Inspección de Buenas Prácticas de Almacenamiento.</li>
              </ul>
              <a href={pdfCurso} download>
              <Button type="primary" shape="round" icon={<DownloadOutlined/>} size={'large'}>
                Descargar PDF
               </Button>
              </a>
            </Panel>
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}