import React, { Component } from 'react'
import {
  Divider,
  Collapse,
  Button
} from 'antd'

import { DownloadOutlined } from '@ant-design/icons';

import '../../../../estilos-paginas/cursos/cursos.css'
import imagen from '../../../../assets/cursos/gratuitos/curso43/FLYER.png'
import pdfCurso1 from '../../../../assets/cursos/gratuitos/curso43/BROCHURE_NSOC.pdf'
import pdfCurso2 from '../../../../assets/cursos/gratuitos/curso43/BROCHURE_NSOHD.pdf'

const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class GestionDeLaInformacionParaLaObtencionDeLaNotificacionSanitariaObligatoriaDeProductosCosmeticosHigieneDomesticaYAbsorbentesDeHigieneJunio extends Component {
  render() {
    return(
      <>
        <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={imagen}
            style={{minWidth: 280, maxWidth: 350, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>GESTIÓN DE LA INFORMACIÓN PARA LA OBTENCIÓN DE: LA NOTIFICACIÓN SANITARIA OBLIGATORIA DE PRODUCTOS COSMÉTICOS - PRODUCTOS DE HIGIENE DOMÉSTICA Y PRODUCTOS ABSORBENTES DE HIGIENE - REGISTRO SANITARIO DE PRODUCTOS SANITARIOS ARTÍCULOS PARA BEBÉS</h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("TEMARIO")}
              <ul>
                <p>Ponente: Q.F. Ninoska Vizcardo Villalba</p>
                <p>DIA 01 (04 de Junio – Hora: 7:30 pm – 10:00 pm) PRODUCTOS COSMÉTICOS:</p>
                <li>CAN: ORGANISMOS REGULADORES.</li>
                <li>DECISION 833 -DEFINICIONES, NOTIFICACION SANITARIA, GRUPO COSMÉTICO, RENOVACION, RECONOCIMIENTO, ACOGIMIENTO, COMUNICACIÓN DE CAMBIOS.</li>
                <li>DECISION 705 – CIRCULACIÓN DE MUESTRAS SIN VALOR COMERCIAL </li>
                <li>DECISION 783 - AGOTAMIENTO DE EXISTENCIAS EN PRODUCTOS SANITARIOS REGULADOS POR LA CAN.</li>
                <li>RESOLUCIÓN N° 2310 - REGLAMENTO TÉCNICO ANDINO PARA EL ETIQUETADO DE PRODUCTOS COSMÉTICOS</li>
                <br/>
                <th/>
              </ul>

              <ul>
                <p>DIA 02 (04 de Junio – Hora: 7:30 pm – 10:00 pm)PRODUCTOS DE HIGIENE DOMÉSTICA Y PRODUCTOS ABSORBENTES DE HIGIENE PERSONAL:</p>
                <li>NORMATIVA VIGENTE: NACIONAL Y SUPRANACIONAL.</li>
                <li>CAN: ORGANISMOS REGULADORES</li>
                <li>DECISION 706 -DEFINICIONES, NOTIFICACION SANITARIA PHD Y PADHP, PROYECTO DE ROTULADO, GRUPO DE PRODUCTOS, RENOVACION, RECONOCIMIENTO, 2DO IMPORTADOR, COMUNICACIÓN DE CAMBIOS, CIRCULACION DE MUESTRAS.</li>
                <li>RESOLUCION 1370.</li>
                <li>DECISION 783 - AGOTAMIENTO DE EXISTENCIAS EN PRODUCTOS SANITARIOS REGULADOS POR LA CAN .</li>
              </ul>

              <ul>
              <p>DIA 02 (04 de Junio – Hora: 7:30 pm – 10:00 pm)REGISTRO SANITARIO DE PRODUCTOS PARA BEBE:</p>
                <li>NORMATIVA NACIONAL</li>
                <li>DEFINICIONES</li>
                <li>RS Y CIRCULACION DE ARTICULOS PARA BEBÉS.</li>
                <li>CLASIFICACION</li>
                <li>CALIDAD Y SEGURIDAD</li>
                <li>REQUISITOS PARA LA INSCRIPCION AL RS</li>
                <br/>
                <th/>
              </ul>

              <ul style={{ display: 'flex', justifyContent: 'space-between'}}>
                <br/>
                <a href={pdfCurso1} download>
                <Button type="primary" shape="round" icon={<DownloadOutlined/>} size={'large'}>
                  PDF PRODUCTOS COSMÉTICOS
                </Button>
                </a>
                <br/>
                <a href={pdfCurso2} download>
                <Button type="primary" shape="round" icon={<DownloadOutlined/>} size={'large'}>
                  PDF PRODUCTOS DE HIGIENE DOMÉSTICA Y PRODUCTOS ABSORBENTES
                </Button>
                </a>
              </ul>
            </Panel>
            <Panel key="3"
              style={{ backgroundColor:'#1890ff'}}
            >
              <ul>
                <li>Si desea registrar su participación en el webinar: GESTIÓN PARA LA IMPLEMENTACIÓN DEL SISTEMA DE FARMACOVIGILANCIA EN UNA DROGUERÍA Y LABORATORIO FARMACÉUTICO, agradeceré llenar el siguiente formulario de inscripción: </li>
              </ul>
              <br/> <a href="https://forms.gle/CdLaTCZgCJLYC2qh7"rel="noopener noreferrer" target="_blank"><Button type="primary" shape="round" size={'large'}>FORMULARIO DE INSCRIPCIÓN </Button></a>
            </Panel>
            
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}