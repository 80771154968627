import React, { Component } from 'react'
import {
  Divider,
  Collapse
} from 'antd'

import '../../../../estilos-paginas/cursos/cursos.css'
import cursoAutorizacionesSanitariasDeEstablecimientosFarmaceuticos1 from '../../../../assets/cursos/inhouse-home.jpg'

const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class AutorizacionesSanitariasDeEstablecimientosFarmaceuticos extends Component {
  render() {
    return(
      <>
        <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={cursoAutorizacionesSanitariasDeEstablecimientosFarmaceuticos1}
            style={{minWidth: 280, maxWidth: 350, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>AUTORIZACIONES SANITARIAS DE ESTABLECIMIENTOS FARMACÉUTICOS: DROGUERIAS Y ALMACENES ESPECIALIZADOS </h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("TEMARIO")}
              <ul>
                <li>Normatividad y Aspectos Generales de la Regulación Sanitarias de Establecimiento Farmacéuticos “DROGUERIAS Y ALMACENES ESPECIALIZADOS”</li>
                <li>Autorización Sanitaria de Funcionamiento, ampliación, traslado de DROGUERIAS Y ALMACENES ESPECIALIZADOS</li>
              </ul>
              <hr />
              <ul>
                <li>Directores Técnicos y jefaturas de las DROGUERIAS Y ALMACENES ESPECIALIZADOS.</li>
                <li>Cierre temporal o definitivo, encargo de servicios y cambios de la información declarada de las DROGUERIAS Y ALMACENES ESPECIALIZADOS.</li>
                <li>Taller: Casos prácticos.</li>
              </ul>
            </Panel>
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}