import React, { Component } from 'react'
import {
  Divider,
  Collapse,
  Button
} from 'antd'

import { DownloadOutlined } from '@ant-design/icons';

import '../../../../estilos-paginas/cursos/cursos.css'
import imagen from '../../../../assets/cursos/gratuitos/curso36/FLYER.png'
import pdfCurso from '../../../../assets/cursos/gratuitos/pdfcursos/NRIMP2023.pdf'

const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class NormativaRegulaIntercambiabilidadDeMedicamentosEnPeru extends Component {
  render() {
    return(
      <>
        <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={imagen}
            style={{minWidth: 280, maxWidth: 350, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>NORMATIVA QUE REGULA LA INTERCAMBIABILIDAD DE MEDICAMENTOS EN EL PERU</h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("TEMARIO")}
              <ul>
                <li>GENERALIDADES SOBRE LA INTERCAMBIABILIDAD DE MEDICAMENTOS.</li>
                <li>REGLAMENTO QUE REGULA LA INTERCAMBIABILIDAD DE MEDICAMENTOS: DECRETO SUPREMO N° 024-2018-SA, R.M. N° 366-2019/MINSA, R.M. N° 404-2021/MINSA, R.M. N° 195-2022/MINSA.</li>
                <li>MARCO CONCEPTUAL - CONTEXTO PERUANO.</li>
                <li>ESTUDIOS DE EQUIVALENCIA TERAPÉUTICA IN VIVO: ESTUDIOS DE BIOEQUIVALENCIA.</li>
                <li>ESTUDIOS DE EQUIVALENCIA TERAPÉUTICA IN VITRO:</li>
                <ul>
                    <li>BIOEXENCIONES BASADAS EN SCB</li>
                    <li>BIOEXENCIONES BASADAS EN LA PROPORCIONALIDAD DE DOSIS</li>
                </ul>
                <li>MEDICAMENTOS QUE NO REQUIEREN ESTUDIOS DE EQUIVALENCIA TERAPÉUTICA BASADOS EN LA FORMA FARMACÉUTICA</li>
              </ul>

              <a href={pdfCurso} download>
              <Button type="primary" shape="round" icon={<DownloadOutlined/>} size={'large'}>
                Descargar PDF
               </Button>
              </a>
            </Panel>
            <Panel key="3"
              style={{ backgroundColor:'#1890ff'}}
            >
              <ul>
                <li>Si desea registrar su participación en el webinar: NORMATIVA QUE REGULA LA INTERCAMBIABILIDAD DE MEDICAMENTOS EN EL PERU, agradeceré llenar el siguiente formulario de inscripción: </li>
              </ul>
              <br/> <a href="https://forms.gle/3yv1R2NwKcdw979o6"rel="noopener noreferrer" target="_blank"><Button type="primary" shape="round" size={'large'}>FORMULARIO DE INSCRIPCIÓN </Button></a>
            </Panel>
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}