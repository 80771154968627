import React, { Component } from 'react'
import {
  Divider,
  Collapse
} from 'antd'

import '../../../../estilos-paginas/cursos/cursos.css'
import cursoBuenasPracticasDistribucion from '../../../../assets/cursos/inHouse-Libres/cursoBuenasPracticasDistribucion.jpg'

const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}

export default class BuenasPracticasDeDistribucionYTransporte extends Component {
  render() {
    return(
      <>
        <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={cursoBuenasPracticasDistribucion}
            style={{minWidth: 280, maxWidth: 350, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>“BUENAS PRACTICAS DE DISTRIBUCIÓN Y TRANSPORTE” (Según los lineamientos detallados en el Manual y Guía de Inspección emitida por DIGEMID)</h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              <ul>
                <li>Ámbito de aplicación de la normativa R.M. N° 833-2015 y sus modificatorias.</li>
                <li>Finalidad, objetivo, base legal, ámbito de aplicación.</li>
                <li>Sistema de Aseguramiento de la calidad.</li>
                <li>Personal.</li>
                <li>Instalaciones y equipos</li>
              </ul>
              <hr />
              <ul>
                <li>Embalaje y despacho.</li>
                <li>Distribución y transporte.</li>
                <li>Documentación y trazabilidad.</li>
                <li>Quejas y Reclamos.</li>
                <li>Devoluciones.</li>
              </ul>
              <hr />
              <ul>
                <li>Contrato para el servicio de distribución y transporte.</li>
                <li>Desarrollo de la Guía de Inspección.</li>
                <li>Preguntas de casos prácticos.</li>
              </ul>
            </Panel>
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}