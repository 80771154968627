import React, { Component } from 'react'
import {
  Divider,
  Collapse,
  Button
} from 'antd'

import { DownloadOutlined } from '@ant-design/icons';

import '../../../../estilos-paginas/cursos/cursos.css'
import imagen from '../../../../assets/cursos/gratuitos/curso24/CURSO_GESTION_DE_LAS_SOSPECHAS.png'
import pdfCurso from '../../../../assets/cursos/gratuitos/pdfcursos/CURSO_GESTION_DE_LAS_SOSPECHAS_2022.pdf'

const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class GestionDeLasSospechasDeReaccionesAdversasALosMedicamentos extends Component {
  render() {
    return(
      <>
        <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={imagen}
            style={{minWidth: 280, maxWidth: 350, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>GESTIÓN DE LAS SOSPECHAS DE REACCIONES ADVERSAS A LOS MEDICAMENTOS</h3>
              <h4 style={{ color: '0000'}}>(Según los lineamientos detallados en el Manual y Guía de Inspección emitida por DIGEMID)</h4>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("TEMARIO")}
              <ul>
                <li>Conceptos y generalidades de las Sospecha de Reacción Adversa a los Medicamentos.</li>
                <ul>
                  <li> Reacción Adversa a un medicamento (RAM)</li>
                  <li> Identificar RAMs</li>
                </ul>
                <li>Recopilación de las Sospechas de Reacciones Adversas (SRA).</li>
                <li>Mecanismos necesarios para la gestión de las SRA.</li>
                <li>Puntos a considerar en la evaluación de la causalidad de las SRA.</li>
                <li>Notificación de SRA al Centro Nacional de Farmacovigilancia y Tecnovigilancia.</li>
                <ul>
                  <li> Datos a incluir en la Notificación de SRA.</li>
                  <li> Plazo para el envío de las SRA.</li>
                </ul>
                <li>Registro de las Notificaciones de SRA.</li>
                <li>Seguimiento y cierre de las Notificaciones de SRA.</li>
              </ul>

              <a href={pdfCurso} download>
              <Button type="primary" shape="round" icon={<DownloadOutlined/>} size={'large'}>
                Descargar PDF
               </Button>
              </a>
            </Panel>
            <Panel key="3"
              style={{ backgroundColor:'#1890ff'}}
            >
              <ul>
                <li>Si desea registrar su participación en el webinar:GESTIÓN DE LAS SOSPECHAS DE REACCIONES ADVERSAS A LOS MEDICAMENTOS, agradeceré llenar el siguiente formulario de inscripción: </li>
              </ul>
              <br/> <a href="https://forms.gle/PKqPNXJHV6gUmrXZA"rel="noopener noreferrer" target="_blank"><Button type="primary" shape="round" size={'large'}>FORMULARIO DE INSCRIPCIÓN </Button></a>
            </Panel>
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}