import axios from 'axios'

import { formatoParams } from '../utilidades'

const obtenerUrl = (endpoint) => {
  // const baseURLDev = 'http://localhost:8080/api/contacto'
  // const baseURLProd = 'https://sgafarma.com/api/contacto'
  const urlBase = 'https://sgafarma.com'
  return `${urlBase}${endpoint}`
}

export const get = async (endpoint, params={}) => {
  const url = obtenerUrl(endpoint)
  const parametros = formatoParams(params)
  return (
    axios
    .get(url, {
      params: parametros,
    })
    .then(response => {
      return response.data
    })
    .catch(error => {
      if (error && error.response) {
        throw(error.response.data)
      } else if (error && error.data) {
        throw(error.data)
      }      
    })
  )
}


export const post = async (endpoint, data=null, base='') => {
  const url = obtenerUrl(endpoint, base)
  return (
    axios
    .post(url,data)
    .then(response => {
      return response.data
    })
    .catch(error => {
      if(error && error.response) {
        throw(error.response.data)
      }
    })
  )
}


export const put = async (endpoint, data={}, base='') => {
  const url = obtenerUrl(endpoint, base)
  return (
    axios
    .put(url, data)
    .then(response => {
      return response.data
    })
    .catch(error => {
      if (error && error.response) {
        throw(error.response.data)
      } else if (error && error.data) {
        throw(error.data)
      }
    })
  )
}

export const remove = async (endpoint, base='') => {
  const url = obtenerUrl(endpoint, base)
  return(
    axios
    .delete(url)
    .then(response => {
      return response.data
    })
    .catch(error => {
      if (error && error.response) {
        throw(error.response.data)
      }
    })
  )
}