import React, { Component } from 'react'
import {
  Divider,
  Collapse,
  Button
} from 'antd'

import { DownloadOutlined } from '@ant-design/icons';

import '../../../../estilos-paginas/cursos/cursos.css'
import imagen from '../../../../assets/cursos/gratuitos/curso39/brochure.png'
import pdfCurso from '../../../../assets/cursos/gratuitos/pdfcursos/MAPEO_TERMICO_DE_ALMACENES_2023.pdf'

const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class CriteriosTecnicosParaRealizarElMapeoTermicoDeAlmacenesYCalificacionDeVehiculosDeTransporteYCamaraFriaEnUnaDrogueriaOAlmacenEspecializado extends Component {
  render() {
    return(
      <>
        <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={imagen}
            style={{minWidth: 280, maxWidth: 350, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>CRITERIOS TÉCNICOS PARA REALIZAR EL MAPEO TERMICO DE ALMACENES y CALIFICACION DE VEHICULOS DE TRANSPORTE Y CAMARA FRIA EN UNA DROGUERIA O ALMACEN ESPECIALIZADO</h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("TEMARIO")}
              <ul>
                <p>Ponente: Q.F. Wilbert Albert Rodriguez Ramos</p>
                <p>13 de Diciembre (Hora: 7:00 pm a 9:30 pm) CRITERIOS TÉCNICOS PARA REALIZAR EL MAPEO TERMICO DE ALMACENES y CALIFICACION DE VEHICULOS DE TRANSPORTE Y CAMARA FRIA EN UNA DROGUERIA O ALMACEN ESPECIALIZADO:</p>
                <li>MAPEO TÉRMICO</li>
                    <ul>
                        <li>Mapeo Térmico como requisito del Documento Técnico: Manual de Buenas Prácticas de Almacenamiento (R.M. 132-2015/ MINSA).</li>                        
                        <li>Preparación del almacén en diversas temperaturas. - Infraestructura y Distribución interna.</li>
                        <li>Dispositivos de Control de Temperatura y Humedad Relativa. - Unidad de Climatización.</li>                        
                        <li>Perfil Térmico de Almacenes. - Carga y Estaciones.</li>
                        <li>Puntos Críticos de Temperatura y % HR. – Ubicación y Monitoreo.</li>                        
                        <li>Dispositivos de Monitoreo y Calibración trazable.</li>
                        
                    </ul>
                <br/>
                <th/>
                <li>CALIFICACION DE VEHICULO Y CAMARA FRIA</li>
                    <ul>
                        <li>Referencias internacionales y nacionales.</li>
                        <li>Conceptos generales.</li>
                        <li>Etapas de la calificación.</li>
                        <li>Dispositivos de Control y Monitoreo con Calibración Trazable.</li>
                        <li>Calificación de Diseño.</li>
                        <li>Calificación de Instalación.</li>
                        <li>Calificación de Operación.</li>
                        <li>Calificación de Desempeño.</li>
                    </ul>
              </ul> 
              <a href={pdfCurso} download>
              <Button type="primary" shape="round" icon={<DownloadOutlined/>} size={'large'}>
                Descargar PDF
               </Button>
              </a>
            </Panel>
            <Panel key="3"
              style={{ backgroundColor:'#1890ff'}}
            >
              <ul>
                <li>Si desea registrar su participación en el webinar: CRITERIOS TÉCNICOS PARA REALIZAR EL MAPEO TERMICO DE ALMACENES y CALIFICACION DE VEHICULOS DE TRANSPORTE Y CAMARA FRIA EN UNA DROGUERIA O ALMACEN ESPECIALIZADO, agradeceré llenar el siguiente formulario de inscripción: </li>
              </ul>
              <br/> <a href="https://forms.gle/FvfJsJiZZRAHAh9v8"rel="noopener noreferrer" target="_blank"><Button type="primary" shape="round" size={'large'}>FORMULARIO DE INSCRIPCIÓN </Button></a>
            </Panel>
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}