import React, { Component } from 'react'
import {
  Divider,
  Collapse,
  Button
} from 'antd'

import { DownloadOutlined } from '@ant-design/icons';

import '../../../../estilos-paginas/cursos/cursos.css'
import imagen from '../../../../assets/cursos/gratuitos/2024/curso45/FLYER_.png'
import pdfCurso1 from '../../../../assets/cursos/gratuitos/2024/curso45/BROCHURE.pdf'


const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class ProcedimientoAdministrativoGeneralEnEstablecimientosFarmaceuticos2024 extends Component {
  render() {
    return(
      <>
        <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={imagen}
            style={{minWidth: 280, maxWidth: 350, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>PROCEDIMIENTO ADMINISTRATIVO GENERAL EN ESTABLECIMIENTOS FARMACÉUTICO</h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("TEMARIO")}
              <ul>
                <p>Ponente: Q.F. ABOG. JUDITH GONZALEZ MORENO</p>
                <p>1. Aspectos Generales de la Ley del Procedimiento Administrativo General N°27444 y Modificaciones.</p>
                <li>Principios del Procedimiento Administrativo y nuevos principios</li>
                <li>El Acto Administrativo.</li>
                <ul>
                    <li>Nulidad de los actos administrativos</li>
                    <li>Eficacia de los actos administrativos</li>
                    <li>Revisión de los actos en la vía administrativa</li>
                </ul>
                <li>El procedimiento administrativo.</li>
                <ul>
                    <li>Silencio administrativo</li>
                    <li>Iniciación del Procedimiento</li>
                    <li>Plazos y Términos</li>
                </ul>
                <li>Procedimiento administrativo Sancionador.</li>
                <li>Principios y aplicación del PAS en defensa del administrado.</li>
                <li>Fiscalización administrativa derechos y obligaciones del administrado.</li>
                <li>Simplificación administrativa.</li>
                <li>Texto Único de Procedimiento Administrativo TUPA.</li>
                <li>Caducidad y Prescripción de sanciones Administrativa en favor del administrado..</li>
                <br/>
                <p>2. Consideraciones para tener presente ante una inspección, pesquisa y/o verificación en los establecimientos farmacéuticos de salud.</p>
                <br/>
                <p>3. Aplicación de casos.</p>
                <th/>
              </ul>

              <ul style={{ display: 'flex', justifyContent: 'space-between'}}>
                <br/>
                <a href={pdfCurso1} download>
                <Button type="primary" shape="round" icon={<DownloadOutlined/>} size={'large'}>
                  PDF PROCEDIMIENTO ADMINISTRATIVO
                </Button>
                </a>
              </ul>
            </Panel>
            <Panel key="3"
              style={{ backgroundColor:'#1890ff'}}
            >
              <ul>
                <li>Si desea registrar su participación en el webinar: PROCEDIMIENTO ADMINISTRATIVO GENERAL EN ESTABLECIMIENTOS FARMACÉUTICO, agradeceré llenar el siguiente formulario de inscripción: </li>
              </ul>
              <br/> <a href="https://forms.gle/3rrfecddRJtahSFaA"rel="noopener noreferrer" target="_blank"><Button type="primary" shape="round" size={'large'}>FORMULARIO DE INSCRIPCIÓN </Button></a>
            </Panel>
            
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}