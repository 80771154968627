import React from 'react'
import { Card, Col, Row, Divider } from 'antd'

import Titulo from '../../componentes/Titulo'

import "../../estilos-paginas/servicios/servicios.css"
import servicioRegulatorio from  "../../assets/servicios/servicioRegulatorio.jpg"
import autorizacionSanitaria from '../../assets/servicios/autorizacionSanitaria.jpg'
import buenasPracticas from "../../assets/servicios/servicios-certificacion.jpg"
import servicios3rmb from '../../assets/servicios/servicios3rmb.png'
import serviciosclinica from '../../assets/servicios/clinica.jpg'

export const Servicios = () => (
  <>
    <p />
    <Titulo nombre="Servicios"/>
      <Divider />
      <div className="main-servicios">
        <p>Asesoramiento, consultorías y capacitación (in/out house) en temas relacionados al sector farmacéutico: </p>

        <div className="espacio-servicios" />
        <div className="site-card-wrapper">
          <Card 
          >
          <Row gutter={24} type="flex">
            <Col span={8} xs={24} sm={24} md={24} lg={8} xl={8}>
              <Card
                bordered={false}
                cover={<img alt="Autorización Sanitaria de Establecimiento Farmacéutico" src={autorizacionSanitaria} style={{ 
                  width:'100%', height:'300px', border: '1px solid #ddd', borderRadius: '4px'
                }}/>}>
                <h4>Autorización Sanitaria de Establecimiento Farmacéutico</h4>
                <ul>
                  <li className="li-servicios">Funcionamiento.</li>
                  <li className="li-servicios">Traslado.</li>
                  <li className="li-servicios">Ampliaciones.</li>
                </ul>
              </Card>
            </Col>
            <Col span={8} xs={24} sm={24} md={24} lg={8} xl={8}>
              <Card
                bordered={false}
                cover={<img alt="Buenas Prácticas" src={buenasPracticas} style={{ 
                  width:'100%', height:'300px', border: '1px solid #ddd', borderRadius: '4px'
                }}/>}
              >
                <h4>Buenas Prácticas</h4>
                <ul>
                  <li className="li-servicios"> Almacenamiento.</li>
                  <li className="li-servicios">Distribución y transporte.</li>
                  <li className="li-servicios">Farmacovigilancia.</li>
                  <li className="li-servicios">Manufactura.</li>
                  <li className="li-servicios">Laboratorio.</li>
                </ul>
              </Card>
            </Col>
            <Col span={8} xs={24} sm={24} md={24} lg={8} xl={8}>
              <Card
                bordered={false}
                cover={<img alt="Regulatorio" src={servicioRegulatorio} style={{ 
                  width:'100%', height:'300px', border: '1px solid #ddd', borderRadius: '4px'
                }} />}
              >
                <h4>Regulatorio</h4>
                <ul>
                  <li className="li-servicios">Inscripción, reinscripción de productos farmacéuticos, dispositivos médicos y productos sanitarios.</li>
                  <li className="li-servicios">Certificado de registro sanitario.</li>
                  <li className="li-servicios">Balance de psicotrópicos, previsiones, consumo anual, certificado de importación.</li>
                </ul>
              </Card>
            </Col>
            </Row>
            <Row gutter={24} type="flex">
              <Col span={8} xs={24} sm={24} md={24} lg={8} xl={8}>
                <Card
                  bordered={false}
                  cover={<img alt="Clínica" src={serviciosclinica} style={{ 
                    width:'100%', height:'300px', border: '1px solid #ddd', borderRadius: '4px'
                  }}/>}
                >
                  <h4>Clínica</h4>
                  <ul>
                    <li className="li-servicios">Elaboración de protocolos de farmacovigilancia activa, según los lineamientos de la autoridad.</li>
                    <li className="li-servicios">Implementación del sistema de farmacovigilancia para laboratorios, droguerías y almacenes especializados.</li>
                    <li className="li-servicios">Desarrollo de ensayos clínicos en alianza estratégica con centros de investigación y médicos investigadores del mas alto nivel.</li>
                  </ul>
                </Card>
              </Col>
              <Col span={8} xs={24} sm={24} md={24} lg={8} xl={8}>
                <Card
                  bordered={false}
                  cover={<img alt="Gestión Comercial" src={servicios3rmb} style={{ 
                    width:'100%', height:'300px', border: '1px solid #ddd', borderRadius: '4px'
                  }}/>}
                >
                  <h4>Gestión Comercial</h4>
                  <ul>
                    <li className="li-servicios">Desarrollo e implementación de su plan estratégico del negocio.</li>
                    <li className="li-servicios">Mercadotecnia.</li>
                    <li className="li-servicios">Holder "Titular de Registro Sanitario".</li>
                  </ul>
                </Card>
              </Col> 
            </Row>
          </Card>
      </div>
      <div className="espacio-servicios" />
      <div className="espacio-servicios" />
      </div>
  </>
)