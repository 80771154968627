import React, { Component } from 'react'

import ponente1 from '../../assets/sobreNosotros/ponente1.png'
import ponente2 from '../../assets/sobreNosotros/ponente2.png'

import Titulo from '../../componentes/Titulo'
import {
  Divider,
  Row,
  Col,
  Card,
  Collapse
} from 'antd'

const { Panel } = Collapse


export default class sobreNosotros extends Component {
  render(){
    function callback(key) {
    }      
  return (
  <>
    <p />
    <Titulo nombre="SGA Farma"/>
    <Divider />
    <Row gutter={[16,16]} type="flex" style={{padding: 0}}>
      <Col span={12} xs={24} sm={24} md={24} lg={24}  xl={12}>
        <Card cover={<img alt="ponente1" src={ponente2} />} bordered={false}/>
        <Card cover={<img alt="ponente2" src={ponente1} />} bordered={false}/>
      </Col>
      <Col span={12} xs={24} sm={24} md={24} lg={24}  xl={12}>
        <Collapse defaultActiveKey={['1']} onChange={callback} style={{padding:0, backgroundColor:"#007bff" ,fontSize:'1.1rem'}} accordion>
          <Panel header="¿Quiénes Somos?" key="1">
            <p>Somos una empresa formada por profesionales Farmacéuticos altamente competentes, dedicados al Asesoramiento,  consultoría y capacitación en temas relacionados al sector farmacéutico:</p>
            <ul>
              <li>Autorizaciones Sanitarias y Certificaciones de establecimientos farmacéuticos.</li>
              <li>Temas regulatorios en general relacionados a la comercialización de Productos Farmacéuticos, Dispositivos Médicos y Productos Sanitarios.</li>
              <li>Elaboración de Dossier Técnico para la Inscripción y Reinscripción en el Registro Sanitario de Productos Farmacéuticos, Dispositivos Médicos y Productos Sanitarios nacional e importados ante la DIGEMID.</li>
            </ul>
            <p>
              Asimismo, contamos con una Droguería con Certificación en Buenas Practicas de Almacenamiento y Buenas Practicas de Transporte otorgado por la Dirección General de Medicamentos Insumos y Drogas – DIGEMID, con actividades para la comercialización en los rubros de Productos Farmacéuticos, Dispositivos Médicos y Productos Sanitarios para Productos.
            </p>
          </Panel>
          <Panel header="Nuestra Fortaleza" key="2">
            <p>LOGRAR TU ÉXITO</p>
          </Panel>
          <Panel header="Misión" key="3">
            <p>Proporcionar a nuestros clientes, productos y servicios de calidad en el sector farmacéutico, garantizando el éxito en sus unidades de negocio, bajo cumplimiento regulatorio nacional e internacional y con excelencia en el servicio</p>
          </Panel>
          <Panel header="Visión" key="4">
            <p>Ser los Líderes en el servicio de Asesoramiento,  consultoría y capacitación en temas relacionados al sector farmacéutico y comercialización de productos de calidad a nivel Nacional e Internacional.</p>
          </Panel>
          <Panel header="¿Por qué SGA FARMA?" key="5">
            <ul>
              <li>Personal altamente capacitado y calificado.</li>
              <li>Temas de capación de interés, aplicado al entorno laboral.</li>
              <li>Actuamos bajo principios éticos y profesionales</li>
            </ul>
          </Panel>
        </Collapse>
      </Col>
    </Row>
  </>
)
  }
}