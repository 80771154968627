import React, { Component } from 'react'
import {
  Divider,
  Collapse
} from 'antd'

import '../../../../estilos-paginas/cursos/cursos.css'
import cursoQueDocumentosSonNecesariosParaLaElaboracionDeUnDossierParaLaInscripcionOReinscripcionDeUnaEspecialidadFarmaceutica from '../../../../assets/cursos/gratuitos/QUE DOCUMENTOS SON NECESARIOS  PARA LA ELABORACION DE UN DOSSIER PARA LA  INSCRIPCION O REINSCRIPCION DE UNA ESPECIALIDAD FARMACEUTICA1.jpg'

const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class QueDocumentosSonNecesariosParaLaElaboracionDeUnDossierParaLaInscripcionOReinscripcionDeUnaEspecialidadFarmaceutica extends Component {
  render() {
    return(
      <>
        <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={cursoQueDocumentosSonNecesariosParaLaElaboracionDeUnDossierParaLaInscripcionOReinscripcionDeUnaEspecialidadFarmaceutica}
            style={{minWidth: 325, maxWidth: 450, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>¿QUE DOCUMENTOS SON IMPORTANTES PARA LA ELABORACIÓN DE UN DOSSIER PARA LA INSCRIPCIÓN O REINSCRIPCIÓN DE UNA ESPECIALIDAD FARMACÉUTICA? </h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("TEMARIO")}
              <ul>
                <li>1. Conceptos generalidades para el Registro Sanitario.</li>
                <li>Registro Sanitario.</li>
                <li>Ley 29459 y D.S. 016-2011 -SA.</li>
                <li>Eficacia, Seguridad y Calidad .</li>
              </ul>
              <hr />
              <ul>
                <li>2. Requisitos para la Inscripción y Reinscripción de Especialidades Farmacéuticas (categoría 1, 2 y 3)</li>
              </ul>
            </Panel>
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}