import React, { Component } from 'react'
import {
  Divider,
  Collapse
} from 'antd'


import '../../../../estilos-paginas/cursos/cursos.css'
import comoaperturarunadrogueria from '../../../../assets/cursos/gratuitos/comoaperturarunadrogueria.jpg'

const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class Comoaperturarunadrogueria extends Component {
  render() {
    return(
      <>
         <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={comoaperturarunadrogueria}
            style={{minWidth: 330, maxWidth: 450, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>¿CÓMO APERTURAR UNA DROGUERIA?</h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("TEMAS A DESARROLLAR:")}
              <ul>
                <li>Normatividad y Aspectos Generales de la Regulación Sanitaria de Establecimiento Farmacéuticos.</li>
                <li>Autorización Sanitaria de Funcionamiento de “DROGUERIAS”</li>
                <li>Consideraciones a tener presente en el llenado de la Solicitud con carácter de Declaración Jurada (Formato A-2).</li>
                <li>Que actividades debo de solicitar en una apertura.</li>
                <li>Horario del establecimiento farmacéutico y del Director Técnico.</li>
                <li>Requerimientos que debe de consignar el Plano de la Droguería.</li>
                <li>Información necesaria que debe tener el contrato de servicio de almacenamiento.</li>
              </ul>
            </Panel>
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}