import React, { Component } from 'react'
import {
  Divider,
  Collapse,
  Button
} from 'antd'

import { DownloadOutlined } from '@ant-design/icons';

import '../../../../estilos-paginas/cursos/cursos.css'
import buenasPracticasEnLaCadenaDeFrioParaProductosFarmaceuticosBiologicosYODispositivosMedicos from '../../../../assets/cursos/gratuitos/curso20/BuenasPracticasEnLaCadenaDeFrioParaProductosFarmaceuticosBiologicosYODispositivosMedicos2.png'
import pdfCurso from '../../../../assets/cursos/gratuitos/pdfcursos/cursoBuenasPracticasEnLaCadenaDeFrioParaProductosFBYODM.pdf'

const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class BuenasPracticasEnLaCadenaDeFrioParaProductosFarmaceuticosBiologicosYODispositivosMedicos extends Component {
  render() {
    return(
      <>
        <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={buenasPracticasEnLaCadenaDeFrioParaProductosFarmaceuticosBiologicosYODispositivosMedicos}
            style={{minWidth: 280, maxWidth: 350, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>BUENAS PRÁCTICAS EN LA CADENA DE FRÍO PARA PRODUCTOS FARMACÉUTICOS, BIOLÓGICOS Y/O DISPOSITIVOS MÉDICOS</h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("TEMARIO")}
              <ul>
                <li>Importancia de la cadena de frío.</li>
                <li>Cadena de frío y marco regulatorio.</li>
                <li>Procesos de Recepción de productos refrigerados.</li>
                <li>Almacenamiento de productos refrigerados.</li>
                <li>Distribución y Transporte de productos refrigerados.</li>
                  <ul>Calicación de Vehículo de Transporte</ul>
                  <ul>Calicación de contenedores pasivos</ul>
                <li>Análisis de Riesgo.</li>
                <li>Planes de Contingencia.</li>
              </ul>

              <a href={pdfCurso} download>
              <Button type="primary" shape="round" icon={<DownloadOutlined/>} size={'large'}>
                Descargar PDF
               </Button>
              </a>
            </Panel>
            <Panel key="3"
              style={{ backgroundColor:'#1890ff'}}
            >
              <ul>
                <li>Si desea registrar su participación en el webinar: BUENAS PRÁCTICAS EN LA CADENA DE FRÍO PARA PRODUCTOS FARMACÉUTICOS, BIOLÓGICOS Y/O DISPOSITIVOS MÉDICOS, agradeceré llenar el siguiente formulario de inscripción: </li>
              </ul>
              <br/> <a href="https://forms.gle/ijeuWAMzyQ9fBDka7"rel="noopener noreferrer" target="_blank"><Button type="primary" shape="round" size={'large'}>FORMULARIO DE INSCRIPCIÓN </Button></a>
            </Panel>
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}