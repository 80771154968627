import React from 'react';

function Titulo(props) {
  return(
    <>
      <h1>
        { props.nombre }
      </h1>
    </>
  )
}

export default Titulo
