import React, { Component } from 'react'
import {Card, Button, Modal, Carousel} from 'antd'
//, Modal, Carousel
import  Jumbotron from '../componentes/Jumbotron'
import '../estilos-paginas/inicio/inicio.css'
import { GrAnnounce } from "react-icons/gr";
import { DownloadOutlined } from '@ant-design/icons';

import DecretoSupremoNro0222021SA from '../assets/noticias/noticia1/DecretoSupremoNro002-2021-SA.pdf'
import DecretoSupremoNro0042021SA from '../assets/noticias/noticia2/DecretoSupremoNro004-2021-SA.pdf'
import ResolucionMinisterialN4042021MINSA from '../assets/noticias/noticia3/Resolución MinisterialN°404-2021-MINSA-Anexo.pdf'
import MODIFICACIONRMN10532020MINSA from '../assets/noticias/noticia4/MODIFICACION-RM-N1053-2020-MINSA.pdf'
//import video from '../assets/videos/VIDEO_BPA2023.mp4'

export default class Inicio extends Component {
  state = {
    modal1Visible: true,
    modal2Visible: true,
  };
  
  setModal1Visible(modal1Visible) {
    this.setState({ modal1Visible });
  }
  render(){ 
    return (
    <> 
      
      <Modal
        centered
        visible={this.state.modal1Visible}
        onOk={() => this.setModal1Visible(false)}
        onCancel={() => this.setModal1Visible(false)}
        width={1050}
        footer={null}
        header={null}
        >
          <Carousel autoplay fade="true">
            <div>
              <a href="/cursos/2024/PROCEDIMIENTO_ADMINISTRATIVO_GENERAL_EN_ESTABLECIMIENTOS_FARMACEUTICO">
                <div className="modal-imagen1" />
              </a>
            </div>
            <div>
              <a href="/cursos/2024/BUSQUEDA_Y_GESTION_DE_LA_LITERATURA_CIENTIFICA_SOBRE_MEDICAMENTOS">
                <div className="modal-imagen2" />
              </a>
            </div>
          </Carousel>
      </Modal>
      <Jumbotron />
      <div className="first-wrapper-inicio">
        <h2>SGA FARMA S.A.C.</h2>
        <h2>
          Somos una empresa que brinda servicios de Asesoramiento, consultoría y capacitación en temas relacionados al sector farmacéutico en base a Normas Nacionales e Internacionales.
        </h2>
      </div>
      <div className="first-wrapper-inicio">
        <Card>
          <h2><GrAnnounce/> ÚLTIMAS NOTICIAS</h2>
          <hr/>

          <Card style={{textAlign:'center', position:'relative'}}>
            <h2 style={{textAlign:'center', position:'relative'}}> <strong>R.M N° 404 - 2021/MINSA: </strong> Que aprueba el listado de medicamentos  para la exigencia de la presentación de estudios de equivalencia terapéutica para demostrar intercambiabilidad.</h2>
            <a href={ResolucionMinisterialN4042021MINSA} download>
              <Button type="primary"  shape="round" icon={<DownloadOutlined />} size={"large"} >
                Descargar la información
              </Button>
            </a>
          </Card>
          <Card style={{textAlign:'center', position:'relative'}}>
            <h2 style={{textAlign:'center', position:'relative'}}> <strong>Decreto Supremo N° 004-2021-SA: </strong> "Se modifica el Reglamento de Establecimientos farmacéuticos"</h2>
            <a href={DecretoSupremoNro0042021SA} download>
              <Button type="primary"  shape="round" icon={<DownloadOutlined />} size={"large"} >
                Descargar la información
              </Button>
            </a>
          </Card>
          <Card style={{textAlign:'center', position:'relative'}}>
            <h2 style={{textAlign:'center', position:'relative'}}> <strong>Decreto Supremo N° 002-2021-SA: </strong> "Se aprueba el Reglamento para el Registro Sanitario Condicional de Medicamentos y Productos Biológicos."</h2>
            <a href={DecretoSupremoNro0222021SA} download>
              <Button type="primary"  shape="round" icon={<DownloadOutlined />} size={"large"} >
                Descargar la información
              </Button>
            </a>
          </Card>
          <Card style={{textAlign:'center', position:'relative'}}>
            <h2 style={{textAlign:'center', position:'relative'}}> <strong>Modifican la R.M. N° 1053-2020/MINSA </strong> "RESOLUCIÓN MINISTERIAL N° 680-2021/MINSA"</h2>
            <a href={MODIFICACIONRMN10532020MINSA} download>
              <Button type="primary"  shape="round" icon={<DownloadOutlined />} size={"large"} >
                Descargar la información
              </Button>
            </a>
          </Card>
        </Card>
      </div>
    </>
    ) 
  }
}
/* 
  25

      
      <Modal
        centered
        visible={this.state.modal1Visible}
        onOk={() => this.setModal1Visible(false)}
        onCancel={() => this.setModal1Visible(false)}
        width={1050}
        footer={null}
        header={null}
        >
          <Carousel autoplay fade="true">
            <div>
              <a href="/cursos/2024/GESTION_DE_LA_INFORMACIÓN_PARA_LA_OBTENCIÓN_DE_LA_NOTIFICACIÓN_SANITARIA_OBLIGATORIA_DE_PRODUCTOS_COSMÉTICOS-PRODUCTOS_DE_HIGIENE_DOMÉSTICA_Y_PRODUCTOS_ABSORBENTES_DE_HIGIENE-REGISTRO_SANITARIO_DE_PRODUCTOS_SANITARIOS_ARTÍCULOS_PARA_BEBÉS">
                <div className="modal-imagen1" />
              </a>
            </div>
          </Carousel>
      </Modal>
   
      <Modal
        centered
        visible={this.state.modal1Visible}
        onOk={() => this.setModal1Visible(false)}
        onCancel={() => this.setModal1Visible(false)}
        width={1100}
        footer={null}
        header={null}
        >
          <Carousel autoplay fade="true">
            <div>
              <video className='videoInicio' width="100%" height="100%"  controls="controls" autoPlay src={video} />
            </div>
          </Carousel>
      </Modal>  
      <Modal
        centered
        visible={this.state.modal1Visible}
        onOk={() => this.setModal1Visible(false)}
        onCancel={() => this.setModal1Visible(false)}
        width={1050}
        footer={null}
        header={null}
        >
          <Carousel autoplay fade="true">
            <div>
              <a href="/cursos/2022/Elaboracion-Dossier-De-DISPOSITIVOS-MEDICOS-INSCRIPCION-REINSCRIPCION-RS">
                <div className="modal-imagen1" />
              </a>
            </div>
          </Carousel>
      </Modal>    
      
*/