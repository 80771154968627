import React from 'react';
import "./Footer.css"
import { FaFacebook, FaPhoneAlt } from "react-icons/fa";
import cursoNuevo from '../assets/cursos/gratuitos/2024/curso45/FLYER_.png'
import cursoNuevo2 from '../assets/cursos/gratuitos/2024/curso46/FLYER_BUSQ_INF.png'

import { GrMail } from "react-icons/gr";

import { Card, Col, Row } from 'antd'
const { Meta } = Card;


const Footer = () => {
  return (
    <>
      <div className="footer-top">
        <Card bordered={false} style={{  backgroundColor:'rgba(0, 0, 0, 0.7)', color:'#cccc'}}>
          <Row gutter={24} type="flex" >

            <Col span={8} xs={24} sm={24} md={24} lg={8} xl={8} e>
              <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FSGAFARMA%2Fposts%2Fpfbid0ktqvZBwLMTdaQEw2KhHZqBkGCHa52xbGmBrQZu5EUCEbashqDAZ39fHYH9b8BoVAl&show_text=true&width=500" width="450" height="502" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>'}} />

            </Col>

            <Col span={8} xs={24} sm={24} md={24} lg={8} xl={8} e>
            <div className="footer-top-curso">
              <h3>Próximos Cursos</h3>
                <a href="/cursos/2024/PROCEDIMIENTO_ADMINISTRATIVO_GENERAL_EN_ESTABLECIMIENTOS_FARMACEUTICO">
                  <Card
                    cover={<img alt="cursos" src={cursoNuevo} />}
                    hoverable
                    style={{ minWidth: 290, maxWidth: 350 }}
                  >
                    <Meta title="PROCEDIMIENTO ADMINITRATIVO GENERAL EN ESTABLECIMIENTOS FARMACÉUTICOS" description="Más información sobre el curso." />
                  </Card>
                </a>
                <br />

                <a href="/cursos/2024/BUSQUEDA_Y_GESTION_DE_LA_LITERATURA_CIENTIFICA_SOBRE_MEDICAMENTOS">
                  <Card
                    cover={<img alt="cursos" src={cursoNuevo2} />}
                    hoverable
                    style={{ minWidth: 290, maxWidth: 350 }}
                  >
                    <Meta title="BÚSQUEDA Y GESTIÓN DE LA LITERATURA CIENTÍFICA SOBRE MEDICAMENTOS" description="Más información sobre el curso." />
                  </Card>
                </a>

              </div>
            </Col>
            <Col span={8} xs={24} sm={24} md={24} lg={8} xl={8}>
              <h3 className="logo-text">
                Enlaces Relacionados 
              </h3>
              <ul>
                <li className="li-footer-top">
                  <a href="http://www.digemid.minsa.gob.pe/" target="blank" >Digemid</a>
                </li>
                <li className="li-footer-top">
                  <a href="https://www.gob.pe/minsa/" target="blank" >Minsa</a>
                </li>
                <li className="li-footer-top">
                  <a href="https://www.vuce.gob.pe/" target="blank" >vuce</a>
                </li>
                <li className="li-footer-top">
                  <a href="https://www.who.int/es/" target="blank" >OMS</a>
                </li>
                <li>
                  <a href="https://www.paho.org/per/" target="blank" >OPS</a>
                </li>
              </ul>
            </Col>
            <Col span={8} xs={24} sm={24} md={24} lg={8} xl={8}>
              <h3 className="logo-text">
                Nosotros 
              </h3>

              <div className="socials">
                Av. Fray Luis de Leon 590, Cercado de Lima
                <address style={{ padding:"15px 0"}}>
                  <FaPhoneAlt />&nbsp; 941649600
                  &nbsp;<GrMail />&nbsp;informes@sgafarma.com
                </address>
                  Siguenos en:&nbsp;
                  <a href="https://www.facebook.com/SGA-FARMA-113487123735309" target="blank"><FaFacebook /></a>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
      <div className="footer-bottom">
        &copy;{new Date().getFullYear()} SGA FARMA 
      </div>
    </>
  )
}
export default Footer;
/*        
  22

     <Col span={8} xs={24} sm={24} md={24} lg={8} xl={8} e>
        <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FSGAFARMA%2Fposts%2Fpfbid0jvuuTiqzxprQWAcJxZ2LGPE9SL8t57RXFTThBdhN1enGwhJq6AXbYJteJ6JqH9Jgl&show_text=true&width=500&is_preview=true" width="450" height="583" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>'}} />

      </Col>
      
        <div className="footer-top-curso">
          <h3>Próximos Cursos</h3>
          <a href="/cursos/2024/GESTION_PARA_LA_IMPLEMENTACION_DEL_SISTEMA_DE_FARMACOVIGILANCIA_EN_UNA_DROGUERIA_Y_LABORATORIO_FARMACEUTICO">
            <Card
              cover={<img alt="cursos" src={cursoNuevo} />}
              hoverable
              style={{ minWidth: 290, maxWidth: 350 }}
            >
              <Meta title="GESTIÓN PARA LA IMPLEMENTACIÓN DEL SISTEMA DE FARMACOVIGILANCIA EN UNA DROGUERÍA Y LABORATORIO FARMACÉUTICO" description="Más información sobre el curso." />
            </Card>
          </a>
        </div>          

          <Col span={8} xs={24} sm={24} md={24} lg={8} xl={8} e>
            <div className="footer-top-curso">
              <h3>Próximos Cursos</h3>
                <a href="/cursos/2021/Busqueda-y-gestion-de-la-literatura-cientifica-sobre-medicamentos">
                  <Card
                    cover={<img alt="cursos" src={cursoNuevo3} />}
                    hoverable
                    style={{ minWidth: 290, maxWidth: 350 }}
                  >
                    <Meta title="BÚSQUEDA Y GESTIÓN DE LA LITERATURA CIENTÍFICA SOBRE MEDICAMENTOS" description="Más información sobre el curso." />
                  </Card>
                </a>
                <br />

                <a href="/cursos/2021/covid19_1">
                  <Card
                    cover={<img alt="cursos" src={cursoNuevo} />}
                    hoverable
                    style={{ minWidth: 290, maxWidth: 350 }}
                  >
                    <Meta title="VIGILANCIA, PREVENCIÓN Y CONTROL DE LA ENFERMEDAD POR CORONAVIRUS(COVID-19)" description="Más información sobre el curso." />
                  </Card>
                </a>
                <br />
                <a href="/cursos/2021/Informes-Periodicos-De-Seguridad-De-Productos-Farmaceuticos">
                  <Card
                    cover={<img alt="cursos" src={cursoNuevo2} />}
                    hoverable
                    style={{ minWidth: 290, maxWidth: 350 }}
                  >
                    <Meta title="INFORMES PERIÓDICOS DE SEGURIDAD DE PRODUCTOS FARMACÉUTICOS" description="Más información sobre el curso." />
                  </Card>
                </a>

              </div>
            </Col>
*/