import React, { Component } from 'react'
import { Nav, Navbar } from 'react-bootstrap'

import './Navigationbar.css'
import styled from 'styled-components'
import { 
  BsHouseDoorFill,
  BsFillPersonFill,
  BsFillPeopleFill,
  BsBriefcaseFill,
  BsFillFolderFill,
  BsChatDotsFill,
} from 'react-icons/bs'
import logo from '../assets/logo.ico'


const Styles  = styled.div`
  .navbar {
    padding: 0px 8px 0px 8px;
  }
  .navbar {
    background-color: #FFFFFF;
    position: relative;
    overflow-y:auto;
    max-heigth:100vh;
    border-bottom: 1.5px solid #000;
  }

  .navbar-nav .nav-link {
    color: #000;
    padding-left: 2.5rem;  
    font-size: 18.5px;
  }

`;

export class NavigationBar  extends Component {
  render() {
    return (
    <>
      <div className="top-navbar">
        <ul>
          <li className="left-info"> 
            Contactanos: 
            <a href="tel:+51 941649600">
              +51 941649600 
            </a> | <a target="blank" href="https://sgafarma.com/contacto">informes@sgafarma.com</a> 
          </li>
          <li className="right-info">
            Siguenos en: 
            <a target="blank" href="https://www.facebook.com/SGA-FARMA-113487123735309">
              &nbsp;FACEBOOK
            </a>
          </li>
        </ul>
      </div>
      <Styles>
        <Navbar expand="lg">
          <Navbar.Brand href="/" style={{ margin: 0 , padding: 0}}>
            <div className="logo-navbar" >
              <img src={logo} style={{ width: "40%", height:"40%"}} alt="logo" />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" >
            <Nav className="ml-auto" >
              <Nav.Item><Nav.Link href="/"> <BsHouseDoorFill /> Inicio</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link href="/sobre-nosotros"><BsFillPersonFill /> Quiénes Somos</Nav.Link></Nav.Item>
              {/* <Nav.Item><Nav.Link href="/productos"><BsFillBagFill /> Productos</Nav.Link></Nav.Item> */}
              <Nav.Item><Nav.Link href="/clientes" ><BsFillPeopleFill /> Clientes</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link href="/servicios"><BsBriefcaseFill /> Servicios</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link href="/cursos"><BsFillFolderFill /> Cursos</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link href="/contacto"><BsChatDotsFill /> Contacto</Nav.Link></Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Styles>
    </>
    )
  }
}