import React, { Component } from 'react'
import {
  Divider,
  Collapse,
  Button
} from 'antd'

import { DownloadOutlined } from '@ant-design/icons';

import '../../../../estilos-paginas/cursos/cursos.css'
import covid19_1 from '../../../../assets/cursos/gratuitos/decimoTercerCurso/covid19_1.png'
import pdfCurso from '../../../../assets/cursos/gratuitos/pdfcursos/webinarCovid19_1.pdf'

const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class Covid19_1 extends Component {
  render() {
    return(
      <>
        <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={covid19_1}
            style={{minWidth: 280, maxWidth: 350, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>VIGILANCIA, PREVENCIÓN Y CONTROL DE LA ENFERMEDAD POR CORONAVIRUS (COVID-19)</h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("TEMARIO")}
              <ul>
                <li>Conceptos y generalidades de la enfermedad por coronavirus (COVID-19).</li>
                <li>Nuevas Variantes del SARS-CoV-2.</li>
                <li>Vigilancia Epidemiología de la enfermedad por coronavirus (COVID-19).</li>
                <li>Fases de la enfermedad COVID-19.</li>
                <li>Síntomas de alerta.</li>
                <li>Farmacoterapia para el tratamiento de la enfermedad por coronavirus (COVID-19).</li>
                <li>Cuidados que debe tener la comunidad para evitar el contagio.</li>
              </ul>

              <a href={pdfCurso} download>
              <Button type="primary" shape="round" icon={<DownloadOutlined/>} size={'large'}>
                Descargar PDF
               </Button>
              </a>
            </Panel>
            <Panel key="3"
              style={{ backgroundColor:'#1890ff'}}
            >
              <ul>
                <li>Si deseas Participar en el webinar: VIGILANCIA, PREVENCIÓN Y CONTROL DE LA ENFERMEDAD POR CORONAVIRUS (COVID-19) agradeceré llenar el siguiente formulario de inscripción: </li>
              </ul>
              <br/> <a href="https://forms.gle/zz5fLN7Pj7sXSEP36"rel="noopener noreferrer" target="_blank"><Button type="primary" shape="round" size={'large'}>FORMULARIO DE INSCRIPCIÓN </Button></a>
            </Panel>
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}