import React, { Component } from 'react'
import {
  Divider,
  Collapse,
  Button
} from 'antd'

import { DownloadOutlined } from '@ant-design/icons';

import '../../../../estilos-paginas/cursos/cursos.css'
import imagen from '../../../../assets/cursos/gratuitos/curso38/brochure.png'
import pdfCurso from '../../../../assets/cursos/gratuitos/pdfcursos/GESTION-REGISTRO SANITARIO_2023.pdf'

const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class GestionDeLaInformacionParaLaElaboracionDelDossierDeUnaEspecialidadFarmaceuticaDispositivoMedicoParaLaInscripcionOReinscripcionEnElRegistroSanitario extends Component {
  render() {
    return(
      <>
        <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={imagen}
            style={{minWidth: 280, maxWidth: 350, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>GESTIÓN DE LA INFORMACIÓN PARA LA ELABORACIÓN DEL DOSSIER DE UNA ESPECIALIDAD FARMACÉUTICA, DISPOSITIVO MÉDICO PARA LA INSCRIPCIÓN O REINSCRIPCIÓN EN EL REGISTRO SANITARIO</h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("TEMARIO")}
              <ul>
                <li>Día 01:</li>
                <p>Ponente: Q.F. Miriam Noemí Cuba Mendoza</p>
                <p>03 de Octubre (Hora: 7:00 pm a 10:00 pm) GESTIÓN DE LA INFORMACIÓN PARA LA ELABORACIÓN DEL DOSSIER DE UNA ESPECIALIDAD MÉDICA:</p>
                    <ul>
                        <li>Conceptos y generalidades para el Registro Sanitario.</li>
                        <ul>
                            <li>Registro Sanitario</li>
                            <li>Ley 29459 y D.S. 016-2011 -SA.</li>
                            <li>Eficacia, Seguridad y Calidad</li>
                        </ul>
                        <li>Requisitos para la Inscripción y Reinscripción de Especialidades Farmacéuticas(categoría 1,2 y 3)</li>
                    </ul>
                <br/>
                <th/>
                <li>Día 02:</li>
                <p>Ponente: Q.F. Julio Américo Salas Carnero</p>
                <p>05 de Octubre (Hora: 7:00 pm a 10:00 pm) GESTIÓN DE LA INFORMACIÓN PARA LA ELABORACIÓN DEL DOSSIER DE UN DISPOSITIVO MÉDICO</p>
                    <ul>
                        <li>Marco Regulatorio para el Registro Sanitario de los Dispositivos Médicos:</li>
                            <ul>
                                <li>Ley 29459.</li>
                                <li>D.S. N° 016-2011 -SA y sus modificaciones.</li>
                                <li>D.S. N° 003-2020 -SA.</li>
                            </ul>
                        <li>Regla de clasificación de los Dispositivos Médicos.</li>
                        <li>Requisitos Regulatorios para el proceso de Inscripción y Reinscripción de los Dispositivos Médicos.</li>
                    </ul>
                <li>Día 3:</li>
                <p>Ponente: PHD. Patricia Rivas Granizo</p>
                <p>06 de Octubre (Hora: 6:00 pm a 9:00 pm) ESTUDIO DE ESTABILIDAD DE LAS ESPECIALIDADES FARMACÉUTICAS</p>
                    <ul>
                        <li>Introducción y generalidades de la Norma Técnica de Salud NTS N° 182 - MINSA/DIGEMID-2022</li>
                        <li>Selección de lotes a efectos de realizar estudio de estabilidad, Sistema envase cierre, Especificaciones de estudio de estabilidad, Ensayos, Técnicas analíticas, Frecuencia de estudio (Estudio de Estabilidad Acelerada, Estudio de Estabilidad a Largo Plazo), Condiciones de almacenamiento, Cambios significativos en el estudio de estabilidad acelerado, Estudios de estabilidad de uso, Estudios de estabilidad reducidos, Estudio de foto estabilidad, Programa de seguimiento de estudio de estabilidad, Periodo de validez, Continuación de los estudios de estabilidad, Consideraciones para el rotulado, Contenido del documento de los estudios de estabilidad.</li>
                    </ul>
              </ul> 
              <a href={pdfCurso} download>
              <Button type="primary" shape="round" icon={<DownloadOutlined/>} size={'large'}>
                Descargar PDF
               </Button>
              </a>
            </Panel>
            <Panel key="3"
              style={{ backgroundColor:'#1890ff'}}
            >
              <ul>
                <li>Si desea registrar su participación en el webinar: GESTIÓN DE LA INFORMACIÓN PARA LA ELABORACIÓN DEL DOSSIER DE UNA ESPECIALIDAD MÉDICA, DISPOSITIVOS MÉDICOS PARA LA INSCRIPCIÓN O REINSCRIPCIÓN EN EL REGISTRO SANITARIO, agradeceré llenar el siguiente formulario de inscripción: </li>
              </ul>
              <br/> <a href="https://forms.gle/vZseLGWhmXeSHMpc9"rel="noopener noreferrer" target="_blank"><Button type="primary" shape="round" size={'large'}>FORMULARIO DE INSCRIPCIÓN </Button></a>
            </Panel>
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}