// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/cursos/gratuitos/2024/curso45/BROCHURE.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/cursos/gratuitos/2024/curso46/BROCHURE.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/cursos/gratuitos/duoDecimoCurso/InformesPeriodicosDeSeguridadDeProductosFarmaceuticos.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".first-wrapper-inicio {\n  background-repeat: no-repeat;\n  background-color: #f5f5f5;\n  color: #000000;\n  background-attachment: scroll;\n  padding: 25px 100px;\n}\n.modal-imagen1 {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center center;\n  background-size: cover;\n  height: 1400px;\n  width: 100%;\n}\n.modal-imagen2 {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat center center;\n  background-size: cover;\n  height: 1500px;\n  width: 100%;\n}\n\n.modal-imagen3 {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat center center;\n  background-size: cover;\n  height: 1450px;\n  width: 100%;\n}\n\n.first-wrapper-inicio h2 {\n  text-align: justify;\n  font-size: 23px;\n}\n\n@media(max-width:995px) {\n  .first-wrapper-inicio {\n    padding: 45px 50px;\n  }\n  .first-wrapper-inicio h2 {\n    font-size: 19px;\n  }\n  .modal-imagen1 {\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat absolute absolute;\n    background-size: cover;\n    max-height: 500px;\n    background-size: 100% 100%; \n  }\n  .modal-imagen2 {\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat absolute absolute;\n    background-size: cover;\n    max-height: 350px;\n    background-size: 100% 100%; \n  }\n  .modal-imagen3 {\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat absolute absolute;\n    background-size: cover;\n    max-height: 350px;\n    background-size: 100% 100%; \n  }\n}\n", ""]);
// Exports
module.exports = exports;
