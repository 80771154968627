import React, { Component } from 'react'
import {
  Divider,
  Collapse,
  Button
} from 'antd'

import { DownloadOutlined } from '@ant-design/icons';

import '../../../../estilos-paginas/cursos/cursos.css'
import imagen from '../../../../assets/cursos/gratuitos/2024/curso44/FLYER.png'
import pdfCurso1 from '../../../../assets/cursos/gratuitos/2024/curso44/BROCHURE_PSICOTROPICOS.pdf'


const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class ManejoYControlDeEstupefacientesYPsicotropicosEnEstablecimientosFarmaceuticos2024 extends Component {
  render() {
    return(
      <>
        <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={imagen}
            style={{minWidth: 280, maxWidth: 350, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>MANEJO Y CONTROL DE ESTUPEFACIENTES Y PSICOTROPICOS EN ESTABLECIMIENTOS FARMACEUTICOS</h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("TEMARIO")}
              <ul>
                <p>Ponente: Q.F. JAIME CESAR SANTI LEÓN</p>
                <p>MODULO I (03 JULIO): MANEJO Y CONTROL DE ESTUPEFACIENTES Y PSICOTROPICOS EN UNA DROGUERIA:</p>
                <li>Normativa: D.S. N° 023-2001-SA: Reglamento de estupefacientes, psicotrópicos y otras sustancias sujetas a fiscalización sanitaria y su modificatoria D.S. N° 024-2020-SA.</li>
                <li>Previsiones.</li>
                <li>Importación (Certificado Oficial).</li>
                <li>Declaración del Consumo Anual.</li>
                <li>Fabricación.</li>
                <li>Almacenamiento.</li>
                <li>Alcance de la Fiscalización.</li>
                <li>Balance Trimestral.</li>
                <li>Libros de Estupefacientes y Psicotrópicos (Recomendaciones para el llenado).</li>
                <li>Visación de Libros.</li>
                <li>Libros Electrónicos.</li>
                <br/>
                <th/>
              </ul>

              <ul>
                <p>MODULO II (04 JULIO): MANEJO Y CONTROL DE ESTUPEFACIENTES Y PSICOTROPICOS EN UNA OFICINA FARMACEUTICA:</p>
                <li>Normativa: D.S. N° 023-2001-SA: Reglamento de estupefacientes, psicotrópicos y otras sustancias sujetas a fiscalización sanitaria y su modificatoria D.S. N° 024-2020-SA.</li>
                <li>Prescripción.</li>
                <li>Dispensación.</li>
                <li>Recetas Especiales.</li>
                <li>Alcance de la Fiscalización.</li>
                <li>Balance Trimestral.</li>
                <li>Libros de Estupefacientes y Psicotrópicos (Recomendaciones para el llenado)</li>
                <li>Visación de Libros.</li>
                <li>Libros Electrónicos.</li>
              </ul>

              <ul style={{ display: 'flex', justifyContent: 'space-between'}}>
                <br/>
                <a href={pdfCurso1} download>
                <Button type="primary" shape="round" icon={<DownloadOutlined/>} size={'large'}>
                  PDF PRODUCTOS COSMÉTICOS
                </Button>
                </a>
              </ul>
            </Panel>
            <Panel key="3"
              style={{ backgroundColor:'#1890ff'}}
            >
              <ul>
                <li>Si desea registrar su participación en el webinar: MANEJO Y CONTROL DE ESTUPEFACIENTES Y PSICOTROPICOS EN ESTABLECIMIENTOS FARMACEUTICOS, agradeceré llenar el siguiente formulario de inscripción: </li>
              </ul>
              <br/> <a href="https://forms.gle/Z1dTM8r2ACusyanBA"rel="noopener noreferrer" target="_blank"><Button type="primary" shape="round" size={'large'}>FORMULARIO DE INSCRIPCIÓN </Button></a>
            </Panel>
            
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}