import React, { Component } from 'react'

import Curso from '../cursos'
import BuenasPracticasDeDistribucionYTransporte from './2020/buenas-practicas-de-distribucion-y-transporte'
import ComoVenderAlEstadoProductosFarmaceuticos from './2020/como-vender-al-estado-productos-farmaceuticos'
import ImplementacionSistemaFarmacovigilancia from './2020/implementacion-del-sistema-de-farmacovigilancia-para-laboratorios,-droguerias-y-almacenes-especializados'
import AutorizacionesSanitariasDeEstablecimientosFarmaceuticos from './2020/autorizaciones-sanitarias-de-establecimientos-farmaceuticos'
import CertificacionBuenasPracticasDeAlmacenesEspecializados from './2020/certificacion-en-buenas-practicas-de-almacenamiento-de-droguerias-y-almacenes-especializados'
import ControlVigilanciaDeEstablecimientosFarmaceuticos from './2020/regulacion-en-el-control-de-calidad-de-productos-farmaceuticos,-dispositivos-medicos-y-sanitarios'
import Comoaperturarunadrogueria from './2020/Como-aperturar-una-droguería'
import QueDebemosTenerEnCuentaParaRealizarElMapeoTermicoDeUnAlmacenYLaCalificacionDelEmbalajeIsotermicoDeUnaDrogueria from './2020/Que-debemos-tener-en-cuenta-para-realizar-el-mapeo-termico-de-un-almacen-y-la-calificacion-del-embalaje-isotermico-de-una-drogueria'
import QueDocumentosSonNecesariosParaLaElaboracionDeUnDossierParaLaInscripcionOReinscripcionDeUnaEspecialidadFarmaceutica from './2020/Que-documentos-son-necesarios-para-la-elaboracion-de-un-dossier-para-la-inscripcion-o-reinscripcion-de-una-especialidad-farmaceutica'
import GestionDeNotificacionesSanitariasDeProductosCosmeticos from './2020/Gestion-de-Notificaciones-Sanitarias-de-Productos-Cosmeticos'
import GestionParaLaImplementacionDelSistemaDeFarmacovigilanciaEnUnaDrogueriaYLaboratorioFarmaceutico from './2020/Gestion-Para-La-Implementacion-Del-Sistema-De-Farmaco-vigilancia-En-Una-Drogueria-Y-Laboratorio-Farmaceutico'
import ComoSolicitarYObtenerLaAutorizacionSanitariaDeUnaOficinaFarmaceutica from './2020/Como-solicitar-y-obtener-la-autorizacion-sanitaria-de-una-oficina-farmaceutica'
import CertificacionEnBuenasPracticasDeAlmacenamiento from './2020/Certificacion-en-buenas-practicas-de-almacenamiento'
import CertificacionEnBuenasPracticasDeDistribucionYTransporte from './2020/Certificacion-en-buenas-practicas-de-distribucion-y-transporte'
import PesquisaYProcesoDeControlDeCalidadDeProductosFarmaceuticos from './2020/Pesquisa-y-proceso-de-control-de-calidad-de-productos-farmaceuticos'
import GestionParaImplementacionDelSistemaDeTecnovigilanciaEnUnaDrogueriaOLaboratorioDeDispositivoMedico from './2021/Gestion-para-implementacion-del-sistema-de-tecnovigilancia-en-una-drogueria-o-laboratorio-de-dispositivo-medico'
import InformesPeriodicosDeSeguridadDeProductosFarmaceuticos from './2021/Informes-Periodicos-De-Seguridad-De-Productos-Farmaceuticos'
import BusquedaYGestionDeLaLiteraturaCientificaSobreMedicamentos from './2021/Busqueda-y-gestion-de-la-literatura-cientifica-sobre-medicamentos'
import Covid19_1 from './2021/covid19_1'
import RequisitosTecnicosParaSolicitarYObtenerLaAutorizacionSanitariaDeUnaDrogueria from './2021/Requisitos-tecnicos-para-solicitar-y-obtener-la-autorizacion-sanitaria-de-una-drogueria'
import GestionDeLaInformacionParaLaElaboracionDelDossierDeDispositivosMedicosParaLaInscripcionOReinscripcionEnElRegistroSanitario from './2021/Gestion-de-la-informacion-para-la-elaboracion-del-dossier-de-dispositivos-medicos-para-la-inscripcion-o-reinscripcion-en-el-registro-sanitario'
import GestionDeLaInformacionParaLaElaboracionDelDossierDeEspecialidadesFarmaceuticasParaLaInscripcionOReinscripcionEnElRegistroSanitario from './2021/Gestion-De-La-Informacion-Para-La-Elaboracion-Del-Dossier-De-Especialidades-Farmaceuticas-Para-La-Inscripcion-O-Reinscripcion-En-El-Registro-Sanitario'
import IntercambiabilidadDeMedicamentos from './2021/Intercambiabilidad-De-Medicamentos'
import ManejoYControlDeEstupefacientesYPsicotropicosEnEstablecimientosFarmaceuticos from './2021/Manejo-Y-Control-De-Estupefacientes-Y-Psicotropicos-En-Establecimientos-Farmaceuticos'
import ProcedimientoAdministrativoGeneralEnEstablecimientosFarmaceuticos from './2021/Procedimiento-Administrativo-General-En-Establecimientos-Farmaceuticos'
import BuenasPracticasEnLaCadenaDeFrioParaProductosFarmaceuticosBiologicosYODispositivosMedicos from './2021/Buenas-Practicas-En-La-Cadena-De-Frio-Para-Productos-Farmaceuticos-Biologicos-Y-O-Dispositivos-Medicos'
import CertificacionEnBuenasPracticasDeAlmacenamientoParaDrogueriasYAlmacenesEspecializados2022 from './2022/Certificacion-En-Buenas-Practicas-De-Almacenamiento-Para-Droguerias-Y-Almacenes-Especializados'
import CertificacionEnBuenasPracticasDeDistribucionYTransporte2022 from './2022/Certificacion-En-Buenas-Practicas-De-Distribucion-y-Transporte'
import BuenasPracticasDeFarmacovigilanciaEnUnaDrogueriaYLaboratorioFarmaceutico from './2022/Buenas-Practicas-De-Farmacovigilancia-En-Una-Drogueria-Y-Laboratorio-Farmaceutico'
import GestionDeLasSospechasDeReaccionesAdversasALosMedicamentos from './2022/Gestion-De-Las-Sospechas-De-Reacciones-Adversas-A-Los-Medicamentos'
import IPSEPASignals from './2022/IPS-EPA-Signals'
import PESQUISA042022 from './2022/Pesquisa-04-2022'
import DossierPDyPN from './2022/Elaboracion-Dossier-De-Productos-Dieteticos-Y-Naturales'
import DossierDMINSCYREINSCRS from './2022/Elaboracion-Dossier-De-DISPOSITIVOS-MEDICOS-INSCRIPCION-REINSCRIPCION-RS'
import CBPOficinaFarmaceutica from './2022/Certificacion-Buenas-Practicas-Oficina-Farmaceutica'
import GestionDeLaInformacionParaLaElaboracionSanitariaObligatoriaDeProductosCosmeticos from './2022/GESTION-DE-LA-INFORMACION-PARA-LA-OBTENCION-DE-LA-NOTIFICACION-SANITARIA-OBLIGATORIA-DE-PRODUCTOS-COSMETICOS'
import GestionDeLaInformacionParaLaObtencionDeLaNotificacionSanitariaDeProductosDeHigieneDomesticaYProductosAbsorbentesDeHigieneRegistroSanitarioDeProductos from './2022/GESTION-DE-LA-INFORMACION-PARA-LA-OBTENCION-DE-LA-NOTIFICACION-SANITARIA-DE-PRODUCTOS-DE-HIGIENE-DOMESTICA-Y-PRODUCTOS-ABSORBENTES-DE-HIGIENE-REGISTRO-SANITARIO-DE-PRODUCTOS'
import BusquedaYGestionDeLaLiteraturaCientificaSobreMedicamentos2022 from './2022/BUSQUEDA-Y-GESTION-DE-LA-LITERATURA-CIENTIFICA-SOBRE-MEDICAMENTOS'
import BuenasPracticasDeCadenaDeFrioParaProductosFarmaceuticosBiologicosYODispositivosMedicos from './2023/BUENAS-PRACTICAS-DE-CADENA-DE-FRIO-PARA-PRODUCTOS-FARMACEUTICOS-BIOLOGICOS-Y-O-DISPOSITIVOS-MEDICOS'
import BuenasPracticasDeAlmacenamiento from './2023/BUENAS-PRACTICAS-DE-ALMACENAMIENTO'
import CertificacionBuenasPracticasDeDistribucionYTransporte from './2023/CERTIFICACION-BUENAS-PRACTICAS-DE-DISTRIBUCION-Y-TRANSPORTE'
import NormativaRegulaIntercambiabilidadDeMedicamentosEnPeru from './2023/NORMATIVA-QUE-REGULA-LA-INTERCAMBIABILIDAD-DE-MEDICAMENTOS-EN-EL-PERU'
import GestionParaLaImplementacionDelSistemaDeFarmacoVigilanciaEnUnaDrogueriaYLaboratorioFarmaceutico from './2023/GESTION-PARA-LA-IMPLEMENTACION-DEL-SISTEMA-DE-FARMACOVIGILANCIA-EN-UNA-DROGUERIA-Y-LABORATORIO-FARMACEUTICO'
import GestionDeLaInformacionParaLaElaboracionDelDossierDeUnaEspecialidadFarmaceuticaDispositivoMedicoParaLaInscripcionOReinscripcionEnElRegistroSanitario from './2023/GESTION-DE-LA-INFORMACION-PARA-LA-ELABORACION-DEL-DOSSIER-DE-UNA-ESPECIALIDAD-FARMACEUTICA-DISPOSITIVOS-MEDICOS-PARA-LA-INSCRIPCION-O-REINSCRIPCION-EN-EL-REGISTRO-SANITARIO'
import CriteriosTecnicosParaRealizarElMapeoTermicoDeAlmacenesYCalificacionDeVehiculosDeTransporteYCamaraFriaEnUnaDrogueriaOAlmacenEspecializado from './2023/CRITERIOS_TECNICOS_PARA_REALIZAR_EL_MAPEO_TERMICO_DE_ALMACENES_Y_CALIFICACION_DE_VEHICULOS_DE_TRANSPORTE_Y_CAMARA_FRIA_EN_UNA_DROGUERIA_O_ALMACEN_ESPECIALIZADO'
import PesquisaYProcesoDeControlDeCalidadDeProductosFarmaceuticosDispositivosMedicosYProductosSanitarios from './2023/PESQUISA_Y_PROCESO_DE_CONTROL_DE_CALIDAD_DE_PRODUCTOS_FARMACEUTICOS_DISPOSITIVOS_MEDICOS_Y_PRODUCTOS_SANITARIOS'
import GestionEImplementacionDeLasBuenasPracticasDeOficinaFarmaceutica from './2024/GESTION_E_IMPLEMENTACION_DE_LAS_BUENAS_PRACTICAS_DE_OFICINA_FARMACEUTICA'
import GestionParaLaImplementacionDelSistemaDeFarmacovigilanciaEnUnaDrogueriaYLaboratorioFarmaceutico2024 from './2024/GESTION_PARA_LA_IMPLEMENTACION_DEL_SISTEMA_DE_FARMACOVIGILANCIA_EN_UNA_DROGUERIA_Y_LABORATORIO_FARMACEUTICO'
import GestionDeLaInformacionParaLaObtencionDeLaNotificacionSanitariaObligatoriaDeProductosCosmeticosHigieneDomesticaYAbsorbentesDeHigieneJunio from './2024/GESTION_DE_LA_INFORMACIÓN_PARA_LA_OBTENCIÓN_DE_LA_NOTIFICACIÓN_SANITARIA_OBLIGATORIA_DE_PRODUCTOS_COSMÉTICOS-PRODUCTOS_DE_HIGIENE_DOMÉSTICA_Y_PRODUCTOS_ABSORBENTES_DE_HIGIENE-REGISTRO_SANITARIO_DE_PRODUCTOS_SANITARIOS_ARTÍCULOS_PARA_BEBÉS'
import ManejoYControlDeEstupefacientesYPsicotropicosEnEstablecimientosFarmaceuticos2024 from './2024/MANEJO_Y_CONTROL_DE_ESTUPEFACIENTES_Y_PSICOTROPICOS_EN_ESTABLECIMIENTOS_FARMACEUTICOS'
import ProcedimientoAdministrativoGeneralEnEstablecimientosFarmaceuticos2024 from './2024/PROCEDIMIENTO_ADMINISTRATIVO_GENERAL_EN_ESTABLECIMIENTOS_FARMACEUTICO'
import BusquedaYGestionDeLaLiteraturaCientificaSobreMedicamentos2024 from './2024/BUSQUEDA_Y_GESTION_DE_LA_LITERATURA_CIENTIFICA_SOBRE_MEDICAMENTOS'

import { Switch, Route } from 'react-router-dom'

export default class RutaCursos extends Component {
  render(){
    return(
      <>
        <Switch>
          <Route exact path="/cursos/" component={Curso} />
          <Route 
            path="/cursos/2020/buenas-practicas-de-distribucion-y-transporte" 
            component={BuenasPracticasDeDistribucionYTransporte} />
          <Route 
            path="/cursos/2020/como-vender-al-estado-productos-farmaceuticos"
            component={ComoVenderAlEstadoProductosFarmaceuticos}
          />
          <Route 
            path="/cursos/2020/implementacion-del-sistema-de-farmacovigilancia-para-laboratorios,-droguerias-y-almacenes-especializados"
            component={ImplementacionSistemaFarmacovigilancia}
          />
          <Route 
            path="/cursos/2020/autorizaciones-sanitarias-de-establecimientos-farmaceuticos"
            component={AutorizacionesSanitariasDeEstablecimientosFarmaceuticos}
          />

          <Route 
            path="/cursos/2020/certificacion-en-buenas-practicas-de-almacenamiento-de-droguerias-y-almacenes-especializados"
            component={CertificacionBuenasPracticasDeAlmacenesEspecializados}
          />
          <Route 
            path="/cursos/2020/regulacion-en-el-control-de-calidad-de-productos-farmaceuticos,-dispositivos-medicos-y-sanitarios"
            component={ControlVigilanciaDeEstablecimientosFarmaceuticos}
          />
          <Route 
            path="/cursos/2020/Como-aperturar-una-droguería"
            component={Comoaperturarunadrogueria}
          />
          <Route 
            path="/cursos/2020/Que-debemos-tener-en-cuenta-para-realizar-el-mapeo-termico-de-un-almacen-y-la-calificacion-del-embalaje-isotermico-de-una-drogueria"
            component={QueDebemosTenerEnCuentaParaRealizarElMapeoTermicoDeUnAlmacenYLaCalificacionDelEmbalajeIsotermicoDeUnaDrogueria}
          />
          <Route 
            path="/cursos/2020/Que-documentos-son-necesarios-para-la-elaboracion-de-un-dossier-para-la-inscripcion-o-reinscripcion-de-una-especialidad-farmaceutica"
            component={QueDocumentosSonNecesariosParaLaElaboracionDeUnDossierParaLaInscripcionOReinscripcionDeUnaEspecialidadFarmaceutica}
          />
          <Route 
            path="/cursos/2020/Gestion-de-Notificaciones-Sanitarias-de-Productos-Cosmeticos"
            component={GestionDeNotificacionesSanitariasDeProductosCosmeticos}
          />
          <Route 
            path="/cursos/2020/Gestion-Para-La-Implementacion-Del-Sistema-De-Farmaco-vigilancia-En-Una-Drogueria-Y-Laboratorio-Farmaceutico"
            component={GestionParaLaImplementacionDelSistemaDeFarmacovigilanciaEnUnaDrogueriaYLaboratorioFarmaceutico}
          />
          <Route 
            path="/cursos/2020/Como-solicitar-y-obtener-la-autorizacion-sanitaria-de-una-oficina-farmaceutica"
            component={ComoSolicitarYObtenerLaAutorizacionSanitariaDeUnaOficinaFarmaceutica}
          />
          <Route 
            path="/cursos/2020/Certificacion-en-buenas-practicas-de-almacenamiento"
            component={CertificacionEnBuenasPracticasDeAlmacenamiento}
          />
          <Route 
            path="/cursos/2020/Certificacion-en-buenas-practicas-de-distribucion-y-transporte"
            component={CertificacionEnBuenasPracticasDeDistribucionYTransporte}
          />
          <Route
            path="/cursos/2020/Pesquisa-y-proceso-de-control-de-calidad-de-productos-farmaceuticos"
            component={PesquisaYProcesoDeControlDeCalidadDeProductosFarmaceuticos}
          />
          <Route 
            path="/cursos/2021/Gestion-para-implementacion-del-sistema-de-tecnovigilancia-en-una-drogueria-o-laboratorio-de-dispositivo-medico"
            component={GestionParaImplementacionDelSistemaDeTecnovigilanciaEnUnaDrogueriaOLaboratorioDeDispositivoMedico}
          />
          <Route
            path="/cursos/2021/Informes-Periodicos-De-Seguridad-De-Productos-Farmaceuticos"
            component={InformesPeriodicosDeSeguridadDeProductosFarmaceuticos}
          />
          <Route 
            path="/cursos/2021/Busqueda-y-gestion-de-la-literatura-cientifica-sobre-medicamentos"
            component={BusquedaYGestionDeLaLiteraturaCientificaSobreMedicamentos}
          />
          <Route 
            path="/cursos/2021/covid19_1"
            component={Covid19_1}
          />
          <Route
            path="/cursos/2021/Requisitos-tecnicos-para-solicitar-y-obtener-la-autorizacion-sanitaria-de-una-drogueria"
            component={RequisitosTecnicosParaSolicitarYObtenerLaAutorizacionSanitariaDeUnaDrogueria}
          />
          <Route 
            path="/cursos/2021/Gestion-de-la-informacion-para-la-elaboracion-del-dossier-de-dispositivos-medicos-para-la-inscripcion-o-reinscripcion-en-el-registro-sanitario"
            component={GestionDeLaInformacionParaLaElaboracionDelDossierDeDispositivosMedicosParaLaInscripcionOReinscripcionEnElRegistroSanitario}
          />
          <Route
            path="/cursos/2021/Gestion-De-La-Informacion-Para-La-Elaboracion-Del-Dossier-De-Especialidades-Farmaceuticas-Para-La-Inscripcion-O-Reinscripcion-En-El-Registro-Sanitario"
            component={GestionDeLaInformacionParaLaElaboracionDelDossierDeEspecialidadesFarmaceuticasParaLaInscripcionOReinscripcionEnElRegistroSanitario}
          />
          <Route 
            path="/cursos/2021/Intercambiabilidad-De-Medicamentos"
            component={IntercambiabilidadDeMedicamentos}
          />

          <Route 
            path="/cursos/2021/Manejo-Y-Control-De-Estupefacientes-Y-Psicotropicos-En-Establecimientos-Farmaceuticos"
            component={ManejoYControlDeEstupefacientesYPsicotropicosEnEstablecimientosFarmaceuticos}
          />
          <Route 
            path="/cursos/2021/Procedimiento-Administrativo-General-En-Establecimientos-Farmaceuticos"
            component={ProcedimientoAdministrativoGeneralEnEstablecimientosFarmaceuticos}
          />

          <Route 
            path="/cursos/2021/Buenas-Practicas-En-La-Cadena-De-Frio-Para-Productos-Farmaceuticos-Biologicos-Y-O-Dispositivos-Medicos"
            component={BuenasPracticasEnLaCadenaDeFrioParaProductosFarmaceuticosBiologicosYODispositivosMedicos}
          />
          <Route 
            path="/cursos/2022/Certificacion-En-Buenas-Practicas-De-Almacenamiento-Para-Droguerias-Y-Almacenes-Especializados"
            component={CertificacionEnBuenasPracticasDeAlmacenamientoParaDrogueriasYAlmacenesEspecializados2022}
          />
          <Route 
            path="/cursos/2022/Certificacion-En-Buenas-Practicas-De-Distribucion-y-Transporte"
            component={CertificacionEnBuenasPracticasDeDistribucionYTransporte2022}
          />
          <Route
            path="/cursos/2022/Buenas-Practicas-De-Farmacovigilancia-En-Una-Drogueria-Y-Laboratorio-Farmaceutico"
            component={BuenasPracticasDeFarmacovigilanciaEnUnaDrogueriaYLaboratorioFarmaceutico}
          />
          <Route
            path="/cursos/2022/Gestion-De-Las-Sospechas-De-Reacciones-Adversas-A-Los-Medicamentos"
            component={GestionDeLasSospechasDeReaccionesAdversasALosMedicamentos}
          />
          <Route
            path="/cursos/2022/IPS-EPA-Signals"
            component={IPSEPASignals}
          />
          <Route 
            path="/cursos/2022/Pesquisa-04-2022"
            component={PESQUISA042022}
          />
          <Route 
            path="/cursos/2022/Elaboracion-Dossier-De-Productos-Dieteticos-Y-Naturales"
            component={DossierPDyPN}
          />
          <Route
            path="/cursos/2022/Elaboracion-Dossier-De-DISPOSITIVOS-MEDICOS-INSCRIPCION-REINSCRIPCION-RS"
            component={DossierDMINSCYREINSCRS}
          />
          <Route
            path="/cursos/2022/Certificacion-Buenas-Practicas-Oficina-Farmaceutica"
            component={CBPOficinaFarmaceutica}
          /> 
          <Route
            path="/cursos/2022/GESTION-DE-LA-INFORMACION-PARA-LA-OBTENCION-DE-LA-NOTIFICACION-SANITARIA-OBLIGATORIA-DE-PRODUCTOS-COSMETICOS"
            component={GestionDeLaInformacionParaLaElaboracionSanitariaObligatoriaDeProductosCosmeticos}
          /> 
          <Route
            path="/cursos/2022/GESTION-DE-LA-INFORMACION-PARA-LA-OBTENCION-DE-LA-NOTIFICACION-SANITARIA-DE-PRODUCTOS-DE-HIGIENE-DOMESTICA-Y-PRODUCTOS-ABSORBENTES-DE-HIGIENE-REGISTRO-SANITARIO-DE-PRODUCTOS"
            component={GestionDeLaInformacionParaLaObtencionDeLaNotificacionSanitariaDeProductosDeHigieneDomesticaYProductosAbsorbentesDeHigieneRegistroSanitarioDeProductos}
          />
          <Route
            path="/cursos/2022/BUSQUEDA-Y-GESTION-DE-LA-LITERATURA-CIENTIFICA-SOBRE-MEDICAMENTOS"
            component= {BusquedaYGestionDeLaLiteraturaCientificaSobreMedicamentos2022}
          />
          <Route
            path="/cursos/2023/BUENAS-PRACTICAS-DE-CADENA-DE-FRIO-PARA-PRODUCTOS-FARMACEUTICOS-BIOLOGICOS-Y-O-DISPOSITIVOS-MEDICOS"
            component= {BuenasPracticasDeCadenaDeFrioParaProductosFarmaceuticosBiologicosYODispositivosMedicos}
          />
          <Route
            path="/cursos/2023/BUENAS-PRACTICAS-DE-ALMACENAMIENTO"
            component= {BuenasPracticasDeAlmacenamiento}
          />
          <Route
            path="/cursos/2023/CERTIFICACION-BUENAS-PRACTICAS-DE-DISTRIBUCION-Y-TRANSPORTE"
            component= {CertificacionBuenasPracticasDeDistribucionYTransporte}
          />
          <Route
            path="/cursos/2023/NORMATIVA-QUE-REGULA-LA-INTERCAMBIABILIDAD-DE-MEDICAMENTOS-EN-EL-PERU"
            component= {NormativaRegulaIntercambiabilidadDeMedicamentosEnPeru}
          />
          <Route
            path="/cursos/2023/GESTION-PARA-LA-IMPLEMENTACION-DEL-SISTEMA-DE-FARMACOVIGILANCIA-EN-UNA-DROGUERIA-Y-LABORATORIO-FARMACEUTICO"
            component= {GestionParaLaImplementacionDelSistemaDeFarmacoVigilanciaEnUnaDrogueriaYLaboratorioFarmaceutico}
          />
          <Route
            path="/cursos/2023/GESTION-DE-LA-INFORMACION-PARA-LA-ELABORACION-DEL-DOSSIER-DE-UNA-ESPECIALIDAD-FARMACEUTICA-DISPOSITIVOS-MEDICOS-PARA-LA-INSCRIPCION-O-REINSCRIPCION-EN-EL-REGISTRO-SANITARIO"
            component= {GestionDeLaInformacionParaLaElaboracionDelDossierDeUnaEspecialidadFarmaceuticaDispositivoMedicoParaLaInscripcionOReinscripcionEnElRegistroSanitario}
          />
          <Route
            path="/cursos/2023/CRITERIOS_TECNICOS_PARA_REALIZAR_EL_MAPEO_TERMICO_DE_ALMACENES_Y_CALIFICACION_DE_VEHICULOS_DE_TRANSPORTE_Y_CAMARA_FRIA_EN_UNA_DROGUERIA_O_ALMACEN_ESPECIALIZADO"
            component= {CriteriosTecnicosParaRealizarElMapeoTermicoDeAlmacenesYCalificacionDeVehiculosDeTransporteYCamaraFriaEnUnaDrogueriaOAlmacenEspecializado}
          />
          <Route
            path="/cursos/2023/PESQUISA_Y_PROCESO_DE_CONTROL_DE_CALIDAD_DE_PRODUCTOS_FARMACEUTICOS_DISPOSITIVOS_MEDICOS_Y_PRODUCTOS_SANITARIOS"
            component= {PesquisaYProcesoDeControlDeCalidadDeProductosFarmaceuticosDispositivosMedicosYProductosSanitarios}
          />
          <Route
            path="/cursos/2024/GESTION_E_IMPLEMENTACION_DE_LAS_BUENAS_PRACTICAS_DE_OFICINA_FARMACEUTICA"
            component= {GestionEImplementacionDeLasBuenasPracticasDeOficinaFarmaceutica}
          />
          <Route
            path="/cursos/2024/GESTION_PARA_LA_IMPLEMENTACION_DEL_SISTEMA_DE_FARMACOVIGILANCIA_EN_UNA_DROGUERIA_Y_LABORATORIO_FARMACEUTICO"
            component= {GestionParaLaImplementacionDelSistemaDeFarmacovigilanciaEnUnaDrogueriaYLaboratorioFarmaceutico2024}
          />
          <Route
            path="/cursos/2024/GESTION_DE_LA_INFORMACIÓN_PARA_LA_OBTENCIÓN_DE_LA_NOTIFICACIÓN_SANITARIA_OBLIGATORIA_DE_PRODUCTOS_COSMÉTICOS-PRODUCTOS_DE_HIGIENE_DOMÉSTICA_Y_PRODUCTOS_ABSORBENTES_DE_HIGIENE-REGISTRO_SANITARIO_DE_PRODUCTOS_SANITARIOS_ARTÍCULOS_PARA_BEBÉS"
            component= {GestionDeLaInformacionParaLaObtencionDeLaNotificacionSanitariaObligatoriaDeProductosCosmeticosHigieneDomesticaYAbsorbentesDeHigieneJunio}
          />

          <Route
            path="/cursos/2024/MANEJO_Y_CONTROL_DE_ESTUPEFACIENTES_Y_PSICOTROPICOS_EN_ESTABLECIMIENTOS_FARMACEUTICOS"
            component= {ManejoYControlDeEstupefacientesYPsicotropicosEnEstablecimientosFarmaceuticos2024}
          />
          <Route
            path="/cursos/2024/PROCEDIMIENTO_ADMINISTRATIVO_GENERAL_EN_ESTABLECIMIENTOS_FARMACEUTICO"
            component= {ProcedimientoAdministrativoGeneralEnEstablecimientosFarmaceuticos2024}
          />
          <Route
            path="/cursos/2024/BUSQUEDA_Y_GESTION_DE_LA_LITERATURA_CIENTIFICA_SOBRE_MEDICAMENTOS"
            component= {BusquedaYGestionDeLaLiteraturaCientificaSobreMedicamentos2024}
          />

        </Switch>
      </>
    )
  }
}
