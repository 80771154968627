import React, { Component } from 'react'
import {
  Divider,
  Collapse,
  Button
} from 'antd'

import { DownloadOutlined } from '@ant-design/icons';

import '../../../../estilos-paginas/cursos/cursos.css'
import imagen from '../../../../assets/cursos/gratuitos/curso41/brochure.png'
import pdfCurso from '../../../../assets/cursos/gratuitos/pdfcursos/GESTION_E_IMPLEMENTACION_DE_LAS_BUENAS_PRACTICAS_DE_OFICINA_FARMACEUTICA2024.pdf'

const { Panel } = Collapse;


function callback(key) {
  console.log(key);
}
function titulo(titulo) {
  return (<>
    <h4>{titulo}</h4>
  </>
  )
}
function subtitulo(subtitulo) {
  return (<>
    <h5 style={{ color: 'black'}}>{subtitulo}</h5>
  </>
  )
}

export default class GestionEImplementacionDeLasBuenasPracticasDeOficinaFarmaceutica extends Component {
  render() {
    return(
      <>
        <div style={{padding: '10px 10px', position:'relative'}}> 
          <img alt="example" src={imagen}
            style={{minWidth: 280, maxWidth: 350, position:'relative',
            display:'block', marginLeft: 'auto', marginRight:'auto', width:'50%'}}/>
        </div>
        <Divider />
        <div className="cursos">
          <Collapse onChange={callback} activeKey={['1', '2', '3']}>
            <Panel header={titulo("Titulo del curso")} key="1" 
              style={{ backgroundColor:'#1890ff'}} >
              <h3>GESTIÓN E IMPLEMENTACIÓN DE LAS NBUENAS PRÁCTICAS DE OFICINA FARMACÉUTICA</h3>
            </Panel>
            <Panel header={titulo("Temario del curso")} key="2"
              style={{ backgroundColor:'#1890ff'}}
            >
              {subtitulo("TEMARIO")}
              <ul>
                <p>Ponente: Q.F. JUDITH GONZALEZ MORENO</p>
                <p>21 de Febrero (Hora: 7:30 pm a 10:00 pm) CRITERIOS TÉCNICOS PARA SOLICITAR Y OBTENER LA AUTORIZACIÓN SANITARIA DE UNA OFICINA FARMACÉUTICA:</p>
                <li>Aspectos legales y Técnicos para solicitar y obtener la Autorización Sanitaria de una Oficina Farmacéutica.</li>
                <li>Infraestructura y equipamiento para una Oficina Farmacéutica.</li>
                <li>Documentos requeridos en una Oficina Farmacéutica.</li>
                <li>Fiscalización por parte de la Dirección de Salud o Direcciones Regionales de Salud.</li>
                <li>Escala de infracciones y sanciones de la Oficina Farmacéutica.</li>
                <br/>
                <th/>
              </ul>

              <ul>
                <p>Ponente: Q.F. JUDITH GONZALEZ MORENO</p>
                <p>22 de Febrero (Hora: 7:30 pm a 10:00 pm) ASEGURAMIENTO DE LA CALIDAD - BUENAS PRÁCTICAS DE ALMACENAMIENTO - BUENAS PRÁCTICAS DE DISTRIBUCIÓN Y TRANSPORTE EN LA OFICINA FARMACÉUTICA:</p>
                <li>Aseguramiento de la Calidad.</li>
                <li>Personal.</li>
                <li>Documentación.</li>
                <li>Infraestructura, Mobiliario y Equipamiento.</li>
                <li>Buenas Prácticas de Almacenamiento.</li>
                    <ul>
                        <li>Aspectos generales.</li>
                        <li>Recepción</li>
                        <li>Almacenamiento</li>
                        <li>Devolución</li>
                        <li>Baja o Rechazados</li>
                    </ul>
                <li>Buenas Prácticas de Distribución y Transporte</li>
                    <ul>
                        <li>Aspectos Generales.</li>
                        <li>Calificación, mantenimiento y mecanismos de seguridad del contenedor y/o vehículo de transporte.</li>
                        <li>De los Documentos</li>
                        <li>Del Personal</li>
                    </ul>
                <br/>
                <th/>
              </ul>

              <ul>
                <p>Ponente: Q.F. GISELLA SECLEN DAVILA</p>
                <p>23 de Febrero (Hora: 7:30 pm a 10:00 pm) BUENAS PRACTICAS DE DISPENSACION:</p>
                <li>Aspectos Generales y responsabilidades del acto de Dispensación.</li>
                <li>Proceso de Dispensación:</li>
                    <ul>
                        <li>Recepción y validación de la receta.</li>
                        <li>Análisis e interpretación de la prescripción.</li>
                        <li>Preparación y selección de los productos para su entrega.</li>
                        <li>Entrega de los productos e información por el dispensador</li>
                        <li>Registros, cuando corresponda.</li>
                    </ul>
                <li>Manejo de los libros de Psicotrópicos y/o estupefacientes.</li>
                <br/>
                <th/>
              </ul>


              <ul>
                <p>Ponente: Q.F. KELLY SERRANO MESTANZA</p>
                <p>28 de Febrero (Hora: 7:30 pm a 10:00 pm) BUENAS PRACTICAS DE FARMACOVIGILANCIA EN LA OFICINA FARMACEUTICA:</p>
                <li>Aspectos Generales</li>
                <li>Identificación del Riesgo</li>
                <li>Gestión del Riesgo</li>
                <li>Documentación y/o Registros</li>
                <br/>
                <th/>
              </ul>

              <ul>
                <p>Ponente: Q.F. KELLY SERRANO MESTANZA</p>
                <p>29 de Febrero (Hora: 7:30 pm a 10:00 pm) BUENAS PRACTICAS DE SEGUIMIENTO FARMACOTERAPEUTICO EN LA OFICINA FARMACEUTICA:</p>
                <li>Aspectos Generales </li>
                <li>Explicación del servicio</li>
                <li>Análisis de la situación del Paciente</li>
                <li>Entrevista Farmacéutica</li>
                <li>Evaluación y análisis de los datos de salud del paciente</li>
                <li>Plan de Intervención Farmacéutica</li>
                <li>Evaluación y Seguimiento</li>
                <li>Registros</li>
                <br/>
                <th/>
              </ul>
              <a href={pdfCurso} download>
              <Button type="primary" shape="round" icon={<DownloadOutlined/>} size={'large'}>
                Descargar PDF
               </Button>
              </a>
            </Panel>
            <Panel key="3"
              style={{ backgroundColor:'#1890ff'}}
            >
              <ul>
                <li>Si desea registrar su participación en el webinar: GESTIÓN E IMPLEMENTACIÓN DE LAS BUENAS PRÁCTICAS DE OFICINA FARMACÉUTICA, agradeceré llenar el siguiente formulario de inscripción: </li>
              </ul>
              <br/> <a href="https://forms.gle/2Xxd3bEjpu4yLWJW6"rel="noopener noreferrer" target="_blank"><Button type="primary" shape="round" size={'large'}>FORMULARIO DE INSCRIPCIÓN </Button></a>
            </Panel>
            <Panel header={titulo("Más Información:")} key="3" style={{ backgroundColor:'#1890ff'}}>
              <p>Correo: informes@sgafarma.com</p>
              <p>Celular/ Whatsapp: (+51) 941 649 600</p>
            </Panel>
          </Collapse>
        </div>
        <Divider />
      </>
    )
  }
}